import React, { useState } from 'react';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Modal,
  Tabs
} from 'antd';
import StickyBox from 'react-sticky-box';

const { Title, Paragraph, Text, Link } = Typography;

const ModalEventSchedule = ({
  handleCancel,
  handleOk,
  open,
  handleScrollOnClick,
  registrationSectionRef,
  setSelectedScheduleInModal
}) => {
  const items = [
    {
      key: '1',
      label: '4th May',
      children: (
        <>
          <div
            style={{ padding: 8, cursor: 'pointer' }}
            onClick={() => {
              handleScrollOnClick(registrationSectionRef);
              setSelectedScheduleInModal('4');
              handleCancel();
            }}
          >
            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Workshop</p>
              </div>

              <ul>
                <li>ADing Creativity (10:30 am)</li>
                <li>
                  Imagination to Reality: Building Your Architectural Dreams
                  (11:20 am)
                </li>
                <li>Decode Your Dreams (12:10 pm)</li>
                <li>#You can also be a Stand-Up Comedian (01:00 pm)</li>
                <li>Design Your Dream Space (01:00 pm)</li>
                <li>The Science of Nutrition (01:00 pm)</li>
                <li>Express Your Style Through Jewellery (01:00 pm)</li>
                <li>The Art of Versatile Acting (01:00 pm)</li>
                <li>Building Blockbuster Roles for the Screen (01:00 pm)</li>
                <li>Be A Data Detective (01:00 pm)</li>
              </ul>
            </Paragraph>

            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Seminar</p>
              </div>

              <ul>
                <li>Application of Robotics (10:30 am)</li>
                <li>
                  The Integration of Data Analytics in Healthcare Industry
                  (11:20 am)
                </li>
                <li>Building a Digital Business Model (12:10 pm)</li>
                <li>An Introduction to Product-led Growth (01:00 pm)</li>
                <li>BTS of Building End-to-End Web Applications (01:50 pm)</li>
                <li>
                  How to Hire the Right Talent for an Organisation? (02:40 pm)
                </li>
              </ul>
            </Paragraph>

            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Panel Discussion</p>
              </div>

              <ul>
                <li>Where to Get Started in Biotech? (11:10 am)</li>
                <li>
                  Mental Health in Modern Learning Environments (11:50 am)
                </li>
                <li>Decoding Careers in Finance (01:40 pm)</li>
                <li>Go Green With Your Career (02:20 pm)</li>
                <li>In-Demand Skills in Machine Learning (03:00 pm)</li>
                <li>Is a Blockchain Career Right for You? (03:40 pm)</li>
                <li>
                  Aligning Your Education With Athletic Careers (04:20 pm)
                </li>
                <li>Make Money With Winning Prompts (04:20 pm)</li>
              </ul>
            </Paragraph>
          </div>
        </>
      )
    },
    {
      key: '2',
      label: '5th May',
      children: (
        <>
          <div
            style={{ padding: 8, cursor: 'pointer' }}
            onClick={() => {
              handleScrollOnClick(registrationSectionRef);
              setSelectedScheduleInModal('5');
              handleCancel();
            }}
          >
            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Workshop</p>
              </div>

              <ul>
                <li>
                  Designing User-Friendly Interfaces for Digital Products (10:30
                  am)
                </li>
                <li>Skin 101: Know Your Dermatology Essentials (11:20 am)</li>
                <li>Go Above and Beyond with an Aerospace Career (12:10 pm)</li>
                <li>Find Your Voice in the Courtroom (01:00 pm)</li>
                <li>Build Your Own App (01:50 pm)</li>
                <li>Create Your First Video Game Concept (02:40 pm)</li>
                <li>
                  Explore Your Perception Through Data Visualisation (03:30 pm)
                </li>
                <li>Building a Profitable Real Estate Portfolio (04:20 pm)</li>
              </ul>
            </Paragraph>

            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Seminar</p>
              </div>

              <ul>
                <li>
                  Urban Planning vs. Architecture: How Diverse Are These Two
                  Career Paths? (10:30 am)
                </li>
                <li>The 7 P's of Event Marketing (11:20 am)</li>
                <li>AI's Evolution in Advertising (12:10 pm)</li>
                <li>Key Aspects of Technical Writing (01:00 pm)</li>
                <li>The Future of Drone Technology in Aviation (01:50 pm)</li>
                <li>The Basics of Cloud Architecture (02:40 pm)</li>
                <li>
                  Colour Theory and Application in Graphic Design (03:30 pm)
                </li>
                <li>The Future Scope of Nano Technology (04:20 pm)</li>
              </ul>
            </Paragraph>

            <Paragraph>
              <div style={{ backgroundColor: '#F4F4F4', height: 26 }}>
                <p>Panel Discussion</p>
              </div>

              <ul>
                <li>
                  Careers in BIM (Building Information Modeling) (10:30 am)
                </li>
                <li>
                  Careers in Foreign Languages beyond Translator and Interpreter
                  (11:00 am)
                </li>
                <li>Rewarding Careers in Social Science (11:50 am)</li>
                <li>The Future of Performance Marketing (12:30 pm)</li>
                <li>Can You Take Gaming as a Career in India? (01:10 pm)</li>
                <li>How to Break Into the Indian Music Industry? (01:50 pm)</li>
                <li>
                  Expanding Your Creative Horizon and Creating Stunning Graphics
                  (02:30 pm)
                </li>
                <li>
                  User Generated Content (UGC) and Other Trends in Digital
                  Marketing (03:10 pm)
                </li>
                <li>Non-flying Careers in Aviation (03:50 pm)</li>
                <li>Writing Across Genres (04:30 pm)</li>
              </ul>
            </Paragraph>
          </div>
        </>
      )
    }
  ];

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      style={{
        zIndex: 1
      }}
    >
      <DefaultTabBar {...props} style={{ backgroundColor: '#ffffff' }} />
    </StickyBox>
  );

  return (
    <Modal
      open={open}
      title="Event Schedule"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Row justify="start">
          <Button
            htmlType="submit"
            style={{
              backgroundColor: '#173D7A',
              color: '#ffffff'
            }}
            onClick={() => {
              handleScrollOnClick(registrationSectionRef);
              handleCancel();
            }}
          >
            Register Now
          </Button>
        </Row>
      ]}
    >
      <div
        style={{
          maxHeight: 398,
          overflow: 'scroll',
          border: '1px solid #BBBBBB'
        }}
      >
        <Tabs
          defaultActiveKey="1"
          centered
          items={items}
          renderTabBar={renderTabBar}
          onTabClick={(key, event) => {
            if (key == 1) {
              setSelectedScheduleInModal('4');
            } else {
              setSelectedScheduleInModal('5');
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalEventSchedule;
