import MainSection from '../sections/MainSection';
import EventSection from '../sections/EventSection';
import BlurSection from '../sections/BlurSection';
import CareerDomain from '../components/CareerDomain';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

const CareerBeamFestival = () => {
  return (
    <div
      style={{
        width: '100%',
        overflowX: 'hidden'
      }}
    >
      <Helmet>
        <title>Ascend Beyond | Elevate Your Career | CareerBeam Festival</title>
        {/* <meta name="title" content="About CareerBeam: Our Story" /> */}
        <meta
          name="description"
          content="Discover career horizons at CareerBeam festival 2024 - ASCend Beyond! Join interactive sessions, workshops & seminars with industry experts. Register now!"
        />
        <link
          rel="canonical"
          href="https://careerbeam.in/careerbeam-festival"
        />
        <meta name="robots" content="all" />
        <meta
          property="og:title"
          content="CareerBeam Festival 2024 | ASCendBeyond"
        />
        <meta property="og:site_name" content="CareerBeam" />
        <meta
          property="og:url"
          content="https://careerbeam.in/careerbeam-festival"
        />
        <meta
          property="og:description"
          content="Discover career horizons at CareerBeam festival 2024 - ASCend Beyond! Join interactive sessions, workshops & seminars with industry experts. Register now!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/festivalBanner.86d513e39dc2ca9f8d2a.png"
        />
      </Helmet>

      {/* <MetaTags>
        <meta
          name="title"
          content="Ascend Beyond | Elevate Your Career | CareerBeam Festival"
        />
        <meta
          name="description"
          content="Discover career horizons at CareerBeam festival 2024 - ASCend Beyond! Join interactive sessions, workshops & seminars with industry experts. Register now!"
        />
      </MetaTags> */}

      <MainSection />
      <EventSection />
      <BlurSection />
      <CareerDomain />
    </div>
  );
};

export default CareerBeamFestival;
