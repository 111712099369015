import React, { useState } from 'react';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Grid,
  Affix
} from 'antd';

const { useBreakpoint } = Grid;

const TopSection = ({
  handleScrollOnClick,
  aboutSectionRef,
  scheduleSectionRef,
  exploreCareerSectionRef,
  eventAttractionSectionRef,
  registrationSectionRef,
  setSelectedSection,
  selectedSection,
  presentScrollSection
}) => {
  const screens = useBreakpoint();

  return (
    <Affix offsetTop={65}>
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          // marginTop: screens?.xs ? '5.5rem' : '1rem',
          // marginBottom: '1rem',
          paddingTop: screens?.xs ? '1.3rem' : '1rem',
          paddingBottom: '0.5rem',

          overflowX: screens?.xs ? 'scroll' : null,
          backgroundColor: '#ffffff',
          scrollbarWidth: 'none'
        }}
      >
        <Button
          shape="round"
          style={{
            marginRight: '1rem',
            marginLeft: screens?.xs ? '22rem' : null,
            backgroundColor: selectedSection == 1 && '#3F95FE',
            color: selectedSection == 1 && '#ffffff',
            fontWeight: selectedSection == 1 && 800,
            padding: '1px 8px 1px 8px'
          }}
          onClick={() => {
            handleScrollOnClick(aboutSectionRef);
            setSelectedSection(1);
          }}
        >
          About Event
        </Button>
        <Button
          shape="round"
          style={{
            marginRight: '1rem',
            backgroundColor: selectedSection == 2 && '#3F95FE',
            color: selectedSection == 2 && '#ffffff',
            fontWeight: selectedSection == 2 && 800,
            padding: '1px 8px 1px 8px'
          }}
          onClick={() => {
            handleScrollOnClick(scheduleSectionRef);
            setSelectedSection(2);
          }}
        >
          Schedule
        </Button>
        <Button
          shape="round"
          style={{
            marginRight: '1rem',
            backgroundColor: selectedSection == 3 && '#3F95FE',
            color: selectedSection == 3 && '#ffffff',
            fontWeight: selectedSection == 3 && 800,
            padding: '1px 8px 1px 8px'
          }}
          onClick={() => {
            handleScrollOnClick(exploreCareerSectionRef);
            setSelectedSection(3);
          }}
        >
          Explore Career Options
        </Button>
        <Button
          shape="round"
          style={{
            marginRight: '1rem',
            backgroundColor: selectedSection == 4 && '#3F95FE',
            color: selectedSection == 4 && '#ffffff',
            fontWeight: selectedSection == 4 && 800
          }}
          onClick={() => {
            handleScrollOnClick(eventAttractionSectionRef);
            setSelectedSection(4);
          }}
        >
          Event Attractions
        </Button>
        <Button
          shape="round"
          style={{
            marginRight: '1rem',
            backgroundColor: selectedSection == 5 && '#3F95FE',
            color: selectedSection == 5 && '#ffffff',
            fontWeight: selectedSection == 5 && 800,
            padding: '1px 8px 1px 8px'
          }}
          onClick={() => {
            handleScrollOnClick(registrationSectionRef);
            setSelectedSection(5);
          }}
        >
          Registration Form
        </Button>
      </Flex>
    </Affix>
  );
};

export default TopSection;
