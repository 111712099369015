import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import GoogleTagManager from './components/GoogleTagManeger';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactGA.initialize('G-8QXSBNS1BS');
ReactGA.pageview(window?.location?.pathname + window?.location?.search);
const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <GoogleTagManager />
        <App />
      </HelmetProvider>
    </React.StrictMode>
  </BrowserRouter>
);

reportWebVitals();
