import { useEffect, useState } from 'react';
import styles from './index.module.css';
import {
  panelData,
  panelData2,
  seminarData,
  seminarData2,
  workShopData,
  workShopData2,
  workShopData1New,
  panelData1New,
  workShopData2New,
  panelData2New,
  eventscheduleWorkShopData,
  eventScheduleFormPanelData,
  eventFormWorkShopData2,
  eventFormPanelData2
} from '../data';
import { FaLocationDot } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { mumbai } from '../images';
import { Helmet } from 'react-helmet-async';

const TopBox = () => {
  return (
    <div
      className={styles.topbox}
      style={{
        backgroundColor: 'rgba(3, 129, 200, 1)'
      }}
    >
      <div
        className={styles.event}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div className={styles.event}>
          <p className={styles.heading}>Event Schedule</p>
          <p
            style={{
              fontSize: '1rem',
              color: 'white',
              width: '80%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-start'
            }}
          >
            <div
              style={{
                paddingTop: '.4rem',
                width: 'fit-content'
              }}
            >
              <FaLocationDot size={20} className="text-white" />
            </div>
            <p style={{ flex: '1' }}>
              Hall No-02, NESCO, Goregaon (East), Mumbai, Maharashtra 400063
            </p>
          </p>
        </div>
      </div>
      <div className={styles.img}>
        <img
          src={mumbai}
          alt="Event Schedule"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

const Table = ({ data }) => {
  return (
    <div
      style={{
        padding: '1rem',
        borderRadius: '24px',
        overflow: 'hidden'
      }}
    >
      <div className={styles.table}>
        <p className={styles.workshop}>{data.heading}</p>
        <div className={styles.topicHeading}>
          {data.colHeading.map((col, idx) => (
            <p
              key={idx}
              style={{
                display: idx == 1 ? 'flex' : null,
                justifyContent: idx == 1 ? 'center' : null
              }}
            >
              {col}
            </p>
          ))}
        </div>

        {data.data.map((row, idx) => (
          <div className={styles.row} key={idx}>
            {row.map((col, idx) => (
              <p
                key={idx}
                style={{
                  display: idx == 1 ? 'flex' : null,
                  justifyContent: idx == 1 ? 'center' : null
                }}
              >
                {col}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const EventSchedule = () => {
  const navigate = useNavigate();
  const data = [
    {
      date: '4th May 2024',
      table: [eventscheduleWorkShopData, eventScheduleFormPanelData]
    },
    {
      date: '5th May 2024',
      table: [eventFormWorkShopData2, eventFormPanelData2]
    }
  ];
  const [dateToggle, setDateToggle] = useState('4th May 2024');

  const getSelectedTableData = (selectedDate) => {
    const foundEvent = data.find((event) => event.date === selectedDate);
    return foundEvent?.table || [];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <Helmet>
        <title>Ascend Beyond | CareerBeam Festival Schedule</title>
        <meta
          name="description"
          content="Explore the event schedule for CareerBeam’s Festival Ascend Beyond. Discover sessions, speakers, and networking opportunities"
        />
        <link rel="canonical" href="https://careerbeam.in/event-schedule" />
        <meta name="robots" content="all" />
      </Helmet>

      {/* <MetaTags>
        <meta name="title" content="Ascend Beyond | CareerBeam Festival Schedule" />
        <meta
          name="description"
          content="Explore the event schedule for CareerBeam’s Festival Ascend Beyond. Discover sessions, speakers, and networking opportunities"
        />
      </MetaTags> */}
      <TopBox />
      <div style={{ margin: '2rem 0' }}>
        <div className="flex justify-center gap-10  md:gap-20 text-xl p-4">
          {data.map((event) => (
            <button
              key={event.date}
              className={` ${
                event.date === dateToggle
                  ? 'text-[#0070C0] underline underline-offset-8'
                  : ''
              }`}
              onClick={() => setDateToggle(event.date)}
            >
              {event.date}
            </button>
          ))}
        </div>
        {getSelectedTableData(dateToggle).map((tableData, idx) => (
          <Table key={idx} data={tableData} />
        ))}
      </div>
      <button
        onClick={() => {
          navigate('/event-registration');
        }}
        style={{
          position: 'fixed',
          bottom: '10%',
          right: '10%',
          backgroundColor: 'white',
          borderRadius: '24px',
          padding: '.5rem 2rem',
          color: 'rgba(9, 117, 194, 1)',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          fontWeight: '500'
        }}
      >
        Register Now
      </button>
    </div>
  );
};

export default EventSchedule;
