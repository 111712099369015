import { useContext, useEffect } from "react";
import Layout from "../components1/03_compounds/Layout";
import OtpVerification from "../components1/03_compounds/Otp_Verification";
import MainContext from "../components1/helper/context/MainContext";
import { useNavigate } from "react-router-dom";

const VerifyOtp = () => {
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.email) {
      navigate("/event-registration");
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <OtpVerification />
    </Layout>
  );
};

export default VerifyOtp;
