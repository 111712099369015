import { useNavigate } from 'react-router-dom';
// import Mumbai from "../ax/ssets1/Mumbai.png";
import { mumbai1 } from '../images';

const BlurSection = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-blur pb-16 flex justify-center items-center text-white ">
      <div className="mt-20 p-8 rounded-md bg-[#005898] text-center">
        <img src={mumbai1} alt="" />
        <h3 className="text-5xl mt-4 font-bold" style={{ fontWeight: 600 }}>
          MUMBAI
        </h3>
        <h4 className="text-xl mt-4" style={{ fontWeight: 600 }}>
          4th & 5th May
        </h4>
        <div className="w-full flex items-center justify-center">
          <p className="mt-4 text-sm md:text-md md:w-[70%]">
            Hall No-02, NESCO, Goregaon (East), Mumbai, Maharashtra 400063
          </p>
        </div>

        <div className="flex mt-6 justify-around gap-4">
          <div
            className="bg-white rounded-md px-4 py-2"
            style={{
              fontFamily: 'Rubik',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '28px',
              color: '#062229'
            }}
          >
            <button
              onClick={() => {
                navigate('/event-schedule');
              }}
            >
              View Schedule
            </button>
          </div>
          <div
            className="bg-secondary rounded-md px-4 py-2 "
            style={{
              fontFamily: 'Rubik',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '28px',
              color: '#062229'
            }}
          >
            <button onClick={() => navigate('/event-registration')}>
              Register Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlurSection;
