import { useContext, useEffect, useState } from 'react';
import Layout from '../components1/03_compounds/Layout';
import AppHeader from '../components1/03_compounds/App_Header';
import StudentLogin from '../components1/03_compounds/Student_Login';
import FacultyLogin from '../components1/03_compounds/Faculty_Login';
import ParentsLogin from '../components1/03_compounds/Parents_Login';
import OthersLogin from '../components1/02_molecules/Others_Login';
import MainContext from '../components1/helper/context/MainContext';
import { useNavigate } from 'react-router-dom';

const ApplicationForm = () => {
  const { saveDetails, setLoading } = useContext(MainContext);
  const [details, setDetails] = useState({
    name: '',
    contact: 0,
    role: '',
    details: {}
  });

  const onSubmit = async () => {
    setLoading(true);
    const res = await saveDetails({
      data: { ...details, ...details?.details }
    });
    setLoading(false);
    if (res.success) {
      navigate('/registration-success');
    } else {
      alert(res.message);
    }
  };

  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.email) {
      navigate('/event-registration');
    }

    window.scrollTo(0, 0);
  }, []);


  return (
    <Layout>
      <div
        style={{
          paddingBottom: '2rem'
        }}
      >
        <AppHeader
          details={details}
          setDetails={setDetails}
          onSubmit={onSubmit}
        />
        {details.role === 'student' && (
          <StudentLogin
            details={details}
            setDetails={setDetails}
            onSubmit={onSubmit}
          />
        )}
        {details.role === 'faculty' && (
          <FacultyLogin
            details={details}
            setDetails={setDetails}
            onSubmit={onSubmit}
          />
        )}
        {details.role === 'parent' && (
          <ParentsLogin
            details={details}
            setDetails={setDetails}
            onSubmit={onSubmit}
          />
        )}
        {details.role === 'others' && (
          <OthersLogin
            details={details}
            setDetails={setDetails}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </Layout>
  );
};

export default ApplicationForm;
