import React from 'react';
import PodcastFirstSection from '../components/PodcatsFirstSection';
import PastCampaign from '../components/PastCampaign';
import PodcastSlide from '../components/PodcastSlide';
import PodcastPic from '../components/PodcastPic';
import PodcastCarosel from '../components/PodcastCarosel';
import MoreEventPodcast from '../events/MoreEvenetsPodcast';
import GoogleTagManager from '../components/GoogleTagManeger';
import MetaTags from 'react-meta-tags';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PodcastScreen = () => {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <title>HP Future Ready Podcast | CareerBeam</title>
        {/* <meta
          name="title"
          content="CareerBeam: Next-Gen Career Guidance for Young India"
        /> */}

        <meta
          name="description"
          content="Discover the HP Future Ready Podcast, revolutionizing classrooms with HP & Microsoft tech solutions. Join us for innovative insights and educational support!"
        />
        <link
          rel="canonical"
          href="https://careerbeam.in/hp-future-ready-podcast"
        />
        <meta name="robots" content="all" />
        <meta
          property="og:title"
          content="HP Future Ready Podcast | CareerBeam"
        />
        <meta property="og:site_name" content="CareerBeam" />
        <meta
          property="og:url"
          content="https://careerbeam.in/hp-future-ready-podcast"
        />
        <meta
          property="og:description"
          content="Discover Initium, where aspiring professionals gain essential skills and knowledge."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/podcastupper.7e665ea63198027a5298.png"
        />
      </Helmet>

      {/* <MetaTags>
        <meta name="title" content="HP Future Ready Podcast | CareerBeam" />
        <meta
          name="description"
          content="Discover the HP Future Ready Podcast, revolutionizing classrooms with HP & Microsoft tech solutions. Join us for innovative insights and educational support!"
        />
      </MetaTags> */}
      <PodcastFirstSection />
      <PastCampaign />
      <PodcastSlide />
      <PodcastPic />
      <PodcastCarosel />
      <MoreEventPodcast />
    </div>
  );
};

export default PodcastScreen;
