import DropDown from "../../01_atoms/DropDown";
import InputBox from "../../01_atoms/InputBox";
import InputBox2 from "../../01_atoms/InputBox2";
import RadioBtn2 from "../../01_atoms/RadioBtn2";
import Button1 from "../../01_atoms/button1";
import { degrees, fields } from "../../../data";
import { useState } from "react";
import styles from "./index.module.css";
import SelectEventOverlay from "../../02_molecules/SelectEventOverlay";
import InputBorderless from "../../01_atoms/InputBorderless";

const StudentLogin = ({ details, setDetails, onSubmit }) => {
  const [showList, setShowList] = useState(false);
  const [showCourseInput, setShowCourseInput] = useState(false);
  const [showFieldInput, setShowFieldInput] = useState(false);
  const [showYearInput, setShowYearInput] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "5rem",
          padding: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <div className={styles.flex}>
            <div className={styles.width50}>
              <InputBox
                text={"Which school/college are you studying in?"}
                mark
                placeholderText={"School/College name"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      school_name: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className={styles.width50}>
              <DropDown
                text={"What course are you studying?"}
                options={degrees}
                onSelect={(e) => {
                  if (e.target.value === "Others") {
                    setShowCourseInput(true);
                    return;
                  }

                  setShowCourseInput(false);
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      course_name: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          {/* Other option */}
          {showCourseInput && (
            <div className={styles.flex}>
              <div className={styles.width50}></div>
              <div className={styles.width50}>
                <InputBorderless
                  placeholderText={"Enter course name"}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      details: {
                        ...details.details,
                        course_name: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
          )}

          <div
            className={styles.flex}
            style={{
              alignItems: "flex-start",
            }}
          >
            <div className={styles.width50}>
              <DropDown
                text={"Which year are you studying in?"}
                style={{ marginBottom:  "1rem" }}
                options={[
                  "10th",
                  "11th",
                  "12th",
                  "First year",
                  "Second year",
                  "Third year",
                  "Fourth year",
                  "Others",
                ]}
                onSelect={(e) => {
                  console.log(e.target.value, "hbu");
                  if (e.target.value == "Others") {
                    setShowYearInput(true);
                    return;
                  }

                  setShowYearInput(false);
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      year_of_study: e.target.value,
                    },
                  });
                }}
              />

              {showYearInput && (
                <InputBorderless
                  placeholderText={"Enter year"}
                  style={{ marginTop: "1rem" }}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      details: {
                        ...details.details,
                        year_of_study: e.target.value,
                      },
                    })
                  }
                />
              )}
            </div>

            <div className={styles.width50}>
              <DropDown
                text={"Which field are you planning to join?"}
                style={{ marginBottom: "1rem" }}
                options={fields}
                onSelect={(e) => {
                  if (e.target.value === "Others") {
                    setShowFieldInput(true);
                    return;
                  }

                  setShowFieldInput(false);
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      course_interested: e.target.value,
                    },
                  });
                }}
              />

              {showFieldInput && (
                <InputBorderless
                  placeholderText={"Enter field name"}
                  style={{ marginTop: "1rem" }}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      details: {
                        ...details.details,
                        course_interested: e.target.value,
                      },
                    })
                  }
                />
              )}
            </div>
          </div>

        

          <div className={styles.flex}>
            <div className={styles.width50}>
              <RadioBtn2
                mark
                text={"Are you planning to study abroad?"}
                name={"abroad"}
                options={["Yes", "No"]}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      study_abroad: e,
                    },
                  })
                }
              />
            </div>
            <div className={styles.width50}>
              <RadioBtn2
                text={
                  "Would you be interested in volunteering at CareerBeam Festival?"
                }
                name={"volunteer"}
                options={["Yes", "No"]}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      interested_in_carribean: e,
                    },
                  })
                }
              />
            </div>
          </div>
          <RadioBtn2
            mark
            text={"Which day would you be attending ASCend Beyond?"}
            options={["4th May", "5th May", "4th & 5th May"]}
            name={"attending_day"}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  attending_day: e,
                },
              })
            }
          />

          <RadioBtn2
            mark
            text={"Choose the sessions you wish to attend."}
            options={["Select session", "Just visiting"]}
            name={"chooseEvent"}
            onChange={(e) => {
              if (e === "Select session") {
                setShowList(true);
              }
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  chooseEvent: e,
                  attending_events: {
                    "4th May 2024": [],
                    "5th May 2024": [],
                  },
                },
              });
            }}
          />

          <InputBox2
            text={
              "What are the biggest concerns or challenges you have faced when it comes to education?"
            }
            placeholderText={"Confusing choices in career selection.."}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  biggest_challenge: e.target.value,
                },
              })
            }
          />
          <InputBox2
            text={
              "What topics would you like to see covered in CareerBeam festival?"
            }
            placeholderText={"AI, Military, Coding"}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  topic_suggested: e.target.value,
                },
              })
            }
          />
          <Button1
            style={{ width: "10rem" }}
            text={"Submit Form"}
            onClick={onSubmit}
          />
        </div>

        {showList && (
          <SelectEventOverlay
            details={details}
            setDetails={setDetails}
            setShowList={setShowList}
          />
        )}
      </div>
    </>
  );
};

export default StudentLogin;
