import React from "react";
// import qsbanner from "../assets/qs-he ad.png";
import styled from "styled-components";
import banner from "../assets/card1.png";
import qsmobile from "../assets/qsmobile.png";
import { qsBanner, qsPreview } from "../images";

const QsEvent = () => {
  return (
    <>
      <Div>
        <Img src={qsBanner} alt="" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6rem",
            paddingBottom: "6rem",
            marginLeft: "6rem",
            justifyContent: "center",
            backgroundColor: "#F3F6FB;",
          }}
        >
          <div style={{ flex: 1, marginLeft: "6rem" }}>
            <H2>About QS</H2>

            <P>
              Bringing together top universities to your screen. Discover more
              about studying in different countries and which university is best
              suited to meet your career aspirations.
            </P>
            <br />
            <P>
              This event will help students find out where to study abroad and
              which course is the best match for their ambitions. Learn all you
              need to know about student life at the world’s top universities.
            </P>
          </div>
          <div style={{ flex: 1, marginRight: "7rem" }}>
            <img
              src={qsPreview}
              alt="Info Image"
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "flex",
                margin: "auto",
              }}
            />
          </div>
        </div>
      </Div>

      <MobileDiv className="pb-12">
        <div>
          <div className="div">
            <ImgMobile src={qsPreview} />
            <h2 className="h2">About Qs</h2>

            <img
              className="mobile-banner m-auto"
              src={qsPreview}
              alt="Info Image"
             
            />

            <p className="mobile-para">
              Bringing together top universities to your screen. Discover more
              about studying in different countries and which university is best
              suited to meet your career aspirations.
            </p>
            <br />
            <p className="mobile-para">
              This event will help students find out where to study abroad and
              which course is the best match for their ambitions. Learn all you
              need to know about student life at the world’s top universities.
            </p>
          </div>
        </div>
      </MobileDiv>
    </>
  );
};

export default QsEvent;

const Img = styled.img`
  /* height: auto; */
  width: 100%; 
  margin: 0%;
  position: relative;
  bottom: 0.9rem;
  transform: translateX(-8px);
  /* margin-bottom: 1rem; */

  @media (max-width: 768px) {
    padding-top: 5.2rem;
  }
`;

const ImgMobile = styled.img`
  margin-top: 4rem;
  transform: translateX(-5px);
  padding: 0;
`;

const H2 = styled.h2`
  color: #062229;
  font-family: "Manrope", Sans-serif;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 40px;

  @media (max-width: 786px) {
  }
`;

const P = styled.p`
  text-align: left;
  color: #062229;
  width: 80%;
  font-family: "Rubik", Sans-serif;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 786px) {
    text-align: center;
  }
`;

const Div = styled.div`
  @media (max-width: 786px) {
    display: none;
  }
`;

const MobileDiv = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  @media (max-width: 786px) {
    .div {
      display: flex;
      flex-direction: column;
      margin: auto;
      /* justify-content: center; */
    }
    .h2 {
      display: flex;
      justify-content: center;
      color: #062229;
      font-size: 24px;
      line-height: 32px;
      margin: 2rem 0;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      /* identical to box height */

      color: #062229;
    }
    .mobile-para {
      /* text-align: left; */
      text-align: center;
      display: flex;
      margin: auto;
      justify-content: center;
      color: #062229;
      width: 300px;
      margin: 5px;
      font-family: "Rubik", Sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin: auto;
    }
    .mobile-banner {
      width: 295px;
      margin-bottom: 2rem;
    }
  }
`;
