import PropTypes from 'prop-types';
import MainContext from './MainContext';
import { useState } from 'react';
import { Loading } from '../../01_atoms/Loading';

const MainState = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('user');

  const loginAPI = async (data) => {
    const endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/login`;
    var res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data
      })
    });

    res = await res.json();
    return res;
  };

  const verifyOtpAPI = async ({ email, otp }) => {
    const endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/verify`;
    var res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        otp: parseInt(otp)
      })
    });

    res = await res.json();
    return res;
  };

  const saveDetails = async ({ data }) => {
    const endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/save`;
    var res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : null
      },
      body: JSON.stringify({
        ...data,
        email: user.email
      })
    });

    res = await res.json();
    if (res?.success) {
      localStorage.removeItem('user');
    }
    return res;
  };

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        loginAPI,
        verifyOtpAPI,
        saveDetails,
        loading,
        setLoading
      }}
    >
      {children}
      {loading && <Loading />}
    </MainContext.Provider>
  );
};

MainState.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainState;
