import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Affix,
  Grid
} from 'antd';

import {
  first_section_background,
  career_beam_festival,
  call,
  whatsapp,
  Desktop_bg
} from '../festival_images';
import MetaTags from 'react-meta-tags';

// import Desktop_bg from '../assets/festival_Desktop_bg.png';
// import first_section_background from '../assets/festival_First_section_bg.svg';
// import career_beam_festival from '../assets/festival_career_beam_festival.svg';
// import call from '../assets/festival_call_icon.svg';
// import whatsapp from '../assets/festival_whatsapp_icon.svg';

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const AscendBeyondSection = ({
  aboutSectionRef,
  registrationSectionRef,
  handleScrollOnClick
}) => {
  const screens = useBreakpoint();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex,nofollow" />
      </MetaTags>

      <FirstSection ref={aboutSectionRef} id="about" className="max-md:hidden">
        <Flex
          justify={'center'}
          align="center"
          ref={aboutSectionRef}
          style={{ paddingTop: 50 }}
        >
          <Row justify="space-around" align="middle">
            <Col
              lg={{ span: 8, offset: 1 }}
              style={{ paddingLeft: screens?.md ? 20 : null }}
            >
              <Title
                level={1}
                style={{
                  color: '#ffffff',
                  letterSpacing: 1,
                  fontWeight: 400,
                  fontSize: 46,
                  fontFamily: 'DIN'
                }}
              >
                <span style={{ color: '#F9DC16', fontWeight: 400 }}>
                  <strong>ASC</strong>
                </span>
                end Beyond
              </Title>
              <Paragraph
                level={4}
                style={{
                  color: '#ffffff',
                  fontFamily: 'sans-serif',
                  fontSize: 18,
                  fontWeight: 400
                }}
              >
                CareerBeam Festival is your one-stop destination for
                comprehensive career development needs. Explore beyond the
                traditional fields of education and ASCend Beyond to new
                horizons with 100+ universities, 100+ industry experts who will
                conduct 50+ seminars, workshops and panel discussions. Not just
                that, network with budding entrepreneurs at our flea market and
                uncover the secrets for a successful career.
              </Paragraph>
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: '#ffffff',
                  color: '#212121',
                  minWidth: 169,
                  marginTop: 35,
                  fontWeight: 500
                }}
                size={'large'}
                onClick={() => handleScrollOnClick(registrationSectionRef)}
              >
                Register Now
              </Button>
            </Col>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <img
                src={career_beam_festival}
                alt=""
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </Col>
          </Row>
        </Flex>
        {/* <Flex justify="flex-end" style={{ marginRight: '30px' }}>
          <Affix offsetTop={500}>
            <Row>
              <Button
                type="secondary"
                style={{
                  backgroundColor: '#ffffff',
                  borderRadius: 99,
                  marginLeft: 32,
                  padding: 0,
                  width: 95
                }}
              >
                <a href="tel:+917208070768">
                  <Row>
                    <Col span={16} style={{ padding: 3 }}>
                      Call Us
                    </Col>
                    <Col span={8}>
                      <img src={call} alt="call" style={{ padding: 3 }} />
                    </Col>
                  </Row>
                </a>
              </Button>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Button
                type="secondary"
                style={{
                  backgroundColor: '#ffffff',
                  borderRadius: 99,
                  padding: 2,
                  marginLeft: 9,
                  width: 120
                }}
                onClick={() =>
                  window.open(
                    'https://wa.me/917208070768',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                <Row>
                  <Col span={18}>Chat Now</Col>
                  <Col span={6}>
                    <img src={whatsapp} alt="whatsapp" style={{ padding: 1 }} />
                  </Col>
                </Row>
              </Button>
            </Row>
          </Affix>
        </Flex> */}
      </FirstSection>
      <MobileFirstSection
        ref={aboutSectionRef}
        className="sm:hidden"
        style={{ marginTop: 50 }}
      >
        <img
          ref={aboutSectionRef}
          src={career_beam_festival}
          alt=""
          style={{
            width: '100%'
          }}
        />

        {/* <Flex justify="flex-end" style={{ marginRight: 10 }}>
          <Affix offsetTop={650}>
            <Row>
              <Button
                type="secondary"
                style={{
                  backgroundColor: '#ffffff',
                  borderRadius: 99,
                  marginLeft: 32,
                  padding: 0,
                  width: 95
                }}
              >
                <a href="tel:+917208070768">
                  <Row>
                    <Col span={16} style={{ padding: 3 }}>
                      Call Us
                    </Col>
                    <Col span={8}>
                      <img src={call} alt="call" style={{ padding: 3 }} />
                    </Col>
                  </Row>
                </a>
              </Button>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Button
                type="secondary"
                style={{
                  backgroundColor: '#ffffff',
                  borderRadius: 99,
                  padding: 2,
                  marginLeft: 9,
                  width: 120
                }}
                onClick={() =>
                  window.open(
                    'https://wa.me/917208070768',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                <Row>
                  <Col span={18}>Chat Now</Col>
                  <Col span={6}>
                    <img src={whatsapp} alt="whatsapp" style={{ padding: 1 }} />
                  </Col>
                </Row>
              </Button>
            </Row>
          </Affix>
        </Flex> */}
      </MobileFirstSection>
    </>
  );
};

export default AscendBeyondSection;

const FirstSection = styled.div`
  background-image: url(${Desktop_bg});
  background-repeat: no-repeat;
  background-size: 100%;

  @media screen and (min-width: 768px) {
    height: 697px;
  }
  @media screen and (min-width: 1024px) {
    height: 550px;
  }
`;

const MobileFirstSection = styled.div``;
