import React from "react";
import styled from "styled-components";
import pic1 from "../assets/pic1.png";
import pic2 from "../assets/pic2.png";
import pic3 from "../assets/pic3.png";
import pic4 from "../assets/pic4.png";

import in1 from "../assets/in1.png";
import in2 from "../assets/in2.png";
import in3 from "../assets/in3.png";
import in4 from "../assets/in4.png";

const IntiumPic = () => {
  const images = [pic1, pic2, pic3, pic4];

  return (
    <div>
      <section class="bg-white dark:bg-white pb-12">
        <div class="flex flex-col w-full mt-16 text-center">
          <span class=" align-middle former-head">
            Former champs who aced it with their innovative ideas
          </span>
        </div>

        <div className="public-div">
          <div class="lg:flex justify-center lg:gap-24 mt-14 picture-section">
            <div class="flex flex-col items-center sm:">
              <p class=" w-32 h-32 rounded-full ">
                <img src={in1} alt="" />
              </p>
            </div>

            <div class="flex flex-col items-center ">
              <p class="w-32 h-32  rounded-full">
                <img src={in2} alt="" />
              </p>
            </div>

            <div class="flex flex-col items-center ">
              <p class="w-32 h-32  rounded-full ">
                <img src={in3} alt="" />
              </p>
            </div>

            <div class="flex flex-col items-center ">
              <p class="w-32 h-32  rounded-full ">
                <img src={in4} alt="" />
              </p>
            </div>
          </div>
        </div>
        <div className="mobile-pics">
          <div
            className="mobile-pics"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  width: "103.11px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  marginTop: "20px",
                  // boxSizing: "border-box",
                  padding: "5px",
                }}
                className="mobi"
              >
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: "50%" }}
                />
              </div>
            ))}
          </div>
        </div>

        <div class="flex flex-col w-full mt-12 text-center">
          <span class="team">UDITA WADHWA & TEAM (IIT DELHI)</span>
        </div>
      </section>
    </div>
  );
};

export default IntiumPic;

const PublicDiv = styled.div``;
