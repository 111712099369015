import React from "react";
import intium from "../assets/intium-head.png";
import styled from "styled-components";
import intiumbanner from "../assets/card2.png";
import intiumMobile from "../assets/intium-mobile.png";
import small from "../assets/smallinitium.png";
import { intiumPreview } from "../images";

const IntiumScreen = () => {
  return (
    <div>
      <Img src={intium} alt="" />
      <Mobileimage src={intiumMobile} alt="" />

      <MobileView>
        <h2>About Initium</h2>
        <img src={intiumPreview} alt="" />
        <MobilePara>
          Initium is a leading platform where students from diverse fields can
          give life to their innovative ideas. The ideas are turned into a
          full-fledged business plan with experts’ guidance and monetary gains.
          The main goal is to nurture entrepreneurship among students.
        </MobilePara>
      </MobileView>

      <InfoDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6rem",
            paddingBottom: "6rem",
            marginLeft: "6rem",
            justifyContent: "center",
            backgroundColor: "#F3F6FB;",
          }}
        >
          <div style={{ flex: "1", marginLeft: "6rem" }}>
            <H2>About Initium</H2>

            <P>
              Initium is a leading platform where students from diverse fields
              can give life to their innovative ideas. The ideas are turned into
              a full-fledged business plan with experts’ guidance and monetary
              gains. The main goal is to nurture entrepreneurship among
              students.
            </P>
          </div>
          <div style={{ flex: 1, marginRight: "7rem" }}>
            <img
              src={intiumPreview}
              alt="Info Image"
              style={{
                width: "100%",
                maxWidth :"24rem",
                height: "auto",
                display: "flex",
                margin: "auto",
              }}
            />
          </div>
        </div>
      </InfoDiv>
    </div>
  );
};

export default IntiumScreen;

const Img = styled.img`
  width: 100%;
  position: relative;
  bottom: 0.9rem;
  /* padding-top: 4.5rem; */
  @media (max-width: 786px) {
    display: none;
  }
`;
const H2 = styled.h2`
  color: #062229;
  font-family: "Manrope", Sans-serif;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 40px;
`;

const P = styled.p`
  text-align: left;
  color: #062229;
  width: 80%;
  font-family: "Rubik", Sans-serif;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 786px) {
    width: 100%;
  }
`;

const Div = styled.div`
  @media (min-width: 786px) {
  }

  @media (max-width: 786px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const Mobileimage = styled.img`
  @media (min-width: 786px) {
    display: none;
  }
  display: flex;
  width: 100%;
  margin-top: 4rem;
`;

const InfoDiv = styled.div`
  @media (max-width: 786px) {
    display: none;
  }
`;
const MobileView = styled.div`
  @media (min-width: 786px) {
    display: none;
  }
  h2 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    width: 200px;
    padding-top: 2rem;
    padding-bottom: 3rem;
    height: 33px;
    display: flex;
    margin-top: 40px;
    justify-content: center;
    margin: auto;
    color: #062229;
  }
  img {
    display: flex;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const MobilePara = styled.p`
  display: flex;
  width: 343px;
  justify-content: center;
  margin: auto;
  padding-bottom: 2rem;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  /* or 164% */

  text-align: center;

  color: #062229;
`;
