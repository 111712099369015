import React from "react";
import qs from "../assets/card1.png";
import int from "../assets/card2.png";
import hp from "../assets/card3.png";
import { Link } from "react-router-dom";
import "./Cards.css";
import qsmobile from "../assets/qsmobile.png";
import styled from "styled-components";
import beamcard from "../assets/beamcard.png";
import { intiumPreview, qsPreview } from "../images";

const Cards = () => {
  return (
    <section>
      <div class=" items-center w-full px-5 py-12   cards-div">
        <div class="flex flex-col w-full mb-6 text-center">
          <span className="colab">From Career-Clueless to Career-Focused</span>
        </div>

        <div class="">
          <p class="join-us">
            Join us on a transformative journey to discover the Career you want
            to be in
          </p>

          <div class="mt-8 ">
            <div class="grid lg:w-4/6 mx-auto lg:grid-cols-2">
              <div class="pt-6">
                <img class="img-cards mobile-image" src={beamcard} alt="blog" />
                <div className="big-button qs-button">
                  <div class="container-events">
                    <h1 class="flex justify-center font-medium text-black lg:text-lg">
                      CAREERBEAM FESTIVAL
                    </h1>
                    <Link
                      to="/careerbeam-festival"
                      className="banner-button read-more"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
              <div class="pt-6">
                <img
                  class="img-cards mobile-image"
                  src={qsPreview}
                  alt="blog"
                />
                <div className="big-button qs-button">
                  <div class="container-events">
                    <h1 class="flex justify-center font-medium text-black lg:text-lg">
                      QS
                    </h1>
                    <p className="banner-button read-more">
                      <p>Read More →</p>
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6 ">
                <img
                  class="img-cards mobile-image"
                  src={intiumPreview}
                  alt="blog"
                />
                <div>
                  <div class="container-events intium-event">
                    <h1 class="flex justify-center font-medium text-black lg:text-lg">
                      INITIUM
                    </h1>
                    <Link to="/initium" className="banner-button read-more">
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
              <div class="pt-6">
                <img class="img-cards" src={hp} alt="blog" />
                <div className="big-button">
                  <div class="container-events hp-button">
                    <h1 class="flex justify-center items-center  font-medium text-black lg:text-lg">
                      HP FUTURE READY PODCAST
                    </h1>
                    <Link
                      to="/hp-future-ready-podcast"
                      className="banner-button read-more"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Link className="explore-btn">Explore New Events</Link> */}
      </div>
    </section>
  );
};

export default Cards;

const Img = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;
