import React from 'react';
import { Button, Flex, Segmented, Row, Typography, Col, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import register_success from '../assets/register_success.gif';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const RegisteredSuccessPage = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  return (
    <>
      <div style={{ height: '100vh', alignContent: 'center', marginTop: -60 }}>
        <Row justify={'center'}>
          <img
            src={register_success}
            alt="success"
            style={{ height: 200, width: 200 }}
          />
        </Row>
        <Row justify={'center'}>
          <h1
            style={{
              fontSize: screens?.xs ? 20 : 40,
              textAlign: 'center',
              fontFamily: 'sans-serif'
            }}
          >
            Registered Successfully
          </h1>
        </Row>
        {/* <Row justify={'center'} style={{ marginTop: screens?.xs ? 16 : null }}>
          <h2
            style={{
              fontSize: screens?.xs ? 14 : 16,
              fontFamily: 'sans-serif'
            }}
          >
            Thank you for registering.
          </h2>
        </Row>
        <Row justify={'center'}>
          <h2
            style={{
              fontSize: screens?.xs ? 14 : 16,
              fontFamily: 'sans-serif'
            }}
          >
            Our team will contact you soon for further details.
          </h2>
        </Row> */}
        <Row justify={'center'} style={{ marginTop: 20 }}>
          <Button
            htmlType="submit"
            style={{
              backgroundColor: '#173D7A',
              color: '#ffffff',
              minWidth: '107px'
            }}
            onClick={() => navigate('/')}
          >
            Done
          </Button>
        </Row>
      </div>
    </>
  );
};

export default RegisteredSuccessPage;
