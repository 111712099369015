import React from "react";
import styled from "styled-components";
import cities from "../assets/citiesnew.png";
import sider from "../assets/sider.png";
import cityIndicator from "../assets/cityIndicator.png";

const EventHighlights = () => {
  return (
    <>
      <EventHighlightsComponent>
        <div className="heading-div">
          <h2>Events</h2>
          <h1>Highlights</h1>
        </div>
        <img className="desktop-cities" src={cities} />

        <div className="siders">
          <div>
            <img src={sider} />
            <span>100+</span>
            <p>Speakers</p>
          </div>

          <div>
            <img src={sider} />
            <span>50+</span>
            <p className="w-p">Workshops</p>
          </div>
          <div>
            <img src={sider} />
            <span>300+</span>
            <p>School/Colleges</p>
          </div>
        </div>
      </EventHighlightsComponent>
      <EventHighMobile>
        <div className="heading-div">
          <h2>Events</h2>
          <h1>Highlights</h1>
        </div>

        <div className="siders">
          <div className="div1">
            <img className="sider" src={sider} />
            <span className="span1">100+</span>
            <p>Speakers</p>
          </div>
          <div className="div2">
            <img className="sider" src={sider} />
            <span className="span2">50+</span>
            <p className="w-p">Workshops</p>
          </div>
          <div className="div3">
            <img className="sider" src={sider} />
            <span className="span3">300+</span>
            <p>School/Colleges</p>
          </div>
        </div>
        <img className="cityIndicator" src={cityIndicator} />
      </EventHighMobile>
    </>
  );
};

export default EventHighlights;

const EventHighlightsComponent = styled.div`
  height: 531px;
  background: #00467e;

  @media (max-width: 786px) {
    display: none;
  }

  .heading-div h2 {
    width: 103px;
    height: 54px;
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    color: #84a8ff;
  }

  .heading-div h1 {
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    width: 187px;
    height: 54px;
    color: #ffffff;
  }

  .heading-div {
    margin-left: 104px;
    position: relative;
    top: 98px;
  }
  .desktop-cities {
    margin-left: 30rem;
    margin-left: auto;
    margin-right: 2rem;
    position: relative;
    bottom: 6rem;
  }
  .siders {
    display: flex;
    width: 90%;
    justify-content: center;
    margin: auto;
    justify-content: space-between;
  }

  .siders div {
    display: flex;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 84px;
      line-height: 102px;
      letter-spacing: -0.05em;
      color: #ffe34e;
      position: relative;
      bottom: 1.3rem;
      left: 1rem;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      position: relative;
      top: 4.4rem;
      right: 11rem;
      color: #ffffff;
    }
    .w-p {
      right: 135px;
    }
  }
`;

const EventHighMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  height: auto;
  background: #00467e;
  bottom: 0;

  .heading-div {
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #ffffff;
    margin: auto;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
    /* identical to box height, or 114% */
    display: flex;
  }

  .heading-div h2 {
    color: #84a8ff;
  }

  .sider {
    width: 44px;
    height: 80px;
  }

  .cityIndicator {
    position: relative;
    bottom: 3rem;
    width: 96%;
    margin: auto;
    /* top: 33rem; */
  }
  .span1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 102px;
    width: 154px;
    height: 47px;
    letter-spacing: -0.05em;
    color: #ffe34e;
    position: relative;
    bottom: 1.3rem;
    left: 4rem;
    bottom: 6rem;
  }
  .div1 {
    margin-left: 1.6rem;
  }
  .div2 {
    margin-left: 65px;
  }

  .div3 {
    margin-left: 100px;
  }

  .span2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 102px;
    width: 154px;
    height: 47px;
    letter-spacing: -0.05em;
    margin-left: 4rem;
    color: #ffe34e;
    position: relative;
    bottom: 6rem;
  }

  .span3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 102px;
    width: 154px;
    height: 47px;
    margin-left: 4rem;
    letter-spacing: -0.05em;
    color: #ffe34e;
    position: relative;
    bottom: 6rem;
  }

  P {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    position: relative;
    margin-left: 5rem;
    bottom: 6rem;
  }
`;
