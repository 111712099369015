import React from "react";
import styled from "styled-components";
import Campaignimage1 from "../assets/cone.png.png";
import Campaign2 from "../assets/cone2.png";

const PastCampaign = () => {
  return (
    <>
      <DParent>
        <Child>
          <div class="flex flex-col w-full mb-8 text-center">
            <span class="intium-process colab pt-4">
              About the Past Campaign
            </span>
          </div>
          <p class="w-4/6 text-gray-900 font-semibold text-center m-auto colab-para">
            Everything at CareerBeam is a success story! HP Future Ready Podcast
            was one such success story too, which we conducted at the start of
            2022. In order to make the campaign more interactive and relevant,
            we organised a School Connect Program, conducting various Online
            sessions for 100+ A and A+ Schools. The entire process was divided
            into two simple yet appealing formats: Future Ready Podcast and the
            one-to-one Online Session.
          </p>

          <CampaignImages>
            <img src={Campaignimage1} alt="" />
            <img src={Campaign2} alt="" />
          </CampaignImages>
        </Child>
      </DParent>

      <MParent>
        <Child>
          <div class="flex flex-col w-full mb-8 text-center">
            <span class="abtpast">About the Past Campaign</span>
          </div>
          <CampaignImages>
            <img className="campaignimageone" src={Campaignimage1} alt="" />
            <img className="campaignimagetwo" src={Campaign2} alt="" />
          </CampaignImages>
          <p class="abtpara">
            Everything at CareerBeam is a success story! HP Future Ready Podcast
            was one such success story too, which we conducted at the start of
            2022. In order to make the campaign more interactive and relevant,
            we organised a School Connect Program, conducting various Online
            sessions for 100+ A and A+ Schools. The entire process was divided
            into two simple yet appealing formats: Future Ready Podcast and the
            one-to-one Online Session.
          </p>
        </Child>
      </MParent>
    </>
  );
};

export default PastCampaign;

const DParent = styled.div`
  /* height: 657px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MParent = styled.div`
  /* height: 657px; */
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Child = styled.div`
  padding-top: 2rem;
  background-color: #e6f1f9;
  padding-bottom: 3rem;
  /* height: 500px; */
  @media (max-width: 768px) {
    p {
      margin-top: 4rem;
      width: 88%;
    }
  }
`;

const CampaignImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  justify-content: center;

  margin-top: 44px;

  img {
    width: 399px;
    height: 222px;
  }

  @media (max-width: 786px) {
    img {
      width: 232px;
      height: 129px;
      margin-top: 2rem;
    }
    .campaignimageone {
      position: relative;
      left: 3.5rem;
      bottom: 2rem;
    }
    .campaignimagetwo {
      position: relative;
      right: 3.5rem;
      top: 3rem;
    }
  }
`;
