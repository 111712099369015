import axios from 'axios';
import { useEffect, useRef } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Footer } from './components/Footer';
import { NewNav } from './components/NewNav';
import SurveyForm from './components/SurveyForm';
import Thankyou from './components/Thankyou';
import AboutScreen from './screens/AboutScreen';
import ContactusScreen from './screens/ContactusScreen';
import HomeScreen from './screens/HomeScreen';
import IntiumScreen from './screens/IntiumScreen';
import PodcastScreen from './screens/PodcastScreen';
import QsScreen from './screens/QsScreen';
// import NewForm from "./screens/NewForm";
import CareerbeamNew from './components/Careerbeam-New';
import GoogleTagManager from './components/GoogleTagManeger';
// import PopupComponent from "./components/OtpComponent";
import Cinema2023 from './screens/Cinema';
import TermsCondition from './components/TermsCondition';
import CancellationPolicy from './components/CancellationPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Sharing from './components/Sharing';
import Blog from './pages/Blog';
import BlogInner from './pages/BlogInner';
import CareerBeamFestival from './screens/CareerBeamFestival';
import MainState from './components1/helper/context/MainState';
import EmailLogin from './pages/EmailLogin';
import VerifyOtp from './pages/VerifyOtp';
import ApplicationForm from './pages/ApplicationForm';
import EventSchedule from './pages/EventSchedule';
import UniversityList from './pages/UniversityList';
import University from './pages/University';
import './styles/animation.css';
import Festival from './pages/Festival';
import ScanQR from './pages/ScanQR';
import RegisteredSuccessPage from './pages/RegisteredSuccessPage';
import StickyCallUsAndChatNow from './components/StickyCallUsAndChatNow';
import PageNotFound from './pages/PageNotFound';
import AltScanQR from './pages/AltScanQR';
import VisitDateSuccessPage from './pages/VisitDateSuccessPage';
import { appRoutes } from './utils';

function App() {
  // let [isOpen, setIsOpen] = useState(false);
  const coursesRef = useRef(null);

  // const flag = localStorage.getItem("MUDRANK::USER");

  const navRef = useRef();

  useEffect(() => {
    navRef?.current?.focus();
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  // const pageTitle = 'CareerBeam - ';

  // useEffect(() => {
  //   // Update the document title with the current screen name and personalized title
  //   document.title =
  //     pageTitle +
  //     (location.pathname
  //       .replace('/', '')
  //       .replace(/^\w/, (c) => c.toUpperCase()) || 'Home');
  // }, [location, pageTitle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    axios.post('http://localhost:5000/api/recieve', formDataObj);
    localStorage.setItem('MUDRANK::USER', 'MUDRANK::USER:DONE');
    // console.log(formDataObj);
    event.target.reset();

    toast.success(
      'Thank you for submiting your information we will get in touch with you soon...'
    );
  };

  const parsedData = window.location.pathname;

  return (
    <>
      <GoogleTagManager />
      <div className="App">
        <MainState>
          <NewNav ref={navRef} />
          {appRoutes?.includes(parsedData) ? <StickyCallUsAndChatNow /> : ''}

          <Routes>
            <Route path="/careerbeam-form" element={<SurveyForm />} />
            <Route path="/" element={<HomeScreen className="animate" />} />
            <Route path="/about-us" element={<AboutScreen />} />
            <Route path="/contact-us" element={<ContactusScreen />} />
            {/* <Route
              path="/qs-mba-and-master-fair"
              element={<QsScreen coursesRef={coursesRef} />}
            /> */}
            {/* <Route path='/verify-otp' element={<PopupComponent/>}/> */}
            <Route path="/initium" element={<IntiumScreen />} />
            <Route
              path="/hp-future-ready-podcast"
              element={<PodcastScreen />}
            />
            <Route path="/thankyou" element={<Thankyou />} />
            {/* <Route path="/careerbeam" element={<Careerbeam />} /> */}
            {/* <Route path="/form" element={<NewForm />} /> */}
            <Route path="/ascend" element={<CareerbeamNew />} />
            {/* <Route path="/celebrate-cinema" element={<Cinema2023 />} /> */}
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route
              path="/cancellation-refund-policy"
              element={<CancellationPolicy />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping" element={<Sharing />} />
            <Route path="/blog" element={<Blog />} />
            {/* <Route exact path="/:slug" element={<BlogInner />} /> */}

            {/* Phase 2 routes start from here */}
            <Route
              path="/careerbeam-festival"
              element={<CareerBeamFestival />}
            />

            <Route path="/event-registration" element={<EmailLogin />} />
            <Route path="/verify" element={<VerifyOtp />} />
            <Route path="/appForm" element={<ApplicationForm />} />
            <Route path="/event-schedule" element={<EventSchedule />} />
            <Route path="/universityList" element={<UniversityList />} />
            <Route path="/university" element={<University />} />
            <Route path="/festival1" element={<Festival />} />
            <Route path="/scanQR" element={<ScanQR />} />
            <Route path="/AltScanQR" element={<AltScanQR />} />
            <Route
              path="/registration-success"
              element={<RegisteredSuccessPage />}
            />
            <Route
              path="/visit-date-update-success"
              element={<VisitDateSuccessPage />}
            />
            {/* <Route path="/page-not-found" element={<PageNotFound />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {window.location.pathname != '/thankyou' && (
            <Footer handleSubmit={handleSubmit} coursesRef={coursesRef} />
          )}
        </MainState>
      </div>
    </>
  );
}

export default App;
