import React from 'react';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

function CancellationPolicy() {
  return (
    <div>
      <Helmet>
        <title>Cancellation & Refund Policy | CareerBeam</title>
        {/* <meta
          name="title"
          content="Cancellation & Refund Policy | CareerBeam"
        /> */}
        <meta
          name="description"
          content="Understand CareerBeam’s policy regarding cancellations and refunds."
        />
        <link
          rel="canonical"
          href="https://careerbeam.in/cancellation-refund-policy"
        />
        <meta name="robots" content="all" />
      </Helmet>
      {/* <MetaTags>
        <meta
          name="title"
          content="Cancellation & Refund Policy | CareerBeam"
        />
        <meta
          name="description"
          content="Understand CareerBeam’s policy regarding cancellations and refunds."
        />
      </MetaTags> */}

      <div>
        <div className="container mx-auto ">
          <h1 className="text-3xl my-4 font-bold border-2 border-b-black">
            Cancellation & Refund Policy
          </h1>
          <p className="text-[#ccc] font-semibold my-4">
            Last updated on Oct 10th 2023
          </p>
          <p>No cancellations & Refunds are entertained.</p>
          <div style={{ height: '350px' }}></div>
          {/* <div className='my-4 italic font-semibold'>Disclaimer: The above content is created at Vigor LaunchPad's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible 
        for any claims and liability that may arise due to merchant’s non-adherence to it.</div> */}
        </div>
      </div>
    </div>
  );
}

export default CancellationPolicy;
