import React from "react";
import c1 from "../assets/podcastpeople/c1.png";
import c2 from "../assets/podcastpeople/c2.png";
import c3 from "../assets/podcastpeople/c3.png";
import c4 from "../assets/podcastpeople/c4.png";
import c5 from "../assets/podcastpeople/c5.png";
import c6 from "../assets/podcastpeople/c6.png";
import c7 from "../assets/podcastpeople/c7.png";
import c8 from "../assets/podcastpeople/c8.png";
import c9 from "../assets/podcastpeople/c9.png";
import c10 from "../assets/podcastpeople/c10.png";
import c11 from "../assets/podcastpeople/c11.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PodcastPic = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const people = [
    {
      name: "Ms. Saniya Kareen",
      designation: "Vice Chaiman",
      school: "Elate Intl School",
      image: c3,
    },
    {
      name: "Dr. Jaya Parekh",
      designation: "Principal",
      school: "Ram Ratan Intl School",
      image: c2,
    },
    {
      name: "Mr. M Madan Mohan",
      designation: "Vice Principal",
      school: "Symbiosis Intl School",
      image: c1,
    },
    {
      name: "Mrs. Meenakshi Mehta",
      designation: "Principal",
      school: "Gyananda",
      image: c4,
    },
    {
      name: "Mr. Jayapal Reddy",
      designation: "Principal",
      school: "Monte Intl School",
      image: c5,
    },
    {
      name: "Mrs. Krishna Sri L",
      designation: "Principal",
      school: "Kairos Intl School",
      image: c6,
    },
    {
      name: "Mrs. Latha Balan",
      designation: "Principal",
      school: "Oasis Intl School",
      image: c7,
    },
    {
      name: "Ms. Vidya Guruprasad",
      designation: "Principal",
      school: "Ryan Global School",
      image: c8,
    },
    {
      name: "Mrs. Maria D’Souza Gujjar",
      designation: "Principal",
      school: "Rejoice Intl School",
      image: c9,
    },
    {
      name: "Mrs. Meenakumari E",
      designation: "Principal",
      school: "Edify World School",
      image: c10,
    },
    {
      name: "Ms. Seema Sapru",
      designation: "Principal",
      school: "The Heritage School",
      image: c11,
    },
  ];

  return (
    <>
      <div className=" pb-8 podcast-div">
        <div class="flex flex-col w-full mt-6 text-center lg:pb-24 sm:pb-10">
          <span class=" podcast-heading">
            Educational Professionals who participated in the last event
          </span>
        </div>
        <div className="people-div">
          <Carousel
            focusOnSelect={true}
            showDots={true}
            arrows={false}
            responsive={responsive}
            class="bg-white dark:bg-white"
          >
            {people &&
              people.map((person) => (
                <div class="flex flex-col items-center pb-14 podcast-screen-images">
                  <p class="w-32 h-32 rounded-full ">
                    <img src={person.image} alt="" />
                  </p>
                  <h1 class=" font-semibold h-2 mx-auto mt-6 person-name">
                    {person.name}
                  </h1>

                  <p class=" h-2 mx-auto mt-4 person-deg">
                    {person.designation}
                  </p>

                  <p class=" h-2 mx-auto mt-4 person-com ">{person.school}</p>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default PodcastPic;
