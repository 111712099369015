import React from "react";
import GoogleTagManager from "./GoogleTagManeger";

const AboutHero = () => {
  return (
    <>
      <GoogleTagManager />
      <section class="w-full bg-white">
        <div class="relative items-center w-full px-5 py-6 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-20">
          <div class="flex w-full mx-auto text-left">
            <div class="relative inline-flex items-center mx-auto align-middle">
              <div class="text-center">
                <h1 class="our-mission">
                  Our Mission <br class="hidden lg:block" />
                </h1>
                <p class="our-mission-para">
                  To create a strong and trustworthy connection with students,
                  schools, colleges, universities, educational professionals and
                  parents for every educational and Career-related requirement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutHero;
