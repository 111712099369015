import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Segmented,
  Row,
  Typography,
  Col,
  Grid,
  Checkbox,
  Form,
  Input,
  Alert
} from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const ScanQR = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [agentPassword, setAgentPassword] = useState('');
  const [responseData, setResponseData] = useState({});

  const navigate = useNavigate();
  const screens = useBreakpoint();

  let [searchParams] = useSearchParams();

  const uniqueCode = searchParams.get('uniquecode');

  const onFinish = async (values) => {
    const data = {
      ...values,
      QRCode: uniqueCode ?? null
    };
    setIsSubmitting(true);

    try {
      const response = await axios
        .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/qr/verify`, data)
        .then((res) => {
          setResponseData(res?.data);
          setError('');

          setIsSubmitting(false);

          // navigate('/registration-success');
        });
    } catch (error) {
      setIsSubmitting(false);

      console.log('error caught', error);
      setError(error?.response?.data?.message);

      return (
        <Alert
          message="Error"
          description={error?.response?.data?.message}
          type="error"
          showIcon
        />
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickVisited = async (visitDay) => {
    const data = {
      visitedDate: visitDay,
      agentPassword,
      guestId: responseData?.guestInfo?._id
    };
    setIsSubmitting(true);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/qr/updateVisitdate`,
          data
        )
        .then((res) => {
          setIsSubmitting(false);
          setError('');

          navigate('/visit-date-update-success');

          toast.success(`Date Recorded Sucessfully For ${visitDay}!`);
        });
    } catch (error) {
      setIsSubmitting(false);

      console.log('error caught', error);
      setError(error?.response?.data?.message);

      return (
        <Alert
          message="Error"
          description={error?.response?.data?.message}
          type="error"
          showIcon
        />
      );
    }
  };

  return (
    <div style={{ alignContent: 'center', marginTop: screens?.xs ? 90 : 20 }}>
      <Row justify={'center'}>
        <Col md={{ span: 8 }} xs={{ span: 24 }}>
          <h2
            style={{
              textAlign: screens?.xs ? 'center' : 'right',
              fontFamily: 'sans-serif',
              paddingRight: screens?.xs ? null : 30
            }}
          >
            Enter your password
          </h2>
        </Col>
        <Col
          md={{ span: 16 }}
          xs={{ span: 24 }}
          style={{ padding: screens?.xs ? 8 : null }}
        >
          <Form
            name="basic"
            wrapperCol={{
              span: 22
            }}
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify={'left'}>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label=""
                  name="agentPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]}
                  validateStatus={error?.length ? 'error' : null}
                  help={error ?? ''}
                >
                  <Input.Password
                    placeholder="Enter Your Stall Password"
                    onChange={(e) => {
                      setAgentPassword(e.target.value);
                      if (e.target.value.length < 1) {
                        setError('');
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: 8 }} xs={{ span: 24 }}>
                <Row justify={screens?.xs ? 'center' : 'left'}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{
                        backgroundColor: '#1c75ba',
                        color: '#ffffff',
                        minWidth: '107px'
                      }}
                      htmlType="submit"
                    >
                      {isSubmitting ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                          fullscreen
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify={'center'}>
        <Title
          level={4}
          style={{
            letterSpacing: 1,
            fontWeight: 400
          }}
        >
          Guest Information
        </Title>

        {/* {
  "name": "Mohammed Hasan Khan",
  "email": "Khanhasankh42@gmail.com",
  "role": "student",
  "contact": 9867771372,
  "details": {
    "school_name": "Khmw degree college ",
    "course_name": "Masters ",
    "year_of_study": "Graduate ",
    "course_interested": "Fashion Business Management",
    "study_abroad": "Yes",
    "interested_in_carribean": "Yes",
    "attending_day": "4th & 5th May",
    "chooseEvent": "Just visiting",
    "attending_events": {
      "4th May 2024": [],
      "5th May 2024": []
    },
    "biggest_challenge": "Yes",
    "topic_suggested": "Al & ml"
  },
  "createdAt": {
    "$date": "2024-04-21T16:12:39.244Z"
  },
  "updatedAt": {
    "$date": "2024-04-21T16:12:39.244Z"
  },
  "__v": 0,
  "uniqueCode": "123456789",
  "visited": false
} */}
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Unique UserId:{' '}
          <strong>{responseData?.guestInfo?.guestUserCode ?? '-'}</strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Name: <strong>{responseData?.guestInfo?.name ?? '-'}</strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Email: <strong>{responseData?.guestInfo?.email ?? '-'}</strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Contact: <strong>{responseData?.guestInfo?.contact ?? '-'}</strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Role: <strong>{responseData?.guestInfo?.role ?? '-'}</strong>
        </Title>
      </Row>

      <Row justify={'center'} style={{ marginTop: 10 }}>
        <Title
          level={4}
          style={{
            letterSpacing: 1,
            fontWeight: 400
          }}
        >
          Other Details
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          School Name:{' '}
          <strong>
            {responseData?.guestInfo?.details?.school_name ?? '-'}
          </strong>
        </Title>
      </Row>

      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Course Name:{' '}
          <strong>
            {responseData?.guestInfo?.details?.course_name ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Study Year:{' '}
          <strong>
            {responseData?.guestInfo?.details?.year_of_study ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Course Interested In:{' '}
          <strong>
            {responseData?.guestInfo?.details?.course_interested ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Has Studied Abroad?:{' '}
          <strong>
            {responseData?.guestInfo?.details?.study_abroad ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Is Interested In Career Beam:{' '}
          <strong>
            {responseData?.guestInfo?.details?.interested_in_carribean ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Attending Day:{' '}
          <strong>
            {responseData?.guestInfo?.details?.attending_day ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Chosen Event:{' '}
          <strong>
            {responseData?.guestInfo?.details?.chooseEvent ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Biggest Challenge:{' '}
          <strong>
            {responseData?.guestInfo?.details?.biggest_challenge ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'}>
        <Title
          level={5}
          style={{
            letterSpacing: 1,
            fontWeight: 300
          }}
        >
          Suggested Topic:{' '}
          <strong>
            {responseData?.guestInfo?.details?.topic_suggested ?? '-'}
          </strong>
        </Title>
      </Row>
      <Row justify={'center'} style={{ marginTop: 3, marginBottom: 15 }}>
        <Button
          size="large"
          style={{
            backgroundColor: _.isEmpty(responseData) ? '#f5f5f5' : '#1c75ba',
            color: _.isEmpty(responseData) ? '#00000040' : '#ffffff',
            minWidth: '107px'
          }}
          disabled={_.isEmpty(responseData)}
          onClick={() => onClickVisited('4th May')}
        >
          {isSubmitting ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              fullscreen
            />
          ) : (
            ' Visited on 4th May'
          )}
        </Button>
        <Button
          size="large"
          style={{
            backgroundColor: _.isEmpty(responseData) ? '#f5f5f5' : '#1c75ba',
            color: _.isEmpty(responseData) ? '#00000040' : '#ffffff',
            minWidth: '107px',
            marginLeft: 15
          }}
          disabled={_.isEmpty(responseData)}
          onClick={() => onClickVisited('5th May')}
        >
          {isSubmitting ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              fullscreen
            />
          ) : (
            'Visited on 5th May'
          )}
        </Button>
      </Row>
    </div>
  );
};

export default ScanQR;
