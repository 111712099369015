import styled from "styled-components";
import EventLocation from "./EventLocation";
import delhi from "../assets/delhi.png";
import bangalore from "../assets/banglore.png";
import mumbai from "../assets/mumbai.png";
import india from "../assets/india.png";
import hyderabad from "../assets/hyderabad.png";
import { Link } from "react-router-dom";

const location = [
  {
    city: "New Delhi",
    date: "14 April 2024",
    time: "1:00PM- 4:30PM",
    address:
      "Shangri-La Eros Hotel, 19 Ashoka Road, New Delhi",
    image: delhi,
    link: "",
    // link: "https://docs.google.com/forms/d/e/1FAIpQLSd3x5IlLoGq3gQAU9YVy-jCj7MRkYHvuVVF3MwwLq1mqYLJuA/viewform",
  },

  {
    city: "Mumbai",
    date: "16 April 2024",
    time: "6:00PM- 9:30PM",
    address: "The St. Regis Mumbai, 462, Senapati Bapat Marg, Mumbai",
    image: mumbai,
    link: "",
    // link: "https://docs.google.com/forms/d/e/1FAIpQLSfkWZM-FY__57OkO3OR6wjYV4c5nDvY7WourtArhjDG0U0qtg/viewform",
  },

  {
    city: "Hyderabad",
    date: "18 April 2024",
    time: "6:00PM- 9:30PM",
    address: "THyatt Place Hyderabad Banjara Hills, Road no 1, Banjara Hills,, Hyderabad",
    image: hyderabad,
    link: "",
    // link: "https://docs.google.com/forms/d/e/1FAIpQLSe01AgtmdMuOvQF-6urF1VCugMB7fCHp2QOuP2mqVfEBOwPDw/viewform",
  },
  {
    city: "Bangalore",
    date: "21 April 2024",
    time: "2:30PM- 6:00PM",
    address: "Taj MG Road Bengaluru, 41/3 Mahatma Gandhi Road, Bangalore",
    image: bangalore,
    link: "",
    // link: "https://docs.google.com/forms/d/e/1FAIpQLSdhkURxAbc7IUQlcx-wH0pL-Vp7riLAAOseHx79VLbGWp3S8A/viewform"
  },
];
const EventLocationComponent = ({
  location,
  date,
  time,
  address,
  imageSrc,
  alt,
}) => {
  return (
    <section className="hover:-translate-y-2 cursor-pointer">
      <Parent>
        <ImageandLocation>
          <img src={imageSrc} alt={alt} />
          <Info className="flex flex-col">
            <h1>{location}</h1>
            <p>{date}</p>
            <p>{time}</p>
          </Info>
        </ImageandLocation>
        <LocationDiv>
          <p>{address}</p>
          {/* <span>Register Now {">"} </span> */}
        </LocationDiv>
      </Parent>
    </section>
  );
};
const TabComponent = styled.div`
  p {
  }
`;
const EventLocations = () => {
  return (
    <>
      <GridContainer>
        {location.map((event) => (
          // <Link to={event.link}>
          <EventLocationComponent
            location={event.city}
            date={event.date}
            time={event.time}
            address={event.address}
            imageSrc={event.image}
            key={event.location}
          />
          // </Link>
        ))}
      </GridContainer>
    </>
  );
};

export default EventLocations;

const Parent = styled.div`
  border-radius: 8px;
  background: #ffffff;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  gap: 20px;
  padding-bottom: 3rem;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ImageandLocation = styled.div`
  display: flex;
  width: 346px;
  box-shadow: 0px 0px 18px rgba(99, 99, 99, 0.12);
  border-radius: 8px;
  background: #4472c4;

  &:hover {
    background: #4a66ac;
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 6px 0px 0px 0px;
  }
  h1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 16px;
    margin-top: 14px;
    color: #ffffff;
  }
`;
const LocationDiv = styled.div`
  display: flex;
  width: 346px;
  border-radius: 8px;
  height: 130px;
  box-shadow: 0px 0px 18px rgba(99, 99, 99, 0.12);
  display: flex;
  flex-direction: column;
  /* or 162% */

  span {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 1rem;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height, or 144% */

    color: #4472c4;
    /* margin: auto; */
  }

  color: #062229;
  p {
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin: auto;
    width: 314px;
  }
`;

const Info = styled.div`
  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-left: 16px;
    /* identical to box height, or 162% */
    margin-top: 4px;
    color: #ffffff;
  }
`;
