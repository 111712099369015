import React, { useState, useCallback, useMemo } from "react";

const RadioBtn2 = ({ text, options, name, onChange, mark }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setSelectedOption(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const optionElements = useMemo(() => {
    return options.map((option, idx) => (
      <div key={idx} style={{ display: "flex", gap: "0.5rem" }}>
        <input
          type="radio"
          id={`${name}-option-${idx}`}
          name={name}
          value={option}
          checked={selectedOption === option}
          onChange={handleOptionChange}
        />
        <label htmlFor={`${name}-option-${idx}`}>{option}</label>
      </div>
    ));
  }, [options, name, selectedOption, handleOptionChange]);

  return (
    <div>
      <label
        style={{
          // fontWeight: "400",
          fontSize: "1rem",
          color: "#1E1E2F",
        }}
      >
        {text}
        {mark && <span style={{ color: "red" }}>*</span>}
      </label>
      <br />
      <div style={{ display: "flex", gap: "2rem", marginTop: "1rem" }}>
        {optionElements}
      </div>
    </div>
  );
};

export default RadioBtn2;
