import React, { useState } from 'react';
import './Hamburger.css';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import QS from '../screens/QsScreen';
import INTIUM from '../screens/IntiumScreen';
import PODCAST from '../screens/PodcastScreen';
import HomeScreen from '../screens/HomeScreen';
import AboutScreen from '../screens/AboutScreen';
import ContactusScreen from '../screens/ContactusScreen';
import SurveyForm from './SurveyForm';
import dn from '../assets/dn.png';
import Cinema2023 from '../screens/Cinema';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSublist, setShowSublist] = useState(false);
  const [activeScreen, setActiveScreen] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSublist = () => {
    setShowSublist(!showSublist);
  };

  const handleScreenClick = (screen) => {
    setActiveScreen(screen);
    setIsOpen(false);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/">
            <img className="" src={Logo} alt="" />
          </Link>
        </div>
        <button
          className={`navbar-toggle ${isOpen ? 'active' : ''}`}
          onClick={toggleMenu}
        >
          <span className="navbar-icon-bar"></span>
          <span className="navbar-icon-bar"></span>
          <span className="navbar-icon-bar"></span>
        </button>
        <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
          <div className="navbar-logo-menu-item">
            <img className="" src={Logo} alt="" />
          </div>
          <li className="navbar-menu-item">
            <Link to="/" onClick={() => handleScreenClick(<HomeScreen />)}>
              Home
            </Link>
          </li>
          <li className="navbar-menu-item">
            <Link
              to="/about-us"
              onClick={() => handleScreenClick(<AboutScreen />)}
            >
              About
            </Link>
          </li>
          <li className="navbar-menu-item">
            <Link
              to="/contact-us"
              onClick={() => handleScreenClick(<ContactusScreen />)}
            >
              Contact
            </Link>
          </li>
          <li
            className={`navbar-menu-item ${showSublist ? 'open' : ''}`}
            onClick={toggleSublist}
          >
            {/* <span to="">
              Events
              <img
                className="mobile-down"
                style={{ display: 'inline-block' }}
                src={dn}
                alt=""
              />
            </span>
            <ul className="navbar-sublist">
              <li className="navbar-sublist-item">
                <Link
                  to="/careerbeam-festival"
                  onClick={() => handleScreenClick(<PODCAST />)}
                >
                  CareerBeam Festival
                </Link>
              </li>
              <li className="navbar-sublist-item">
                <Link
                  to="/celebrate-cinema"
                  onClick={() => handleScreenClick(<Cinema2023 />)}
                >
                  Celebrate Cinema 2023
                </Link>
              </li>
              <li className="navbar-sublist-item">
                <Link onClick={() => handleScreenClick(<QS />)}>QS</Link>
              </li>

              <li className="navbar-sublist-item">
                <Link
                  to="/initium"
                  onClick={() => handleScreenClick(<INTIUM />)}
                >
                  Initium
                </Link>
              </li>
              <li className="navbar-sublist-item">
                <Link
                  to="/hp-future-ready-podcast"
                  onClick={() => handleScreenClick(<PODCAST />)}
                >
                  Podcast
                </Link>
              </li>
            </ul> */}
          </li>

          {/* <Link
            className="button-parent-div"
            to="/survey-form"
            onClick={() => handleScreenClick(<SurveyForm />)}
          >
            <button className="survey-form-button ">✎ Survey Form</button>
          </Link> */}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
