export const card1 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/card-mobile.png';

export const eventRegistrationImage =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Event-registration-Banner-Mobile.png';

export const backgroundBlur =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/background-blur.png';

export const backgroundCloudNarrow =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/background-cloud-narrow.png';
export const backgroundCloudWide =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/background-cloud-wide.png';

export const backgroundSolid =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/background-solid.png';
export const bartending =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/bartending.png';
export const CbLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/CbLogo.png';
export const dance =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/dance.png';
export const flash =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/flash.png';
export const flea =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/flea.png';
export const gaming =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/gaming.png';
export const heroimage =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/heroimage.png';
export const image99 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/image99.png';
export const Law =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Law.png';
export const Logo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Logo.png';
export const movie =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/movie.png';
export const mumbai =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Mumbai+1+(1).png';
export const mumbai1 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Mumbai.png';
export const music =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/music.png';
export const vr =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/vr.png';

//   ICons
export const hospitality =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Hospitality.png';
export const computerApplications =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/ComputerApplications.png';
// export const vr ="https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Law.png"
export const fashion =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Fashion.png';
export const medical =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Medical.png';
export const eventManagement =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/EventManagement.png';
export const animation =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Animation.png';
export const musicIcon =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Music.png';
export const sports =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Sports.png';
export const military =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Military.png';
export const paramedical =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Paramedical.png';
export const pharmacy =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Pharmacy.png';
export const education =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Education.png';
export const engineering =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Engineering.png';
export const art =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Art.png';
export const filmMaking =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/FilmMaking.png';
export const aviation =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Aviation.png';
export const tech =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Tech.png';

export const architecture =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/icons/Architecture.png';

export const eventBackground =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/event-background.png';

export const image2 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Homepage-Banner-carousel-Desktop.png';

export const banner1 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/banner1.png';

export const banner3 =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/banner3.png';

export const goenkaa =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Goenkaa.png';

export const cs =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/cs.png';

export const aboutus =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/aboutus.png';

export const qsPreview =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/QS1.png';

export const qsBanner =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/qsBanner.png';

export const intiumPreview =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/InitiumPreview.png';
