import React from "react";
import "swiper/css";
import "swiper/css/navigation";

  const BlogGrid3 = ({ myProp, onClick }) => {

  if (!myProp) {
  console.error('Invalid myProp data:', myProp);
  return null;
}

const {  Image, Created_On, Title, Slug } = myProp;
  const imageStyle = {
    objectFit: 'cover',
    backgroundPosition: 'center',
    height: '200px',  
  };
  
  return (
    <>
      <section className="p-2 mb-10 mx-auto">
        <button onClick={() => onClick(myProp.ID)}>
          <div className="my-auto">
            <img
              src={Image}
              style = {imageStyle}
              className="mb-3 w-full"
              alt=""
            />
            <div className="px-2 text-center">
              <p className="text-blue-500 font-bold">
                {Title}
              </p>
              {/* <div className="bg-white rounded-full shadow-sm absolute p-3 "> */}
                <p className="text-sm text-gray-700 leading-5 pt-2 mt-3">
                  {Created_On}
                </p>
              {/* </div> */}
            </div>
          </div>
        </button>
      </section>
    </>
  );
};

export default BlogGrid3;
