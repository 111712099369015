import React from "react";
import "../styles/CityList.css";

const cities = [
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Dehradun",
  "Hyderabad",
  "Pune",
  "Ranchi",
  "Lucknow",
  "Guwahati",
  "Indore",
  "Delhi",
  "Darjeeling",
  "Patna",
  "Goa",
  "Chandigarh",
  "Kolkata",
  "Jaipur",
  "Bhopal",
  "Ahmedabad",
  "Mangalore",
];

function CityList() {
  const numCols = 3; // number of columns in the grid
  const numRows = Math.ceil(cities.length / numCols); // number of rows in the grid
  const cellHeight = 262 / numRows; // height of each grid cell

  return (
    <div className="city-grid-container-m">
      {cities.map((city, index) => (
        <div
          className="city-grid-item-m"
          key={city}
          style={{
            height: cellHeight + "px",
            padding: "10px",
          }}
        >
          <span>{city}</span>
        </div>
      ))}
    </div>
  );
}

export default CityList;
