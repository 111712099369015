import React from 'react';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

function Sharing() {
  return (
    <div>
      <Helmet>
        <title>Shipping Information | CareerBeam</title>
        <meta
          name="description"
          content="Get details on shipping and delivery when you shop with CareerBeam."
        />
        <link rel="canonical" href="https://careerbeam.in/shipping" />
        <meta name="robots" content="all" />
      </Helmet>
      {/* <MetaTags>
        <meta name="title" content="Shipping Information | CareerBeam" />
        <meta
          name="description"
          content="Get details on shipping and delivery when you shop with CareerBeam."
        />
      </MetaTags> */}
      <div>
        <div className="container mx-auto ">
          <h1 className="text-3xl my-4 font-bold border-2 border-b-black">
            Shipping & Delivery Policy
          </h1>
          <p className="text-[#ccc] font-semibold my-4">
            Last updated on Oct 10th 2023
          </p>
          <p>Shipping is not applicable for business.</p>
          <div style={{ height: '350px' }}></div>
          {/* <div className='my-4 italic font-semibold'>Disclaimer: The above content is created at Vigor LaunchPad's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible 
        for any claims and liability that may arise due to merchant’s non-adherence to it.</div> */}
        </div>
      </div>
    </div>
  );
}

export default Sharing;
