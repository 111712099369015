import React from 'react';
import intium from '../assets/intium-head.png';
import styled from 'styled-components';
import intiumbanner from '../assets/intiumbanner.png';
import IntiumFirst from '../components/IntiumFirst';
import IntiumProcess from '../components/IntiumProcess';
import IntiumPic from '../components/IntiumPic';
import MoreEvents from '../components/MoreEvents';
// import Cards from "../components/Cards";
import GoogleTagManager from '../components/GoogleTagManeger';
import MetaTags from 'react-meta-tags';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const IntiumScreen = () => {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <title>Initium | Launch Your Career | CareerBeam</title>
        {/* <meta
          name="title"
          content="CareerBeam: Next-Gen Career Guidance for Young India"
        /> */}

        <meta
          name="description"
          content="Discover Initium, where aspiring professionals gain essential skills and knowledge."
        />
        <link rel="canonical" href="https://careerbeam.in/initium" />
        <meta name="robots" content="all" />
        <meta
          property="og:title"
          content="Initium | Launch Your Career | CareerBeam"
        />
        <meta property="og:site_name" content="CareerBeam" />
        <meta property="og:url" content="https://careerbeam.in/initium" />
        <meta
          property="og:description"
          content="Discover Initium, where aspiring professionals gain essential skills and knowledge."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/intium-head.f4b57d45031ee4a8c656.png"
        />
      </Helmet>

      {/* <MetaTags>
        <meta
          name="title"
          content="Initium | Launch Your Career | CareerBeam"
        />
        <meta
          name="description"
          content="Discover Initium, where aspiring professionals gain essential skills and knowledge."
        />
      </MetaTags> */}
      <IntiumFirst />
      <IntiumProcess />
      <IntiumPic />
      <MoreEvents />
    </div>
  );
};

export default IntiumScreen;
