import ModalComponent from '../components/ModalComponent';
import Cards from '../components/Cards';
import MyComponent from '../components/MyComponent';
import React from 'react';
import HandleSlide from '../components/HandleSlide';
import styled from 'styled-components';
import '../styles/animation.css';
// import CC from "../components/CC";
import NewCarousel from '../components/NewCarousel';
// import Carousel from "../carousel-component/Carousel";
// import { Link } from "react-router-dom";
import GoogleTagManager from '../components/GoogleTagManeger';
// import paperPlane from "../assets/paper-plane.png";
import MetaTags from 'react-meta-tags';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HomeScreen = ({ handleSubmit }) => {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <title>CareerBeam: Next-Gen Career Guidance for Young India</title>
        {/* <meta
          name="title"
          content="CareerBeam: Next-Gen Career Guidance for Young India"
        /> */}

        <meta
          name="description"
          content="Discover your dream career with CareerBeam! Explore career options, attend events & unlock insights for a successful future. Strong network connects you to opportunities across India."
        />
        <link rel="canonical" href="https://careerbeam.in/" />
        <meta name="robots" content="all" />

        <meta
          property="og:title"
          content="CareerBeam: Next-Gen Career Guidance for Young India"
        />
        <meta property="og:site_name" content="CareerBeam" />
        <meta property="og:url" content="https://careerbeam.in/" />
        <meta
          property="og:description"
          content="Discover your dream career with CareerBeam! Explore career options, attend events & unlock insights for a successful future. Strong network connects you to opportunities across India."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/festivalBanner.86d513e39dc2ca9f8d2a.png></meta"
        />
      </Helmet>

      {/* <MetaTags>
        <meta
          name="title"
          content="CareerBeam: Next-Gen Career Guidance for Young India"
        />
        <meta
          name="description"
          content="Discover your dream career with CareerBeam! Explore career options, attend events & unlock insights for a successful future. Strong network connects you to opportunities across India."
        />
      </MetaTags> */}

      {/* <Link to="/form">
        <button
          style={{
            position: "fixed",
            bottom: 20,
            right: 160,
            cursor: "pointer",
            display: "block",
            zIndex: "30",
          }}
          className=" register-button-guidance"
          // onClick={handleRegisterNowClick}
        >
          <Img className="icn" src={paperPlane} />
          <span className="relative bottom-0.5 guidance ">Need Guidance?</span>
        </button>
      </Link> */}
      <MyComponent className="animate" />
      <ModalComponent className="animate" />
      <HandleSlide className="animate" />
      {/* <Cards className="animate" /> */}
      <Div className="animate">
        <div class="mt-12 pt-6 flex flex-col w-full  text-center  pb-6">
          <span class="carousel-heading">
            Know why CareerBeam is the{' '}
            <span className="first-choice">first choice</span> of Students,
            Teachers and Parents
          </span>
          <NewCarousel />
        </div>
      </Div>
    </div>
  );
};

export default HomeScreen;
const Div = styled.div`
  padding-bottom: 2rem;
  background-color: #e9f2f9;
  .first-choice {
    color: #0070c0;
  }
  .carousel-heading {
    @media (max-width: 768px) {
    }
  }
`;
const Img = styled.img`
  @media (min-width: 786px) {
    position: relative;
    top: 0.7rem;
    left: 0.5rem;
  }

  @media (max-width: 786px) {
    position: relative;
    top: 0.7rem;
    left: 0.9rem;
  }
`;
