import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Affix,
  Grid
} from 'antd';

import { festival_Mobile_Bg } from '../festival_images';
import MetaTags from 'react-meta-tags';

// import first_section_background from '../assets/festival_Mobile_Bg.png';
// import career_beam_festival from '../assets/career_beam_festival.svg';
// import call from '../assets/call_icon.svg';
// import whatsapp from '../assets/whatsapp_icon.svg';

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const MobileAscendBeyondSection = ({
  aboutSectionRef,
  registrationSectionRef,
  handleScrollOnClick
}) => {
  const screens = useBreakpoint();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex,nofollow" />
      </MetaTags>

      <FirstSection ref={aboutSectionRef} id="about" className="sm:hidden">
        <Flex
          justify={'center'}
          align="center"
          className="pt-3"
          ref={aboutSectionRef}
        >
          <Row justify="space-around" align="middle">
            <Col xs={{ span: 23, offset: 1 }}>
              <Title
                level={1}
                style={{
                  color: '#ffffff',
                  letterSpacing: 1,
                  fontWeight: 400,
                  fontSize: screens?.xs ? 32 : 46,
                  fontFamily: 'DIN',
                  textAlign: 'center'
                }}
              >
                <span style={{ color: '#F9DC16', fontWeight: 400 }}>
                  <strong>ASC</strong>
                </span>
                end Beyond
              </Title>
              <Paragraph
                level={4}
                style={{
                  color: '#ffffff',
                  fontFamily: 'sans-serif',
                  fontSize: 18,
                  fontWeight: 400,
                  textAlign: 'center'
                }}
              >
                CareerBeam Festival is your one-stop destination for
                comprehensive career development needs. Explore beyond the
                traditional fields of education and ASCend Beyond to new
                horizons with 100+ universities, 100+ industry experts who will
                conduct 50+ seminars, workshops and panel discussions. Not just
                that, network with budding entrepreneurs at our flea market and
                uncover the secrets for a successful career.
              </Paragraph>
              <Row justify={'center'}>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#212121',
                    minWidth: 149,
                    fontWeight: 600,
                    height: 45
                  }}
                  size={'large'}
                  onClick={() => handleScrollOnClick(registrationSectionRef)}
                >
                  Register Now
                </Button>
              </Row>
            </Col>
          </Row>
        </Flex>
      </FirstSection>
    </>
  );
};

export default MobileAscendBeyondSection;

const FirstSection = styled.div`
  background-image: url(${festival_Mobile_Bg});
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 401px;
`;
