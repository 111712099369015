export const workShopData = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Adding creativity', '10:30 am - 11:10 am'],
    [
      'Imagination to Reality: Building Your Architectural Dreams',
      '11:20 am - 12:00 pm'
    ],
    ['Decode your dreams', '11:20 am - 12:00 pm'],
    ['#You can also be a Stand-Up Comedian', '01:00 pm - 01:40 pm'],
    ['Design Your Dream Space', '01:50 pm - 02:30 pm'],
    ['The Science of Nutrition', '02:40 pm - 03:20 pm'],
    ['Express Your Style Through Jewellery', '03:30 pm - 04:10 pm'],
    ['The Art of Versatile Acting', '03:30 pm - 04:10 pm'],
    ['Building Blockbuster Roles for the Screen', '04:20 pm - 05:00 pm']
  ]
};

export const appFormWorkShopData = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Aviation Technology', '10:30 am - 11:10 am'],
    ['⁠Liberal Arts', '11:20 am - 12:00 pm'],
    ['⁠Macrame', '11:20 am - 12:00 pm'],
    ['⁠Careers in Merchant Navy', '01:00 pm - 01:40 pm'],
    ['⁠Beyond Fear', '01:50 pm - 02:30 pm'],
    ['⁠Scholarship Test', '02:40 pm - 03:20 pm']
  ]
};

export const eventscheduleWorkShopData = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Inauguration', '10:30 am - 11:10 am'],
    ['Aviation Technology', '12:10 pm - 12:50 pm'],
    ['⁠Liberal Arts', '01:00 pm - 01:40 pm'],
    ['⁠Macrame', '01:50 pm - 02:30 pm'],
    ['⁠Careers in Merchant Navy', '02:40 pm - 03:20 pm'],
    ['⁠Beyond Fear', '03:30 pm - 04:10 pm'],
    ['⁠Scholarship Test', '04:15 pm - 05:15 pm']
  ]
};

export const eventScheduleFormPanelData = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Inauguration', '10:30 am - 11:10 am'],
    [
      'The Influence of Performing Arts on Our Professional Paths',
      '11:10 am - 11:40 pm'
    ],
    [
      '⁠The Role of Educators and Employers in Promoting positive mental health and well-being',
      '11:50 pm - 12:20 pm'
    ],
    ['Session by Eyecare Partner', '12:20 pm - 12:30 pm'],
    [
      'Shaping the Future: A dialogue on National Education Policy',
      '12:30 pm - 01:30 pm'
    ],
    ['⁠Entrepreneurship and startups', '01:40 pm - 02:10 pm'],
    ['Careers in Film-making, Animation & VFX', '02:20 pm - 02:50 pm'],
    ['⁠Design as a career option', '03:00 pm - 03:30 pm'],
    ['⁠Go Green with Your Career', '03:40 pm - 04:10 pm'],
    [
      'Unleashing Your Voice: The Art and Science of Hosting and Anchoring',
      '04:20 pm - 04:50 pm'
    ]
  ]
};

export const eventFormWorkShopData2 = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Career Options after HSC at MKES Nagindas Khandwala College',
      '12:10 pm - 12:50 pm'
    ],
    ['Liberal Education in the World of Opportunities', '01:00 pm - 01:40 pm'],
    ['⁠Exploring Careers at Sea', '01:50 pm - 02:30 pm'],
    ['Key Aspects of Technical Writing', '02:40 pm - 03:20 pm'],
    ['Future Careers in the Music Industry', '03:30 pm - 04:30 pm'],
    ['Scholarship test', '04:40 pm - 05:20 pm']
  ]
};

export const eventFormPanelData2 = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Promoting Diversity and Inclusion in Career Choices.',
      '11:10 am - 11:40 pm'
    ],
    [
      'Positive impacts of social media for creators & change makers',
      '11:50 pm - 12:20 pm'
    ],
    [
      '⁠Navigating the Intersection of Technology and Mental Health',
      '12:30 pm - 01:30 pm'
    ],
    ['Counsellor’s Certification', '01:30 pm - 01:40 pm'],
    ['Creative careers in today’s world', '01:40 pm - 02:10 pm'],
    ['Digital Marketing and its Possibilities with AI', '02:20 pm - 02:50 pm']
  ]
};

export const appFormPanelData = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'The Influence of Performing Arts on Our Professional Paths',
      '10:30 am - 11:00 am'
    ],
    [
      '⁠The Role of Educators and Employers in Promoting positive mental health and well-being',
      '11:10 am - 11:40 pm'
    ],
    ['Session by Eyecare Partner', '11:50 pm - 12:20 pm'],
    [
      'Shaping the Future: A dialogue on National Education Policy',
      '12:30 pm - 01:30 pm'
    ],
    ['⁠Entrepreneurship and startups', '01:40 pm - 02:10 pm'],
    ['Careers in Film-making, Animation & VFX', '02:20 pm - 02:50 pm'],
    ['⁠Design as a career option', '03:00 pm - 03:30 pm'],
    ['⁠Go Green with Your Career', '03:40 pm - 04:10 pm'],
    [
      'Unleashing Your Voice: The Art and Science of Hosting and Anchoring',
      '04:20 pm - 04:50 pm'
    ]
  ]
};

export const appFormWorkShopData2 = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Career Options after HSC at MKES Nagindas Khandwala College',
      '10:30 am - 11:10 am'
    ],
    ['⁠Liberal Education in the World of Opportunities', '11:20 am - 12:00 pm'],
    ['⁠Future Careers in the Music Industry', '11:20 am - 12:00 pm'],
    ['⁠Scholarship test', '01:00 pm - 01:40 pm']
  ]
};

export const appFormPanelData2 = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Promoting Diversity and Inclusion in Career Choices',
      '10:30 am - 11:00 am'
    ],
    [
      'Positive impacts of social media for creators & change makers',
      '11:10 am - 11:40 pm'
    ],
    [
      '⁠Navigating the Intersection of Technology and Mental Health',
      '11:50 pm - 12:20 pm'
    ],
    ['Creative careers in today’s world', '12:30 pm - 01:30 pm']
  ]
};

export const seminarData = {
  heading: 'Seminar',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Application of Robotics', '10:30 am - 11:10 am'],
    [
      'The Integration of Data Analytics in Healthcare Industry',
      '11:20 am - 12:00 pm'
    ],
    ['Building a Digital Business Model', '12:10 pm - 12:50 pm'],
    ['An Introduction to Product-led Growth', '01:00 pm - 01:40 pm'],
    ['BTS of Building End-to-End Web Applications', '01:50 pm - 02:30 pm'],
    ['How to Hire the Right Talent for an Organisation?', '02:40 pm - 03:20 pm']
  ]
};

export const panelData = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Inauguration', '10:30 am - 11:00 am'],
    ['Where to Get Started in Biotech?', '11:10 am - 11:40 pm'],
    ['Mental Health in Modern Learning Environments', '11:50 pm - 12:20 pm'],
    ['CareerBeam Launch 🎊🎊', '12:30 pm - 01:30 pm'],
    ['Decoding Careers in Finance', '01:40 pm - 02:10 pm'],
    ['Go Green With Your Career', '02:20 pm - 02:50 pm'],
    ['In-Demand Skills in Machine Learning', '03:00 pm - 03:30 pm'],
    ['Is a Blockchain Career Right for You?', '03:40 pm - 04:10 pm'],
    ['Aligning Your Education With Athletic Careers', '04:20 pm - 04:50 pm'],
    ['Make Money With Winning Prompts', '04:20 pm - 04:50 pm']
  ]
};

export const workShopData1New = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Design as a career option.', '01:50 pm - 02:30 pm'],
    ['Beyond Fear', '03:30 pm - 04:10 pm'],
    ['Scholarship Test', '04:20 pm - 05:20 pm']
  ]
};

export const panelData1New = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Liberal Arts', '10:30 am - 11:00 am'],
    [
      'The Influence of Performing Arts on Our Professional Paths',
      '11:10 am - 11:40 pm'
    ],
    ['Mental-Well being', '11:50 pm - 12:20 pm'],
    ['National Education Policy / Principal Meet-up', '12:30 pm - 01:30 pm'],
    ['Entrepreneurship and startups', '01:40 pm - 02:10 pm'],
    ['Go Green with Your Career', '02:20 pm - 02:50 pm'],
    ['Careers in Film-making, Animation & VFX', '03:00 pm - 03:30 pm']
  ]
};

export const workShopData2New = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Careers in Merchant Navy', '01:50 pm - 02:30 pm'],
    ['Scholarship test', '03:30 pm - 04:10 pm'],
    ['Marketing', '04:20 pm - 05:00 pm']
  ]
};

export const panelData2New = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Digital Marketing and its Possibilities with AI', '10:30 am - 11:00 am'],
    [
      'Promoting Diversity and Inclusion in Career Choices.',
      '11:10 am - 11:40 am'
    ],
    [
      'Positive impacts of social media for creators & change makers',
      '11:50 am - 12:20 pm'
    ],
    [
      `Digital Marketing and it's new possibilities with AI`,
      '01:10 pm - 01:40 pm'
    ]
  ]
};

export const workShopData2 = {
  heading: 'Workshop',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Designing User-Friendly Interfaces for Digital Products',
      '10:30 am - 11:10 am'
    ],
    ['Skin 101: Know Your Dermatology Essentials', '11:20 am - 12:00 pm'],
    ['Go Above and Beyond with an Aerospace Career', '12:10 pm - 12:50 pm'],
    ['Find Your Voice in the Courtroom', '01:00 pm - 01:40 pm'],
    ['Build Your Own App', '01:50 pm - 02:30 pm'],
    ['Create Your First Video Game Concept', '02:40 pm - 03:20 pm'],
    [
      'Explore Your Perception Through Data Visualisation',
      '03:30 pm - 04:10 pm'
    ],
    ['Building a Profitable Real Estate Portfolio', '04:20 pm - 05:00 pm']
  ]
};
export const seminarData2 = {
  heading: 'Seminar',
  colHeading: ['Topic Name', 'Time'],
  data: [
    [
      'Urban Planning vs. Architecture: How Diverse Are These Two Career Paths?',
      '10:30 am - 11:10 am'
    ],
    ["The 7 P's of Event Marketing", '11:20 am - 12:00 pm'],
    ["AI's Evolution in Advertising", '12:10 pm - 12:50 pm'],
    ['Key Aspects of Technical Writing', '01:00 pm - 01:40 pm'],
    ['The Future of Drone Technology in Aviation', '01:50 pm - 02:30 pm'],
    ['The Basics of Cloud Architecture', '02:40 pm - 03:20 pm'],
    ['Colour Theory and Application in Graphic Design', '03:30 pm - 04:10 pm'],
    ['The Future Scope of Nano Technology', '04:20 pm - 05:00 pm']
  ]
};

export const panelData2 = {
  heading: 'Panel Discussion',
  colHeading: ['Topic Name', 'Time'],
  data: [
    ['Careers in BIM (Building Information Modeling)', '10:30 am - 11:00 am'],
    [
      'Careers in Foreign Languages beyond Translator and Interpreter',
      '11:00 am - 11:40 am'
    ],
    ['Rewarding Careers in Social Science', '11:50 am - 12:20 pm'],
    ['The Future of Performance Marketing', '12:30 pm - 01:00 pm'],
    ['Can You Take Gaming as a Career in India?', '01:10 pm - 01:40 pm'],
    ['How to Break Into the Indian Music Industry?', '01:50 pm - 02:20 pm'],
    [
      'Expanding Your Creative Horizon and Creating Stunning Graphics',
      '02:30 pm - 03:00 pm'
    ],
    [
      'User Generated Content (UGC) and Other Trends in Digital Marketing',
      '03:10 pm - 03:40 pm'
    ],
    ['Non-flying Careers in Aviation', '03:50 pm - 04:20 pm'],
    ['Writing Across Genres', '04:30 pm - 05:00 pm']
  ]
};

export const degrees = [
  'Others',
  'Science',
  'Commerce',
  'Arts',
  'Diploma',
  'Bachelor of Mass Media (BMM)',
  'Bachelor of Arts (B.A)',
  'Bachelor of Engineering (B.E.)',
  'Bachelor of Technology (B.Tech.)',
  'Bachelor of Pharmacy (B.Pharm.)',
  'Bachelor of Architecture (B.Arch.)',
  'Bachelor of Science (B.Sc.)',
  'Bachelor of Commerce (B.Com.)',
  'Bachelor of Management Studies (B.M.S.)',
  'Bachelor of Education (B.Ed.)',
  'Bachelor of Fine Art (B.F.A.)',
  'Bachelor of Performing Art (B.P.A.)',
  'Bachelor’s Degree in Library & Information Science (B.L.I.Sc.)',
  'Bachelor Of Vocational Program (B. Voc)',
  'Bachelor of Law (LL.B.)',
  'Bachelor in Paint Technology',
  'Bachelor in Interior Design',
  'Bachelor in Animation',
  'Bachelor in Visual Effects',
  'Bachelor in Film Art',
  'Bachelor in Screen Writing',
  'Bachelor in Film Production & Management',
  'Bachelor in Psychological Health & Behavioral Science',
  'Bachelor in Biomedical Instrumentation Science',
  'Bachelor in Capital Market',
  'Bachelor Of Dental Surgery (BDS)',
  'MBBS',
  'Bachelor Of Design (B. Des)',
  'Bachelor of Hospital Management (BHM)',
  'Bachelor of Hotel Management (BHM)',
  'Bachelor of Business Administration (BBA)',
  'Bachelor of Financial Markets (BFM)',
  'Bachelor of Social Work (BSW)',
  'Bachelor of Business Management (BBM)'
];

export const fields = [
  'Others',
  'Interior Design',
  'Jewellery Design',
  'Fashion Communication',
  'Fashion Business Management',
  'Photography',
  'Hair and Make-up Artistry',
  'Maritime Studies',
  'Nautical Science',
  'Logistics',
  'Marine Engineering',
  'Performing Arts',
  'Fashion Design',
  'Communication Design',
  'Engineering',
  'Management',
  'Arts, Sciences',
  'Pharmacy',
  'Law',
  'Film Making',
  'Journalism',
  'Biotechnology',
  'Dentistry',
  'Social Sciences',
  'Study Abroad',
  'Global Education',
  'Study Abroad Prep',
  'Luxury Management',
  'Product Design',
  'Visual Arts',
  'Sports Management',
  'Artificial Intelligence',
  'Design Thinking & Innovation',
  'Animation',
  'VFX',
  'Film Making',
  'Acting'
];
