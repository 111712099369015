import React from "react";
import delhi from "../assets/delhi.png";
import bangalore from "../assets/banglore.png";
import mumbai from "../assets/mumbai.png";
import india from "../assets/india.png";
import hyderabad from "../assets/hyderabad.png";
import styled from "styled-components";

const EventLocation = () => {
  const location = [
    {
      city: "Bangalore",
      date: "13 April 2023",
      time: "15:30 - 18:30 (IST)",
      address: "Taj MG Road, 41/3 Mahatma Gandhi Road, Bangalore",
      image: bangalore,
    },
    {
      city: "Mumbai",
      date: "15 April 2023",
      time: "13:30 – 17:00 (IST)",
      address: "President, 90 Cuffe Parade, Mumbai, 400 005",
      image: mumbai,
    },
    {
      city: "New Delhi",
      date: "18 April 2023",
      time: "17:30 – 21:00 (IST)",
      address:
        "The Westin Gurgaon, Number 1, MG Road, Sector 29, New Delhi NCR, New Delhi",
      image: delhi,
    },
    {
      city: "Hyderabad",
      date: "20 April 2023",
      time: "17:30 – 21:00 (IST)",
      address: "Taj Krishna, Road No. 1, Hyderabad",
      image: hyderabad,
    },
    {
      city: "India",
      date: "18 May 2023",
      time: "19:00pm – 21:30 (IST)",
      address: "Online",
      image: india,
    },
  ];

  return (
    <>
      {/* <section class="text-gray-600 body-font">
        <div class="container px-5  mx-auto">
          <div class="flex flex-wrap -m-2">
            {location.map((loc) => (
              <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
                <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                  <img
                    alt="team"
                    class="w-28 h-28 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                    src={loc.image}
                  />
                  <div class="flex-grow">
                    <h2 class="text-gray-900 title-font font-bold">
                      {loc.city}
                    </h2>
                    <span class="text-gray-500 font-medium">{loc.date}</span> |{" "}
                    <span class="text-gray-500 font-medium">{loc.time}</span>
                    <p class="text-gray-500 font-medium">
                      Taj MG Road, 41/3 Mahatma Gandhi Road, Bangalore
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section>
        {location.map((loc) => {
          <Parent>
            <ImageandLocation>
              <img src={loc.image} alt="" />
              <Info className="flex flex-col">
                <h1>{loc.city}</h1>
                <p>{loc.address}</p>
                <p>{loc.time}</p>
              </Info>
            </ImageandLocation>
            <LocationDiv>
              <p>
                The Lalit New Delhi Barakhamba Avenue, Connaught Place, New
                Delhi, 110001
              </p>
            </LocationDiv>
          </Parent>;
        })}
      </section>
    </>
  );
};

export default EventLocation;

const Parent = styled.div`
  border-radius: 8px;
  background: #ffffff;
  height: 284px;
`;

const ImageandLocation = styled.div`
  display: flex;
  width: 346px;
  box-shadow: 0px 0px 18px rgba(99, 99, 99, 0.12);
  border-radius: 8px;

  /* flex-direction: column; */
  background: #4472c4;
  img {
    width: 120px;
    height: 120px;
    border-radius: 6px 0px 0px 0px;
  }
  h1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 16px;
    margin-top: 14px;
    color: #ffffff;
  }
`;
const LocationDiv = styled.div`
  display: flex;
  width: 346px;
  height: 160px;
  box-shadow: 0px 0px 18px rgba(99, 99, 99, 0.12);
  align-items: center;
  p {
    text-align: center;
  }
`;

const Info = styled.div`
  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-left: 16px;
    /* identical to box height, or 162% */
    margin-top: 4px;
    color: #ffffff;
  }
`;
