import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Card,
  Grid
} from 'antd';

// import festival_people from '../assets/festival_people.svg';

import {
  law, engineering, computer, events, paramedical,
  hospitality, filmMaking, pharmacy, tech, fashion, education, media,
  aviation, achitecture, art, music, animate, medical, military, sports,
} from "../festival_images"

// import law from '../assets/career_option_logos/festival_logo_law.svg';
// import engineering from '../assets/career_option_logos/festival_logo_engineering.svg';
// import computer from '../assets/career_option_logos/festival_logo_computer.svg';
// import events from '../assets/career_option_logos/festival_logo_events.svg';
// import paramedical from '../assets/career_option_logos/festival_logo_paramedical.svg';
// import hospitality from '../assets/career_option_logos/festival_logo_hospitality.svg';
// import filmMaking from '../assets/career_option_logos/festival_logo_film-making.svg';
// import pharmacy from '../assets/career_option_logos/festival_logo_pharmacy.svg';
// import tech from '../assets/career_option_logos/festival_logo_tech.svg';
// import fashion from '../assets/career_option_logos/festival_logo_fashion.svg';
// import education from '../assets/career_option_logos/festival_logo_education.svg';
// import media from '../assets/career_option_logos/festival_logo_media.svg';
// import aviation from '../assets/career_option_logos/festival_logo_aviation.svg';
// import achitecture from '../assets/career_option_logos/festival_logo_achitecture.svg';
// import art from '../assets/career_option_logos/festival_logo_art.svg';
// import music from '../assets/career_option_logos/festival_logo_music.svg';
// import animate from '../assets/career_option_logos/festival_logo_animate.svg';
// import medical from '../assets/career_option_logos/festival_logo_medical.svg';
// import military from '../assets/career_option_logos/festival_logo_military.svg';
// import sports from '../assets/career_option_logos/festival_logo_sports.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const careerOptions = [
  { id: 1, title: 'Law & Finance', logo: law },
  { id: 2, title: 'Engineering', logo: engineering },
  { id: 3, title: 'Computer Applications', logo: computer },
  { id: 4, title: 'Sports', logo: sports },
  { id: 5, title: 'Events & Management', logo: events },
  { id: 6, title: 'Paramedical', logo: paramedical },
  { id: 7, title: 'Hospitality', logo: hospitality },
  { id: 8, title: 'Filmmaking', logo: filmMaking },
  { id: 9, title: 'Pharmacy', logo: pharmacy },
  { id: 10, title: 'Tech & Data Science', logo: tech },
  { id: 11, title: 'Fashion', logo: fashion },
  { id: 12, title: 'Education', logo: education },
  { id: 13, title: 'Media', logo: media },
  { id: 14, title: 'Aviation', logo: aviation },
  { id: 15, title: 'Architecture', logo: achitecture },
  { id: 16, title: 'Art & Design', logo: art },
  { id: 17, title: 'Music', logo: music },
  { id: 18, title: 'Animation', logo: animate },
  { id: 19, title: 'Medical', logo: medical },
  { id: 20, title: 'Military', logo: military }
];

const ExploreCareerSection = ({ exploreCareerSectionRef }) => {
  const screens = useBreakpoint();

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 4500,
    initialSlide: 0,
    autoplaySpeed: 0,
    cssEase: 'linear',
    draggable: true
  };

  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: -2,
    autoplay: true,
    speed: 4500,
    initialSlide: 0,
    autoplaySpeed: 0,
    cssEase: 'linear',

    draggable: true
  };

  const settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 4500,
    initialSlide: 0,
    autoplaySpeed: 0,
    cssEase: 'linear',
    draggable: true
  };

  return (
    <Wrapper
      style={{ minHeight: 400, paddingTop: 40, paddingBottom: 40 }}
      ref={exploreCareerSectionRef}
      id="explore"
    >
      <Row justify="center">
        <Col md={{ span: 8 }} xs={{ span: 18 }}>
          <Title level={2} style={{ color: '#44546A', textAlign: 'center' }}>
            Explore Your Career Options
          </Title>
        </Col>
      </Row>
      <LargerScreenWrapper>
        <Row justify="center">
          {careerOptions?.length
            ? careerOptions?.map((item, ind) => (
              <Col
                md={{ span: 5 }}
                xs={{ span: 9 }}
                style={{ margin: '20px' }}
              >
                <Card bordered={false} key={ind + 1}>
                  <Row justify="start" align="middle">
                    <Col lg={{ span: 6 }} xs={{ span: 24 }}>
                      <img
                        src={item?.logo}
                        style={{
                          margin: screens?.xs || screens?.sm ? 'auto' : null
                        }}
                      />
                    </Col>
                    <Col lg={{ span: 12, offset: 2 }} xs={{ span: 24 }}>
                      <p
                        style={{
                          textAlign:
                            screens?.xs || screens?.sm ? 'center' : null,
                          fontSize: 20,
                          fontWeight: 400
                        }}
                      >
                        {item?.title}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))
            : ''}
        </Row>
      </LargerScreenWrapper>

      <div className="sm:hidden">
        <Row justify="center">
          <div
            className="slider-container"
            style={{ display: 'inline-block', width: '425px' }}
          >
            <Slider {...settings1}>
              {careerOptions?.length
                ? careerOptions?.slice(0, 6)?.map((item, ind) => (
                  <Col md={{ span: 5 }} xs={{ span: 20 }}>
                    <Card
                      bordered={false}
                      key={ind + 1}
                      style={{ height: 156 }}
                    >
                      <Row justify="start" align="middle">
                        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
                          <img
                            src={item?.logo}
                            style={{
                              margin:
                                screens?.xs || screens?.sm ? 'auto' : null
                            }}
                          />
                        </Col>
                        <Col lg={{ span: 12, offset: 2 }} xs={{ span: 24 }}>
                          <p
                            style={{
                              textAlign:
                                screens?.xs || screens?.sm ? 'center' : null,
                              fontSize: 20,
                              fontWeight: 400,
                              marginTop: 10
                            }}
                          >
                            {item?.title}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
                : ''}
            </Slider>
          </div>
        </Row>

        <Row justify="center" style={{ marginTop: 25 }}>
          <div
            className="slider-container"
            style={{ display: 'inline-block', width: '425px' }}
          >
            <Slider {...settings2}>
              {careerOptions?.length
                ? careerOptions?.slice(7, 13)?.map((item, ind) => (
                  <Col md={{ span: 5 }} xs={{ span: 20 }}>
                    <Card
                      bordered={false}
                      key={ind + 1}
                      style={{ height: 156 }}
                    >
                      <Row justify="start" align="middle">
                        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
                          <img
                            src={item?.logo}
                            style={{
                              margin:
                                screens?.xs || screens?.sm ? 'auto' : null
                            }}
                          />
                        </Col>
                        <Col lg={{ span: 12, offset: 2 }} xs={{ span: 24 }}>
                          <p
                            style={{
                              textAlign:
                                screens?.xs || screens?.sm ? 'center' : null,
                              fontSize: 20,
                              fontWeight: 400,
                              marginTop: 10
                            }}
                          >
                            {item?.title}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
                : ''}
            </Slider>
          </div>
        </Row>

        <Row justify="center" style={{ marginTop: 25 }}>
          <div
            className="slider-container"
            style={{ display: 'inline-block', width: '425px' }}
          >
            <Slider {...settings3}>
              {careerOptions?.length
                ? careerOptions?.slice(14, 20)?.map((item, ind) => (
                  <Col md={{ span: 5 }} xs={{ span: 20 }}>
                    <Card
                      bordered={false}
                      key={ind + 1}
                      style={{ height: 156 }}
                    >
                      <Row justify="start" align="middle">
                        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
                          <img
                            src={item?.logo}
                            style={{
                              margin:
                                screens?.xs || screens?.sm ? 'auto' : null
                            }}
                          />
                        </Col>
                        <Col lg={{ span: 12, offset: 2 }} xs={{ span: 24 }}>
                          <p
                            style={{
                              textAlign:
                                screens?.xs || screens?.sm ? 'center' : null,
                              fontSize: 20,
                              fontWeight: 400,
                              marginTop: 10
                            }}
                          >
                            {item?.title}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
                : ''}
            </Slider>
          </div>
        </Row>
      </div>
    </Wrapper>
  );
};

export default ExploreCareerSection;

const Wrapper = styled.div`
  background-color: #a8daff;
`;

const LargerScreenWrapper = styled.div`
  @media (max-width: 430px) {
    display: none;
  }
`;
