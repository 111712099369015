import universityImg from "../assets/university.png";
import roundImg from "../assets/roundImg.png";

const Card = () => {
  return (
    <div
      className="min-w-44 w-1/3 rounded-3xl shadow-md"
      style={{
        borderRadius: ".5rem",
      }}
    >
      <div className="relative">
        <img src={universityImg} alt="university img" className="w-full" />
        <img
          src={roundImg}
          style={{
            width: "4rem",
            height: "4rem",
            borderRadius: "50%",
            position: "absolute",
            bottom: "-2rem",
            left: "1rem",
          }}
          alt="university img"
          className="w-full"
        />
      </div>
      <div className="p-6">
        <div className="mt-4">
          <p>Somaiya University</p>
          <p>93 Mukundapur Main Road Kolkata 700099,</p>

          <div className="mt-8 flex gap-2 w-full">
            <button
              className="w-1/2"
              style={{
                border: "1px solid blue",
                padding: "8px 12px",
                color: "#173D7A",
                width: "50%",
              }}
            >
              View details
            </button>
            <button
              className="w-1/2"
              style={{
                border: "1px solid #173D7A",
                padding: "8px 12px",
                marginLeft: "8px",
                backgroundColor: "#173D7A",
                color: "white",
                width: "50%",
              }}
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UniversityList = () => {
  return (
    <div>
      <div className="flex flex-wrap gap-4 m-auto max-w-4xl mt-8 mb-8">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
};

export default UniversityList;