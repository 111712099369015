import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Grid
} from 'antd';

// import first_section_background from '../assets/First_section_bg.svg';
// import career_beam_festival from '../assets/career_beam_festival.svg';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const EventHighlightSection = () => {
  const screens = useBreakpoint();

  return (
    <SecondSection
      style={{ minHeight: screens?.xs ? 120 : 350, marginTop: 70 }}
      id="event"
    >
      <Row align="middle" className="max-sm:hidden">
        <Col span={6} offset={2}>
          <Title
            level={2}
            style={{ color: '#ffffff', fontSize: 40, fontFamily: 'DIN' }}
          >
            <span style={{ color: '#84A8FF' }}>
              <strong>Event</strong>{' '}
            </span>
            Highlights
          </Title>
        </Col>
      </Row>

      <Row style={{ marginTop: screens?.xs ? -70 : 40 }}>
        <Col md={{ span: 5, offset: 2 }} xs={{ span: 5, offset: 2 }}>
          <Title
            level={4}
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: screens?.xs ? '14px' : '24px',
              textAlign: 'center'
            }}
          >
            <span style={{ color: '#F9DC16', fontSize: screens?.xs ? 32 : 84 }}>
              100+{' '}
            </span>
            <br /> Industry Experts
          </Title>
        </Col>

        <Col md={{ span: 5, offset: 2 }} xs={{ span: 6, offset: 3 }}>
          <Title
            level={4}
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: screens?.xs ? '14px' : '24px',
              textAlign: 'center'
            }}
          >
            <span style={{ color: '#F9DC16', fontSize: screens?.xs ? 32 : 84 }}>
              50+{' '}
            </span>
            <br /> Workshops, Seminars & Panel Discussions
          </Title>
        </Col>

        <Col md={{ span: 5, offset: 2 }} xs={{ span: 6, offset: 1 }}>
          <Title
            level={4}
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: screens?.xs ? '14px' : '24px',
              textAlign: 'center'
            }}
          >
            <span style={{ color: '#F9DC16', fontSize: screens?.xs ? 32 : 84 }}>
              100+{' '}
            </span>
            <br /> Prestigious Institutions
          </Title>
        </Col>
      </Row>
    </SecondSection>
  );
};

export default EventHighlightSection;

const SecondSection = styled.div`
  background-color: #00467e;
`;
