import { RxCaretLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { heroimage } from "../images";

const MainSection = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-solid  text-white w-screen pt-16 md:pt-0">
      <div className="bg-narrow bg-no-repeat bg-cover pb-32 md:flex sm:bg-wide sm:bg-cover p-8 md:justify-between md:items-center ">
        <div className="md:order-last ">
          <img src={heroimage} className="md:max-w-3xl sm:max-w-lg" alt="hero" />
        </div>
        <div className="mt-12 md:mx-auto md:pl-18 ">
          <div className="flex justify-center font-bold md:justify-start items-center ">
            <h1 className="text-4xl md:shrink-0 text-center">
              <span className="text-5xl text-secondary">ASC</span>
              end Beyond
            </h1>
          </div>
          <div className="mt-8 text-lg max-w-md text-center md:text-left mx-auto leading-7 flex justify-center items-center">
            <p className="">
              CareerBeam festival’s 2024 theme, ASCend Beyond, is designed to
              broaden students' career prospects beyond the traditional Arts,
              Science, and Commerce fields of study. The event offers students
              an insight from industry experts, counselors, and advisors. To
              facilitate students we have interactive sessions, workshops, and
              seminars for an in-depth understanding of future career
              opportunities.
            </p>
          </div>
          <div className="flex md:justify-start justify-center items-center mt-12">
            <button
              onClick={() => navigate("/event-registration")}
              className="bg-white text-[#212121] font-bold px-8 py-3 rounded-md md:shrink-0"
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
      <div className="mt-8 mx-10">
        <div className="">
          <h1 className="pl-8 text-3xl font-bold">
            <span className="text-[#84A8FF]  ">Event</span> Highlights
          </h1>
        </div>
        <div className="md:grid grid-cols-3 md:justify-between md:mt-8 pb-4">
          <div className="flex items-center mt-4 md:mt-0">
            <div className="-mr-8">
              <RxCaretLeft color="rgba(22, 127, 209, 1)" size={130} />
            </div>
            <div className="">
              <h2 className="text-6xl font-bold text-secondary">100+</h2>
              <h4 className="text-xl font-bold">Industry Experts</h4>
            </div>
          </div>
          <div className="flex items-center">
            <div className="-mr-8">
              <RxCaretLeft color="rgba(22, 127, 209, 1)" size={130} />
            </div>
            <div className="">
              <h2 className="text-6xl font-bold text-secondary">50+</h2>
              <h4 className="text-xl font-bold">
                Workshops, Seminars and panel discussion
              </h4>
            </div>
          </div>
          <div className="flex items-center">
            <div className="-mr-8">
              <RxCaretLeft color="rgba(22, 127, 209, 1)" size={130} />
            </div>
            <div className="">
              <h2 className="text-6xl font-bold text-secondary">100+</h2>
              <h4 className="text-xl font-bold">Prestigious Institutions</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
