import React from "react";
import styled from "styled-components";

const StandoutBeam = () => {
  const info = [
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Focus on alternate careers",
    },
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Tailor-made assessment tests",
    },
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Interactive workshops by industry professionals",
    },
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Emerging Career opportunities",
    },
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Insightful sessions by Industry experts and youth icons",
    },
    {
      svg: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#FEEA6D" />
        </svg>
      ),
      text: "Student innovation design showcase",
    },
  ];

  return (
    <>
      <Standout>
        <div>
          {info.map((i) => (
            <div>
              <svg>{i.svg}</svg>
              <span>{i.text}</span>
            </div>
          ))}
        </div>
      </Standout>
    </>
  );
};

const StandoutsGrid = () => {
  const mobileInfo = [
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Focus on alternate careers",
    },
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Tailor-made assessment Tests",
    },
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Interactive workshops by industry professionals",
      class: "two-liner",
    },
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Emerging Career dopportunities",
      class: "two-liner",
    },
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Insightful sessions by industry experts and youth icons",
      class: "two-liner",
    },
    {
      svg: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#FEEA6D" />
        </svg>
      ),
      text: "Student innovation design showcase",
      class: "two-liner",
    },
  ];
  return (
    <>
      <Main>
        <H1>
          What Makes <span>CareerBeam</span> Stand Out
        </H1>
        <Grid>
          <StandoutBeam />
        </Grid>
      </Main>
      <StandoutMobile>
        <h1>
          What Makes{" "}
          <em
            style={{
              fontStyle: "normal",
              color: "#1b75ba",
            }}
          >
            CareerBeam
          </em>{" "}
          Stand Out
        </h1>
        {mobileInfo.map((info) => (
          <div>
            <svg>{info.svg}</svg>
            <span className={info.class}>{info.text}</span>
          </div>
        ))}
      </StandoutMobile>
    </>
  );
};

export default StandoutsGrid;

const StandoutMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  .two-liner {
    position: relative;
    bottom: 10.5rem;
  }

  height: 783px;
  background: #dbefff;
  padding-bottom: 1rem;

  h1 {
    font-family: "DIN";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    /* or 146% */
    position: relative;
    top: 48px;
    width: 266px;
    text-align: center;
    margin: auto;
    color: #062229;
  }

  div {
    position: relative;
    top: 100px;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Change to grid layout with auto-fit and minimum width of 200px */
    /* Add gap between columns */
    width: 80%;
    margin: auto;
    margin-bottom: -90px;
    align-items: center; /* Vertically align the contents of each column */
  }

  svg {
  }

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    width: 260px;
    height: 30%;
    /* identical to box height, or 156% */
    position: relative;
    bottom: 9.4rem;
    left: 1.3rem;
    color: #062229;
  }
`;

const Standout = styled.div`
  div {
    display: grid;
    margin: auto;
    width: 80%;
    margin-bottom: -40px;

    grid-template-columns: repeat(3, 1fr);
  }
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    letter-spacing: -0.01em;

    color: #062229;
    width: 293px;
    color: #062229;
    position: relative;
    top: 0.7rem;
    /* right: 0.5rem; */
    /* bottom: 2.6rem; */
    left: 0.6rem;
  }
`;

const Main = styled.div`
  height: 458px;
  background: #dbefff;

  @media (max-width: 786px) {
    display: none;
  }
`;

const Grid = styled.div`
  /* margin-top: 78px; */
  position: relative;
  top: 150px;
  /* display: grid; */
  /* margin: auto; */
  /* width: 80%; */
  /* grid-template-columns: repeat(3, 1fr); */
`;

const H1 = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #44546a;
  /* identical to box height, or 100% */

  text-align: center;
  position: relative;
  top: 78px;

  span {
    color: #1b75ba;
  }
`;
