// import { Pagination, Select } from "antd";
// import Search from "antd/es/input/Search";
import React, { useEffect, useState, useRef } from 'react';

import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Affix
} from 'antd';
import TopSection from '../festival-component/TopSection';
import AscendBeyondSection from '../festival-component/AscendBeyondSection';
import EventHighlightSection from '../festival-component/EventHighlightSection';
import ViewScheduleSection from '../festival-component/ViewScheduleSection';
import ExploreCareerSection from '../festival-component/ExploreCareerSection';
import EventAttraction from '../festival-component/EventAttractions';
import RegistrationForm from '../festival-component/RegistrationFormSection';
import MobileAscendBeyondSection from '../festival-component/MobileViewAscendSection';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';

const Festival = () => {
  let [searchParams] = useSearchParams();

  const utm_source = searchParams.get('utm_source');
  const utm_content = searchParams.get('utm_content');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_GCLID = searchParams.get('utm_GCLID');
  const utm_FBCID = searchParams.get('utm_FBCID');

  const aboutSectionRef = useRef();
  const scheduleSectionRef = useRef();
  const exploreCareerSectionRef = useRef();
  const eventAttractionSectionRef = useRef();
  const registrationSectionRef = useRef();

  const [selectedScheduleInModal, setSelectedScheduleInModal] = useState('');
  const [selectedSection, setSelectedSection] = useState(1);
  const [presentScrollSection, setPresentScrollSection] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // console.log('ref here>>>', aboutSectionRef?.current?.id);

  const handleScrollOnClick = (section) => {
    section?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const utmApiCall = async () => {
    try {
      const response = await axios
        .post('https://backend.careerbeam.in/api/utmInfo/create', {
          utm_source,
          utm_content,
          utm_medium,
          utm_campaign,
          utm_GCLID,
          utm_FBCID,
          pageName: 'festival1'
        })
        .then((res) => {
          setIsSubmitting(false);
        });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (utm_source) {
      utmApiCall();
    }
  }, [utm_source]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (
        aboutSectionRef.current.offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        scheduleSectionRef.current.offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        setSelectedSection(1);
      } else if (
        scheduleSectionRef.current.offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        exploreCareerSectionRef.current.offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        setSelectedSection(2);

        // For the about section
      } else if (
        exploreCareerSectionRef.current.offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        eventAttractionSectionRef.current.offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        setSelectedSection(3);
      } else if (
        eventAttractionSectionRef.current.offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        registrationSectionRef.current.offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        setSelectedSection(4);
      } else {
        setSelectedSection(5);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <TopSection
          aboutSectionRef={aboutSectionRef}
          scheduleSectionRef={scheduleSectionRef}
          exploreCareerSectionRef={exploreCareerSectionRef}
          eventAttractionSectionRef={eventAttractionSectionRef}
          registrationSectionRef={registrationSectionRef}
          handleScrollOnClick={handleScrollOnClick}
          setSelectedSection={setSelectedSection}
          selectedSection={selectedSection}
          presentScrollSection={presentScrollSection}
        />
        <div style={{ backgroundColor: '#00467e' }}>
          <AscendBeyondSection
            aboutSectionRef={aboutSectionRef}
            handleScrollOnClick={handleScrollOnClick}
            registrationSectionRef={registrationSectionRef}
          />
          <EventHighlightSection />
          <MobileAscendBeyondSection
            aboutSectionRef={aboutSectionRef}
            handleScrollOnClick={handleScrollOnClick}
            registrationSectionRef={registrationSectionRef}
          />
        </div>
        <ViewScheduleSection
          scheduleSectionRef={scheduleSectionRef}
          handleScrollOnClick={handleScrollOnClick}
          registrationSectionRef={registrationSectionRef}
          setSelectedScheduleInModal={setSelectedScheduleInModal}
        />
        <ExploreCareerSection
          exploreCareerSectionRef={exploreCareerSectionRef}
        />
        <EventAttraction
          eventAttractionSectionRef={eventAttractionSectionRef}
        />
        <RegistrationForm
          registrationSectionRef={registrationSectionRef}
          selectedScheduleInModal={selectedScheduleInModal}
        />
      </div>
    </>
  );
};

export default Festival;
