export const WhyComponent = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-20 whyc">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-xl md:mb-12">
        <h2 className="process max-w-lg mb-10 colab ">
          <span className="relative inline-block "></span> Why Careerbeam?
        </h2>
      </div>
      <div className="grid gap-5 mb-4 md:grid-cols-2 lg:grid-cols-2">
        <div className="p-4 duration-300 transform bg-white border why-component  rounded-2xl shadow-sm ml-auto">
          <span class="whyspan flex justify-center m-auto h-1 w-16 rounded blue-line lg:mt-10 lg:mb-6 max-sm:my-5"></span>
          <h6 className="mb-2 font-semibold leading-5 flex justify-center text-center  why-para ">
            Helps reach the mass and become choice of the masses
          </h6>
        </div>
        <div className="p-4 duration-300 transform bg-white border why-component rounded-2xl shadow-sm ">
          <span class="whyspan flex justify-center m-auto h-1 w-16 rounded blue-line lg:mt-10 lg:mb-6 max-sm:my-5"></span>

          <h6 className="mb-2 font-semibold leading-5 flex justify-center text-center  why-para ">
            Helps admission department to ideate, curate and execute sessions
            across India
          </h6>
        </div>
        <div className="p-4 duration-300 transform bg-white border why-component rounded-2xl shadow-sm ml-auto">
          <span class="whyspan flex justify-center m-auto h-1 w-16 rounded blue-line lg:mt-10 lg:mb-6 max-sm:my-5"></span>

          <h6 className="mb-2 font-semibold leading-5 flex justify-center text-center  why-para ">
            Creates awareness about the emerging academic programs to be offered
          </h6>
        </div>
        <div className="p-4 duration-300 transform bg-white border why-component rounded-2xl shadow-sm">
          <span class="whyspan flex justify-center m-auto h-1 w-16 rounded blue-line lg:mt-10 lg:mb-6 max-sm:my-5"></span>

          <h6 className="mb-2 font-semibold leading-5 flex justify-center text-center  why-para ">
            Helps teachers expand their student network and create their own
            individual identity on our digital space
          </h6>
        </div>
      </div>
    </div>
  );
};
