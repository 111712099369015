import React from "react";

const IntiumProcess = () => {
  return (
    <>
      <div className="process-screen">
        <div className="px-4 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16  mt-2">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="process intium-process">
              <span className="relative inline-block"></span> Process
            </h2>
          </div>
          <div className="grid gap-5 mb-8 md:grid-cols-3 lg:grid-cols-3">
            <div className="p-5 duration-300 transform bg-white  rounded-lg shadow-sm ">
              <span class="whyspan flex justify-center m-auto h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
              <h6 className=" why-para ">
                Teams are build and are required to ideate on a new service or
                product, come up with a theme and create a business plan around
                it.
              </h6>
            </div>
            <div className="p-5 duration-300 transform bg-white border rounded shadow-sm ">
              <span class="whyspan flex justify-center m-auto h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>

              <h6 className=" why-para">
                The participants get a chance to hone their App Development and
                Business Planning skills with the help of workshops designed and
                delivered by experts.
              </h6>
            </div>
            <div className="p-5 flex flex-col duration-300 transform bg-white border rounded shadow-sm ">
              <span class="whyspan flex justify-center m-auto h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>

              <h6 className=" why-para">
                Winners are finalised after several rounds of evaluation and top
                7 are shortlisted and presented to the esteemed jury from India,
                the USA, and Europe.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntiumProcess;
