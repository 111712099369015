import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import background from "../assets/formback.png";
import Tick from "../assets/tick.gif";
import ReactGA from "react-ga";

const Thankyou = () => {
  function handleClick() {
    ReactGA.event({
      category: "Button",
      action: "Click",
    });
  }

  return (
    <>
      <ThankyouComponent>
        <div>
          <img src={Tick} className="tick" />
          <h1>Registered Successfully</h1>
          <Link className="thankyoulink" to="/">
            <button>Home</button>
          </Link>
        </div>
      </ThankyouComponent>
      <ThankyouPhone>
        <div>
          <img src={Tick} className="mobile-tick" />
          <h1>Registered Successfully</h1>
          <Link className="thankyoulink" to="/">
            <button onClick={handleClick}>Home</button>
          </Link>
        </div>
      </ThankyouPhone>
    </>
  );
};

export default Thankyou;

const ThankyouComponent = styled.div`
  background-image: url(${background});

  height: 90vh;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) {
    .tick {
      position: relative;
      top: 1.5rem;
      height: 67px;
      width: 67px;
      margin: auto;
    }

    background: #dfdfdf;
    display: flex;
    justify-content: center;
    div {
      margin-top: 140px;
      width: 662px;
      height: 387px;
      background-color: white;
      display: flex;
      flex-direction: column;
      margin-bottom: 180px;
    }
    h1 {
      display: flex;
      margin: auto;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      position: relative;
      bottom: 0.8rem;
      /* top: 1.5rem; */
      text-align: center;
      color: #062229;
    }

    button {
      margin: auto;
      position: relative;
      bottom: 3rem;
      width: 160px;
      height: 51px;
      background: #1b75ba;
      border-radius: 6px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      justify-content: center;
      text-align: center;

      color: #ffffff;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .thankyoulink {
      margin: auto;
    }
    background-image: url(${background});
  }
`;

const ThankyouPhone = styled.div`
  height: 100vh;
  overflow: hidden;
  @media (min-width: 786px) {
    display: none;
  }

  .mobile-tick {
    height: 57px;
    width: 57px;
    margin: auto;
    position: relative;
    top: 2rem;
    /* top: 37%; */
  }

  background-image: url(${background});

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    background: #ffffff;

    height: 280px;
    width: 360px;
  }
  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    position: relative;
    top: 20%;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #062229;
  }

  button {
    margin: auto;
    display: flex;
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 220px;
    height: 49px;
    background: #1b75ba;
    justify-content: center;
    align-items: center;
    margin-top: 25%;
    color: #ffffff;
  }
  .thankyoulink {
    margin: auto;
  }
`;
