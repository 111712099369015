export const appRoutes = [
  '/',
  '/careerbeam-form',
  '/about-us',
  '/contact-us',
  '/initium',
  '/hp-future-ready-podcast',
  '/thankyou',
  '/ascend',
  '/terms-condition',
  '/cancellation-refund-policy',
  '/privacy-policy',
  '/shipping',
  '/blog',
  '/careerbeam-festival',
  '/event-registration',
  '/verify',
  '/appForm',
  '/event-schedule',
  '/universityList',
  '/university',
  '/festival1',
  '/registration-success'
];

export const homeRoute = ['/'];
