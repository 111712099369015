import { Pagination, Select, message } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useEffect, useState } from 'react';
// import BlogGrid2 from "../components/BlogGrid2";
import BlogGrid3 from '../components/BlogGrid3';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

const BlogMain = () => {
  const [options, setOptions] = useState([]);
  const [blog, setBlog] = useState([]);
  const [blogDetails, setBlogDetails] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigate = useNavigate();

  const handleSelect = () => {
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_USER + 'categories'
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          setOptions(res.Success);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  const handleblogView = () => {
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_USER + 'blog_list'
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          setBlog(res.Success);
          console.log(blog.Title, 'bllll');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  const handleblogViewDetails = (
    ID,
    Slug,
    Title,
    Author,
    Created_On,
    Image
  ) => {
    const postData = {
      id: ID
    };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_USER + 'view_blog',
      data: postData
    })
      .then(async function (response) {
        let res = await response.data.Success;
        navigate(`/${Slug}`, {
          state: {
            htmlCode: res,
            blog: Title,
            Author: Author,
            Created_On: Created_On,
            Image: Image
          }
        });

        setBlogDetails(res);
        handleblogView();

        console.log(blogDetails, 'Blog');
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  useEffect(() => {
    console.log(blogDetails, 'Blog Details');
  }, [blogDetails]);

  useEffect(() => {
    handleSelect();
    handleblogView();
  }, []);

  const handleBlogClick = (ID, Slug, Title, Author, Created_On, Image) => {
    handleblogViewDetails(ID, Slug, Title, Author, Created_On, Image);
  };

  const htmlContent =
    '<p>Your HTML content goes here</p><h2>Another heading</h2><p>More content</p>';
  return (
    <>
      <Helmet>
        <title>Career Insights & Tips | CareerBeam Blog</title>
        <meta
          name="description"
          content="Explore our blog for expert advice, industry trends, and success stories. Stay informed and inspired with CareerBeam."
        />
        <link rel="canonical" href="https://careerbeam.in/blog" />
        <meta name="robots" content="all" />
        <meta
          property="og:title"
          content="Career Insights & Tips | CareerBeam Blog"
        />
        <meta property="og:site_name" content="CareerBeam" />
        <meta property="og:url" content="https://careerbeam.in/blog" />
        <meta
          property="og:description"
          content="Explore our blog for expert advice, industry trends, and success stories. Stay informed and inspired with CareerBeam."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/festivalBanner.86d513e39dc2ca9f8d2a.png"
        />
      </Helmet>

      {/* <MetaTags>
        <meta name="title" content="Career Insights & Tips | CareerBeam Blog" />
        <meta
          name="description"
          content="Explore our blog for expert advice, industry trends, and success stories. Stay informed and inspired with CareerBeam.”"
        />
      </MetaTags> */}
      <div className="bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
        <div className="  mx-auto pt-10">
          <div className="container mx-auto my-auto bg-white rounded-md mb-16 shadow-lg">
            <div className="text-4xl my-10 text-black font-semibold mb-10">
              Blog Posts
            </div>
            <div className="flex gap-4 mb-10 ">
              <Search placeholder="Search" style={{ width: 400 }} />
              <Select
                placeholder="Category"
                style={{ width: 200 }}
                options={options.map((option) => ({
                  value: option,
                  label: option
                }))}
                onChange={(value) => setSelectedCategory(value)}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {blog &&
                blog
                  .filter(
                    (item) =>
                      !selectedCategory ||
                      item.Categories.includes(selectedCategory)
                  )
                  .map((item, index) => (
                    <BlogGrid3
                      key={index}
                      myProp={{
                        key: index,
                        ID: item.ID,
                        Image: item.Image,
                        Created_On: item.Created_On,
                        Title: item.Title,
                        Slug: item.Slug
                      }}
                      onClick={() =>
                        handleBlogClick(
                          item.ID,
                          item.Slug,
                          item.Title,
                          item.Author,
                          item.Created_On,
                          item.Image
                        )
                      }
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogMain;
