import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Radio,
  Checkbox,
  Form,
  Input,
  Alert,
  Grid
} from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const RegistrationForm = ({
  registrationSectionRef,
  selectedScheduleInModal
}) => {
  const navigate = useNavigate();

  const screens = useBreakpoint();

  useEffect(() => {
    setChooseTrigger(selectedScheduleInModal);
  }, [selectedScheduleInModal]);

  const [chooseTrigger, setChooseTrigger] = useState(['4']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const radioToState = (input) => {
    switch (input) {
      case '5':
        return ['5'];
      case 'both':
        return ['4', '5'];
      case '4':
      default:
        return ['4'];
    }
  };

  const stateToRadio = useMemo(() => {
    if (chooseTrigger.includes('5')) {
      return chooseTrigger.includes('4') ? 'both' : '5';
    }
    return '4';
  }, [chooseTrigger]);

  const radioButtonToDate = () => {
    if (chooseTrigger == 4) {
      return '4thMay';
    } else if (chooseTrigger == 5) {
      return '5thMay';
    } else {
      return '4th&5thMay';
    }
  };

  const onFinish = async (values) => {
    const data = {
      ...values,
      selectedDate: chooseTrigger ? radioButtonToDate() : '4thMay'
    };

    setIsSubmitting(true);

    try {
      const response = await axios
        .post('https://backend.careerbeam.in/api/eventUser/create', data)
        .then((res) => {
          setIsSubmitting(false);

          navigate('/registration-success');
        });
    } catch (error) {
      setIsSubmitting(false);

      return (
        <Alert message="Error" description={error} type="error" showIcon />
      );
    }
  };

  return (
    <>
      <RegistrationFormComponent
        ref={registrationSectionRef}
        id="registration"
        style={{ height: screens?.xs ? 410 : 380 }}
      >
        <h1>
          Registration
          <span style={{ color: '#0061AB' }}>
            <strong> Form</strong>
          </span>
        </h1>

        <Row justify="center" style={{ marginTop: screens?.xs ? -26 : 30 }}>
          <Title
            level={screens?.xs ? 5 : 4}
            style={{ color: '#1E1E2F', fontWeight: 400 }}
          >
            Select attending date.
          </Title>
        </Row>

        <Row justify="center">
          <Radio.Group
            onChange={(e) => setChooseTrigger(radioToState(e.target.value))}
            value={stateToRadio}
          >
            <Radio value="4" defaultChecked>
              4th May
            </Radio>
            <Radio value="5">5th May</Radio>
            <Radio value="both">4th & 5th May</Radio>
          </Radio.Group>
        </Row>

        <Row
          justify="center"
          style={{ marginTop: 8, padding: screens?.xs ? 20 : null }}
        >
          <Col sm={{ span: 18 }} xs={{ span: 24 }}>
            <Form
              name="basic"
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={[24, 0]}>
                <Col
                  md={{ span: 8 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: screens?.xs ? -15 : null }}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        message: ''
                      }
                    ]}
                  >
                    <StyledInput
                      placeholder="Enter your name"
                      required
                      style={{ width: '100%', height: 45 }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  md={{ span: 8 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: screens?.xs ? -15 : null }}
                >
                  <Form.Item name="mobileNumber">
                    <StyledInput
                      placeholder="Mobile number"
                      type="number"
                      required
                      style={{ width: '100%', height: 45 }}
                    />
                  </Form.Item>
                </Col>

                <Col
                  md={{ span: 8 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: screens?.xs ? -15 : null }}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: ''
                      }
                    ]}
                  >
                    <StyledInput
                      placeholder="Email Id"
                      style={{ width: '100%', height: 45 }}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                justify={'center'}
                style={{ marginTop: screens?.xs ? -15 : null }}
              >
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      style={{
                        backgroundColor: '#173D7A',
                        color: '#ffffff',
                        width: '100%',
                        height: 44,
                        fontSize: 16
                      }}
                    >
                      {isSubmitting ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                          fullscreen
                        />
                      ) : (
                        'Register'
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </RegistrationFormComponent>
    </>
  );
};

export default RegistrationForm;

const RegistrationFormComponent = styled.div`
  background-color: #f3f8ff;

  @media (max-width: 768px) {
    h1 {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      padding-top: 48px;
      padding-bottom: 40px;
      color: #44546a;
    }
  }

  @media (min-width: 786px) {
    h1 {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      /* identical to box height, or 100% */
      margin: auto;
      text-align: center;
      padding-top: 80px;
      color: #44546a;
    }
  }
`;

const StyledInput = styled(Input)`
  &::placeholder {
    color: #1e1e2f;
    opacity: 50%;
  }
`;
