import React from "react";
import one from "../assets/podcastlogos/p1.png";
import two from "../assets/podcastlogos/p2.png";
import three from "../assets/podcastlogos/p3.png";
import four from "../assets/podcastlogos/p4.png";
import five from "../assets/podcastlogos/p5.png";
import six from "../assets/podcastlogos/p6.png";
import seven from "../assets/podcastlogos/p7.png";
import eight from "../assets/podcastlogos/p8.png";
import nine from "../assets/podcastlogos/p9.png";
import ten from "../assets/podcastlogos/p10.png";
import elevan from "../assets/podcastlogos/p11.png";
import twelve from "../assets/podcastlogos/p12.png";
import thirteen from "../assets/podcastlogos/p13.png";
import fourteen from "../assets/podcastlogos/p14.png";

import { useState, useEffect, useRef } from "react";

import "./LogoSlider.css";

const HandleSlide = () => {
  const logos = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    elevan,
    twelve,
    thirteen,
    fourteen,
  ];

  const [position, setPosition] = useState(0);
  const [transition, setTransition] = useState(false);
  const filmstripRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTransition(true);
      setPosition((position + 1) % logos.length);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [position, logos.length]);

  useEffect(() => {
    if (filmstripRef.current) {
      const handleTransitionEnd = () => {
        setTransition(false);
      };

      filmstripRef.current.addEventListener(
        "transitionend",
        handleTransitionEnd
      );
    }
  }, []);

  const duplicateLogos = [...logos, ...logos, ...logos];

  return (
    <div class="pt-14 pb-8">
      <div class="flex flex-col w-full text-center">
        <span class="former-head">
          Here are the Schools we collaborated with
        </span>
      </div>
      <div className="filmstrip-container pt-10">
        <div
          className="filmstrip"
          ref={filmstripRef}
          style={{
            transform: `translateX(-${position * (100 / logos.length)}%)`,
            transition: transition ? "transform 1s ease-out" : "none",
            width: `${(100 / logos.length) * duplicateLogos.length}%`,
          }}
        >
          {duplicateLogos.map((logo, index) => (
            <img key={index} src={logo} alt={logo} className="logo" />
          ))}
        </div>
      </div>
      <div className="button-section"></div>
      <style jsx>{`
        .filmstrip-container {
          max-width: 100%;
          overflow: hidden;
          position: relative;
        }

        .filmstrip {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;
          transition: transform 1s ease-out;
        }

        .logo {
          display: block;
          margin: 0;
          height: 60px;
          width: auto;
          max-width: 100%;
        }

        .logo.active {
          opacity: 1;
        }

        @media screen and (min-width: 640px) {
          .filmstrip {
            justify-content: center;
          }

          .logo {
            height: 80px;
            margin: 0 30px;
          }
        }

        @media screen and (min-width: 768px) {
          .logo {
            height: 100px;
          }
        }
      `}</style>
    </div>
  );
};

export default HandleSlide;
