import { useState } from "react";
import styles from "./index.module.css";
import { panelData, panelData2, seminarData, seminarData2, workShopData, workShopData2 ,appFormWorkShopData,appFormPanelData,appFormWorkShopData2,appFormPanelData2} from "../../../data";


const List = ({ data, details, date, setDetails }) => {
  const handleCheckboxChange = (eventName) => {
    const currentEvents = details.details.attending_events[date] || [];
    let updatedEvents;

    if (currentEvents.includes(eventName)) {
      // Remove the event from the list if it is already included
      updatedEvents = currentEvents.filter((event) => event !== eventName);
    } else {
      // Add the event to the list if it is not included
      updatedEvents = [...currentEvents, eventName];
    }

    const newAttendingEvents = {
      ...details.details.attending_events,
      [date]: updatedEvents,
    };

    const updatedDetails = {
      ...details,
      details: {
        ...details.details,
        attending_events: newAttendingEvents,
      },
    };

    setDetails(updatedDetails);
  };

  return (
    <>
      {data.map((section, index) => (
        <div key={index}>
          <p
            className="font-bold p-2"
            style={{
              backgroundColor: "rgba(244, 244, 244, 1)",
            }}
          >
            {section.heading}
          </p>
          {section.data.map((item, key) => (
            <div
              key={key}
              className="flex align-center p-4 gap-4"
              style={{
                borderBottom: "1px solid #00000020",
                cursor: "pointer",
              }}
              onClick={() => handleCheckboxChange(item[0])}
            >
              <input
                type="checkbox"
                checked={details.details.attending_events[date]?.includes(
                  item[0]
                )}
              />
              <p>{item[0]}</p>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

const SelectEventOverlay = ({ details, setDetails, setShowList }) => {
  const [data, setData] = useState([appFormWorkShopData, appFormPanelData]);
  const [dateToggle, setDateToggle] = useState("4th May 2024");
  const dateData = [
    { date: "4th May 2024", table: [appFormWorkShopData, appFormPanelData] },
    { date: "5th May 2024", table: [appFormWorkShopData2, appFormPanelData2] },
  ];

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <p className="mb-4">Sessions (Select atleast one)</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1.5rem",
            padding: "1rem",
          }}
        >
          {dateData.map((event) => (
            <button
              key={event.date}
              style={{
                color: event.date === dateToggle ? "#0070C0" : "#000",
              }}
              className={` ${
                event.date === dateToggle
                  ? "text-[#0070C0] underline underline-offset-8"
                  : ""
              }`}
              onClick={() => {
                setDateToggle(event.date);
                setData(event.table);
              }}
            >
              {event.date}
            </button>
          ))}
        </div>

        <div
          className="h-80 md:max-h-80 overflow-scroll border border-black rounded-xl mb-4"
          style={{
            height: "20rem",
            overflow: "scroll",
            border: "1px solid #000000",
            borderRadius: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <List
            data={data}
            date={dateToggle}
            setDetails={setDetails}
            details={details}
          />
        </div>

        <button
          className="bg-blue text-white"
          style={{
            backgroundColor: "#0070C0",
            color: "#fff",
            padding: "0.5rem 1.5rem",
          }}
          onClick={() => {
            setShowList(false);
          }}
        >
          Done
        </button>

        <button
          style={{
            padding: "0.5rem 1.5rem",
            marginLeft: "1rem",
          }}
          onClick={() => {
            setDetails({
              ...details,
              details: {
                ...details.details,
                chooseEvent: "Just visiting",
              },
            });
            setShowList(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SelectEventOverlay
