import cardImg from '../../../assets1/card1.png';
// import cardMobile from "../../../assets1/card-mobile.png";

import styles from './index.module.css';
import { card1 } from '../../../images';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const SideBar = () => {
  const isLargeScreen = window.innerWidth > 770;

  const screens = useBreakpoint();

  const largeImage =
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Event-registration-Banner-Desktop.png';

  const mobileImage =
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/Event-registration-Banner-Mobile.png';

  return (
    <>
      <div className={styles.SideContainer}>
        <div>
          <div
            style={{
              borderRadius: '24px',
              overflow: 'hidden',
              width: '100%'
            }}
          >
            {isLargeScreen ? (
              <div>
                <img
                  src={largeImage}
                  alt="Large"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </div>
            ) : (
              <img
                src={mobileImage}
                alt="Small"
                style={{
                  width: '100%',
                  height: 'auto'
                }}
              />
            )}
          </div>
          <div>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '1.5rem',
                marginBottom: '0.5rem',
                marginTop: '2rem'
              }}
            >
              CareerBeam Festival
            </p>
            <p
              style={{
                fontWeight: '400',
                fontSize: '1rem',
                color: '#5D7290'
              }}
            >
              Join us at a one-of-a-kind festival to discover new-age careers
              beyond Arts, Science & Commerce.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.mobileNav}>
        <img
          src={mobileImage}
          style={{
            width: '100%'
          }}
        />
      </div>
    </>
  );
};

export default SideBar;
