import { useState } from "react";
import { degrees } from "../../../data";
import DropDown from "../../01_atoms/DropDown";
import InputBox from "../../01_atoms/InputBox";
import InputBox2 from "../../01_atoms/InputBox2";
import RadioBtn2 from "../../01_atoms/RadioBtn2";
import Button1 from "../../01_atoms/button1";
import styles from "./index.module.css";
import SelectEventOverlay from "../../02_molecules/SelectEventOverlay";
import InputBorderless from "../../01_atoms/InputBorderless";

const ParentsLogin = ({ details, setDetails, onSubmit }) => {
  const [showList, setShowList] = useState(false);
  const [showYearInput, setShowYearInput] = useState(false);
  const [showCourseInput, setShowCourseInput] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        marginBottom: "5rem",
        padding: "2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <div className={styles.flex}>
          <div className={styles.width50}>
            <InputBox
              text={"Which school/college is your child studying in?"}
              mark
              placeholderText={"School/College name"}
              onChange={(e) =>
                setDetails({
                  ...details,
                  details: {
                    ...details.details,
                    school_name: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.width50}>
            <DropDown
              text={"Will you be attending individually or with your child?"}
              options={["With Child", "Individually"]}
              onSelect={(e) =>
                setDetails({
                  ...details,
                  details: {
                    ...details.details,
                    attending_with: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div
          className={styles.flex}
          style={{
            alignItems: "flex-start",
          }}
        >
          <div className={styles.width50}>
            <DropDown
              text={"What course is your child studying?"}
              options={degrees}
              style={{
                marginBottom: "1rem",
              }}
              onSelect={(e) => {
                if (e.target.value == "Others") {
                  setShowCourseInput(true);
                  return;
                }

                setShowCourseInput(false);
                setDetails({
                  ...details,
                  details: {
                    ...details.details,
                    course_name: e.target.value,
                  },
                });
              }}
            />

            {showCourseInput && (
              <InputBorderless
                placeholderText={"Enter course"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      course_name: e.target.value,
                    },
                  })
                }
              />
            )}
          </div>

          <div className={styles.width50}>
            <DropDown
              style={{
                marginBottom: "1rem",
              }}
              text={"Which year your child is studying in?"}
              options={[
                "Others",
                "10th",
                "11th",
                "12th",
                "First year",
                "Second year",
                "Third year",
                "Fourth year",
              ]}
              onSelect={(e) => {
                if (e.target.value == "Others") {
                  setShowYearInput(true);
                  return;
                }

                setShowYearInput(false);
                setDetails({
                  ...details,
                  details: {
                    ...details.details,
                    year_of_study: e.target.value,
                  },
                });
              }}
            />

            {showYearInput && (
              <InputBorderless
                placeholderText={"Enter year"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    details: {
                      ...details.details,
                      year_of_study: e.target.value,
                    },
                  })
                }
              />
            )}
          </div>
        </div>

        <InputBox
          text={"What stream/course is your child interested in?"}
          mark
          placeholderText={"The workshop topics etc"}
          onChange={(e) =>
            setDetails({
              ...details,
              details: {
                ...details.details,
                stream: e.target.value,
              },
            })
          }
        />

        <RadioBtn2
          text={"Which days would you be attending ASCend Beyond?"}
          mark
          name={"attending_days"}
          options={["4th May", "5th May", "4th & 5th May"]}
          onChange={(e) =>
            setDetails({
              ...details,
              details: {
                ...details.details,
                attending_days: e,
              },
            })
          }
        />

        <RadioBtn2
          mark
          text={"Choose the sessions you wish to attend"}
          options={["Select session", "Just visiting"]}
          name={"chooseEvent"}
          onChange={(e) => {
            if (e === "Select session") {
              setShowList(true);
            }
            setDetails({
              ...details,
              details: {
                ...details.details,
                chooseEvent: e,
                attending_events: {
                  "4th May 2024": [],
                  "5th May 2024": [],
                },
              },
            });
          }}
        />

        <InputBox2
          text={
            "What are the biggest concerns or challenges you have faced when it comes to education? "
          }
          placeholderText={"Confusing choices in career selection.."}
          onChange={(e) =>
            setDetails({
              ...details,
              details: {
                ...details.details,
                biggest_challenge: e.target.value,
              },
            })
          }
        />
        <InputBox2
          text={
            "What topics would you like to see covered in CareerBeam festival?"
          }
          placeholderText={"AI, Military, Coding"}
          onChange={(e) =>
            setDetails({
              ...details,
              details: {
                ...details.details,
                topics_suggested: e.target.value,
              },
            })
          }
        />
        <Button1
          style={{ width: "10rem" }}
          text={"Submit Form"}
          onClick={onSubmit}
        />
      </div>
      {showList && (
        <SelectEventOverlay
          details={details}
          setDetails={setDetails}
          setShowList={setShowList}
        />
      )}
    </div>
  );
};

export default ParentsLogin;
