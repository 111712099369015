import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import csm from '../assets/csm.png';
import { useNavigate } from 'react-router-dom';
import GoogleTagManager from '../components/GoogleTagManeger';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

import { cs } from '../images';

const ContactusScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(null);
  const history = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.checkValidity()) {
      // Save user information in local storage
      localStorage.setItem('user', JSON.stringify({ name, email, phone }));

      axios
        .post('https://careerbeam-backend.onrender.com/api/recieve', {
          name: name,
          email: email,
          phone: phone
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(name, email, phone);
      // Redirect to another page
      history('/thankyou');
    } else {
      alert('Please fill all the fields correctly');
    }
  };

  return (
    <Div>
      <GoogleTagManager />
      <Helmet>
        <title>Contact CareerBeam: Reach Out</title>
        {/* <meta name="title" content="About CareerBeam: Our Story" /> */}
        <meta
          name="description"
          content="Have questions? Contact CareerBeam for assistance, feedback, or inquiries. We’re here to help!"
        />
        <link rel="canonical" href="https://careerbeam.in/contact-us" />
        <meta name="robots" content="all" />
        <meta property="og:title" content="Contact CareerBeam: Reach Out" />
        <meta property="og:site_name" content="CareerBeam" />
        <meta property="og:url" content="https://careerbeam.in/contact-us" />
        <meta
          property="og:description"
          content="Have questions? Contact CareerBeam for assistance, feedback, or inquiries. We’re here to help!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/mobileb3.9e67f566426f9a90a2cc.png"
        />
      </Helmet>

      {/* <MetaTags>
        <meta
          name="title"
          content="Contact CareerBeam: Reach Out"
        />
        <meta
          name="description"
          content="Have questions? Contact CareerBeam for assistance, feedback, or inquiries. We’re here to help!"
        />
      </MetaTags> */}
      <Heading>Contact Us</Heading>
      <img src={cs} alt="" className="w-full img" />
      <ParentDiv>
        <Childone>
          <H1>Contact Details</H1>
          <ContactDetails>
            <ul>
              <li>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.19121 19.1878C7.19143 19.1879 7.19159 19.188 7.5 18.75L7.19121 19.1878ZM7.80879 19.1878L7.81071 19.1864L7.81559 19.183L7.83262 19.1708C7.84725 19.1603 7.8683 19.145 7.8953 19.1252C7.9493 19.0855 8.02725 19.0274 8.12577 18.9517C8.3227 18.8003 8.60202 18.5783 8.9363 18.2924C9.60413 17.7214 10.4951 16.8926 11.3874 15.8603C13.1592 13.8107 15 10.8854 15 7.5412C15 5.54218 14.2106 3.62438 12.8044 2.20988C11.3981 0.795289 9.49012 0 7.5 0C5.50987 0 3.60187 0.795289 2.19557 2.20988C0.789429 3.62438 0 5.54218 0 7.5412C0 10.8854 1.84077 13.8107 3.61259 15.8603C4.50493 16.8926 5.39587 17.7214 6.0637 18.2924C6.39798 18.5783 6.6773 18.8003 6.87423 18.9517C6.97275 19.0274 7.0507 19.0855 7.1047 19.1252C7.1317 19.145 7.15275 19.1603 7.16738 19.1708L7.18441 19.183L7.18929 19.1864L7.19121 19.1878C7.3762 19.318 7.6238 19.318 7.80879 19.1878ZM7.5 18.75L7.80879 19.1878C7.80857 19.1879 7.80841 19.188 7.5 18.75ZM10.1786 7.5C10.1786 8.97932 8.97932 10.1786 7.5 10.1786C6.02068 10.1786 4.82143 8.97932 4.82143 7.5C4.82143 6.02068 6.02068 4.82143 7.5 4.82143C8.97932 4.82143 10.1786 6.02068 10.1786 7.5Z"
                    fill="#4A66AC"
                  />
                </svg>
                <a href="https://goo.gl/maps/s2xhbbDdCvdfvHwL8">
                  Suit 3, 4th Floor, Samruddhi Venture Park, MIDC Central Rd,
                  Andheri East, Mumbai - 400093
                </a>
              </li>
              <li>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.9">
                    <path
                      d="M3.16667 0C1.41776 0 0 1.41776 0 3.16667V3.48539L9.50016 8.60086L19 3.48557V3.16667C19 1.41776 17.5823 0 15.8333 0H3.16667Z"
                      fill="#4A66AC"
                    />
                    <path
                      d="M19 5.28373L9.87549 10.1969C9.64117 10.3231 9.35915 10.3231 9.12483 10.1969L0 5.28357V12.6666C0 14.4155 1.41776 15.8332 3.16667 15.8332H15.8333C17.5823 15.8332 19 14.4155 19 12.6666V5.28373Z"
                      fill="#4A66AC"
                    />
                  </g>
                </svg>
                <a href="mailto:enquiry@vigorlaunchpad.com">
                  enquiry@vigorlaunchpad.com
                </a>
              </li>
              <li>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M18.4582 13.4261L14.3018 11.6448C14.1243 11.5691 13.9269 11.5532 13.7395 11.5994C13.5521 11.6455 13.3848 11.7513 13.2628 11.9008L11.4221 14.1497C8.53335 12.7877 6.20858 10.4629 4.84657 7.57419L7.09544 5.73353C7.24524 5.61169 7.35127 5.44436 7.39747 5.25687C7.44368 5.06938 7.42754 4.87195 7.3515 4.69445L5.57021 0.53813C5.48676 0.346794 5.33916 0.190574 5.15286 0.0964088C4.96656 0.00224336 4.75324 -0.0239658 4.54969 0.0223006L0.690246 0.912941C0.493997 0.958259 0.318903 1.06876 0.193542 1.2264C0.0681805 1.38405 -4.52078e-05 1.57953 2.24746e-08 1.78094C2.24746e-08 11.2997 7.71517 19 17.219 19C17.4205 19.0001 17.6161 18.9319 17.7738 18.8066C17.9315 18.6812 18.0421 18.5061 18.0874 18.3097L18.9781 14.4503C19.024 14.2458 18.9973 14.0316 18.9024 13.8446C18.8075 13.6577 18.6504 13.5097 18.4582 13.4261V13.4261Z"
                    fill="#4A66AC"
                  />
                </svg>
                <a href="tel:022 4978 2431">+91 7208070768 | 022 4978 2431</a>
              </li>
            </ul>
          </ContactDetails>
          <SocialHandle>
            <a href="https://www.facebook.com/careerbeam/">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0025 5.35948C7.88098 5.35948 5.36319 7.87783 5.36319 11C5.36319 14.1222 7.88098 16.6405 11.0025 16.6405C14.1239 16.6405 16.6417 14.1222 16.6417 11C16.6417 7.87783 14.1239 5.35948 11.0025 5.35948ZM11.0025 14.6671C8.98528 14.6671 7.3362 13.0225 7.3362 11C7.3362 8.97746 8.98037 7.33292 11.0025 7.33292C13.0245 7.33292 14.6687 8.97746 14.6687 11C14.6687 13.0225 13.0196 14.6671 11.0025 14.6671ZM18.1877 5.12875C18.1877 5.8602 17.5988 6.44438 16.8724 6.44438C16.1411 6.44438 15.5571 5.85529 15.5571 5.12875C15.5571 4.40221 16.146 3.81312 16.8724 3.81312C17.5988 3.81312 18.1877 4.40221 18.1877 5.12875ZM21.9227 6.46402C21.8393 4.70166 21.4368 3.14058 20.146 1.8544C18.8601 0.568225 17.2994 0.165681 15.5374 0.0773179C13.7215 -0.0257726 8.27853 -0.0257726 6.46258 0.0773179C4.70552 0.160772 3.14479 0.563316 1.85399 1.84949C0.56319 3.13567 0.165644 4.69675 0.0773006 6.45911C-0.0257669 8.27547 -0.0257669 13.7196 0.0773006 15.536C0.160736 17.2983 0.56319 18.8594 1.85399 20.1456C3.14479 21.4318 4.70061 21.8343 6.46258 21.9227C8.27853 22.0258 13.7215 22.0258 15.5374 21.9227C17.2994 21.8392 18.8601 21.4367 20.146 20.1456C21.4319 18.8594 21.8344 17.2983 21.9227 15.536C22.0258 13.7196 22.0258 8.28037 21.9227 6.46402ZM19.5767 17.4849C19.1939 18.4471 18.4528 19.1883 17.4859 19.5761C16.038 20.1505 12.6025 20.018 11.0025 20.018C9.40245 20.018 5.96196 20.1456 4.51902 19.5761C3.55705 19.1932 2.81595 18.452 2.42822 17.4849C1.85399 16.0367 1.9865 12.6004 1.9865 11C1.9865 9.39964 1.8589 5.95838 2.42822 4.51512C2.81104 3.55294 3.55215 2.81167 4.51902 2.42385C5.96687 1.84949 9.40245 1.98204 11.0025 1.98204C12.6025 1.98204 16.0429 1.8544 17.4859 2.42385C18.4479 2.80676 19.189 3.54803 19.5767 4.51512C20.1509 5.96329 20.0184 9.39964 20.0184 11C20.0184 12.6004 20.1509 16.0416 19.5767 17.4849Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/careerbeam/">
              <svg
                width="13"
                height="22"
                viewBox="0 0 13 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.375 0.618135C12.375 0.456111 12.3157 0.300724 12.2102 0.186156C12.1048 0.0715881 11.9617 0.0072244 11.8125 0.0072244H9C7.58376 -0.0693949 6.19717 0.465885 5.14319 1.49611C4.0892 2.52634 3.45351 3.96775 3.375 5.50542V8.80433H0.5625C0.413316 8.80433 0.270242 8.8687 0.164753 8.98327C0.0592633 9.09783 0 9.25322 0 9.41524V12.592C0 12.754 0.0592633 12.9094 0.164753 13.024C0.270242 13.1385 0.413316 13.2029 0.5625 13.2029H3.375V21.3891C3.375 21.5511 3.43426 21.7065 3.53975 21.8211C3.64524 21.9356 3.78832 22 3.9375 22H7.3125C7.46168 22 7.60476 21.9356 7.71025 21.8211C7.81574 21.7065 7.875 21.5511 7.875 21.3891V13.2029H10.8225C10.9476 13.2048 11.0697 13.1614 11.1695 13.0796C11.2694 12.9977 11.3412 12.882 11.3738 12.7508L12.1838 9.57408C12.2061 9.48382 12.2091 9.38918 12.1925 9.29745C12.1759 9.20572 12.1402 9.11936 12.088 9.045C12.0359 8.97064 11.9687 8.91028 11.8917 8.86855C11.8146 8.82683 11.7298 8.80486 11.6438 8.80433H7.875V5.50542C7.90298 5.20299 8.03375 4.92275 8.24178 4.71941C8.4498 4.51608 8.72014 4.40426 9 4.40578H11.8125C11.9617 4.40578 12.1048 4.34142 12.2102 4.22685C12.3157 4.11228 12.375 3.95689 12.375 3.79487V0.618135Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
            <a href="https://twitter.com/careerbeamindia">
              <svg
                width="25"
                height="20"
                viewBox="0 0 25 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.3293 2.34627C23.4465 2.73694 22.5015 3.00571 21.5055 3.1276C22.5218 2.51208 23.2988 1.53844 23.6693 0.378553C22.716 0.938811 21.6615 1.34462 20.5403 1.57402C19.6462 0.607956 18.3683 0 16.95 0C14.2313 0 12.0278 2.22362 12.0278 4.96434C12.0278 5.35879 12.075 5.73734 12.1567 6.09621C8.067 5.90315 4.4415 3.91953 2.01675 0.922911C1.59075 1.65276 1.3485 2.50072 1.3485 3.42363C1.3485 5.15059 2.21925 6.6648 3.5355 7.55515C2.73075 7.53168 1.97325 7.30682 1.3095 6.9366V6.99641C1.3095 9.40476 3.0045 11.4126 5.2545 11.8699C4.84425 11.9797 4.40625 12.0433 3.9615 12.0433C3.64575 12.0433 3.34425 12.0122 3.0435 11.9562C3.6765 13.9277 5.4885 15.3632 7.6485 15.4063C5.96925 16.7351 3.84075 17.527 1.54725 17.527C1.1565 17.527 0.7665 17.5081 0.375 17.4604C2.56275 18.8686 5.145 19.6923 7.93425 19.6923C16.9845 19.6923 21.93 12.125 21.93 5.57154C21.93 5.36258 21.93 5.14983 21.9143 4.93633C22.8795 4.24206 23.715 3.3623 24.375 2.3652L24.3293 2.34627Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
          </SocialHandle>
        </Childone>
        <Childtwo>
          <H1>Get in Touch</H1>
          <P>
            We will get back to you in 24-48 hrs and will help you with your
            query.
          </P>

          {/* <Form> */}
          <form onSubmit={handleSubmit} className="form">
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Full Name"
              required
            />
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email Id"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              type="phone"
              placeholder="Mobile Number"
              required
              // pattern="\d{10} "
            />
            <button className="flex">SUBMIT NOW</button>
          </form>
          {/* </Form> */}
        </Childtwo>
      </ParentDiv>

      <img src={csm} alt="" className="mobile-img" />
      <ParentMobile>
        <MobileHead>Contact Us</MobileHead>

        <MobileChildOne>
          <h1>Get in Touch</h1>
          <p>
            We will get back to you in 24-48 hrs and will help you with your
            query.
          </p>

          <MobileForm onSubmit={handleSubmit}>
            <input
              onChange={(e) => setName(e.target.value)}
              type="name"
              placeholder="Full Name"
              required
            />
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email Id"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              type="mobile"
              placeholder="Mobile Number"
              required
              pattern="\d{10}"
            />
            <button className="flex">SUBMIT NOW</button>
          </MobileForm>
        </MobileChildOne>

        <MobileChildTwo>
          <h1>Contact Details</h1>
          <ContactMobile>
            <ul>
              <li>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.19121 19.1878C7.19143 19.1879 7.19159 19.188 7.5 18.75L7.19121 19.1878ZM7.80879 19.1878L7.81071 19.1864L7.81559 19.183L7.83262 19.1708C7.84725 19.1603 7.8683 19.145 7.8953 19.1252C7.9493 19.0855 8.02725 19.0274 8.12577 18.9517C8.3227 18.8003 8.60202 18.5783 8.9363 18.2924C9.60413 17.7214 10.4951 16.8926 11.3874 15.8603C13.1592 13.8107 15 10.8854 15 7.5412C15 5.54218 14.2106 3.62438 12.8044 2.20988C11.3981 0.795289 9.49012 0 7.5 0C5.50987 0 3.60187 0.795289 2.19557 2.20988C0.789429 3.62438 0 5.54218 0 7.5412C0 10.8854 1.84077 13.8107 3.61259 15.8603C4.50493 16.8926 5.39587 17.7214 6.0637 18.2924C6.39798 18.5783 6.6773 18.8003 6.87423 18.9517C6.97275 19.0274 7.0507 19.0855 7.1047 19.1252C7.1317 19.145 7.15275 19.1603 7.16738 19.1708L7.18441 19.183L7.18929 19.1864L7.19121 19.1878C7.3762 19.318 7.6238 19.318 7.80879 19.1878ZM7.5 18.75L7.80879 19.1878C7.80857 19.1879 7.80841 19.188 7.5 18.75ZM10.1786 7.5C10.1786 8.97932 8.97932 10.1786 7.5 10.1786C6.02068 10.1786 4.82143 8.97932 4.82143 7.5C4.82143 6.02068 6.02068 4.82143 7.5 4.82143C8.97932 4.82143 10.1786 6.02068 10.1786 7.5Z"
                    fill="#4A66AC"
                  />
                </svg>
                <a href="https://goo.gl/maps/s2xhbbDdCvdfvHwL8">
                  Suit 3, 4th Floor, Samruddhi Venture Park, MIDC Central Rd,
                  Andheri East, Mumbai - 400093
                </a>
              </li>
              <li>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.9">
                    <path
                      d="M3.16667 0C1.41776 0 0 1.41776 0 3.16667V3.48539L9.50016 8.60086L19 3.48557V3.16667C19 1.41776 17.5823 0 15.8333 0H3.16667Z"
                      fill="#4A66AC"
                    />
                    <path
                      d="M19 5.28373L9.87549 10.1969C9.64117 10.3231 9.35915 10.3231 9.12483 10.1969L0 5.28357V12.6666C0 14.4155 1.41776 15.8332 3.16667 15.8332H15.8333C17.5823 15.8332 19 14.4155 19 12.6666V5.28373Z"
                      fill="#4A66AC"
                    />
                  </g>
                </svg>
                <a href="mailto:enquiry@vigorlaunchpad.com">
                  enquiry@vigorlaunchpad.com
                </a>
              </li>
              <li>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M18.4582 13.4261L14.3018 11.6448C14.1243 11.5691 13.9269 11.5532 13.7395 11.5994C13.5521 11.6455 13.3848 11.7513 13.2628 11.9008L11.4221 14.1497C8.53335 12.7877 6.20858 10.4629 4.84657 7.57419L7.09544 5.73353C7.24524 5.61169 7.35127 5.44436 7.39747 5.25687C7.44368 5.06938 7.42754 4.87195 7.3515 4.69445L5.57021 0.53813C5.48676 0.346794 5.33916 0.190574 5.15286 0.0964088C4.96656 0.00224336 4.75324 -0.0239658 4.54969 0.0223006L0.690246 0.912941C0.493997 0.958259 0.318903 1.06876 0.193542 1.2264C0.0681805 1.38405 -4.52078e-05 1.57953 2.24746e-08 1.78094C2.24746e-08 11.2997 7.71517 19 17.219 19C17.4205 19.0001 17.6161 18.9319 17.7738 18.8066C17.9315 18.6812 18.0421 18.5061 18.0874 18.3097L18.9781 14.4503C19.024 14.2458 18.9973 14.0316 18.9024 13.8446C18.8075 13.6577 18.6504 13.5097 18.4582 13.4261V13.4261Z"
                    fill="#4A66AC"
                  />
                </svg>
                <a href="tel:022 4978 2431">+91 7208070768 | 022 4978 2431</a>
              </li>
            </ul>
          </ContactMobile>
          <SocialHandle>
            <a href="https://www.facebook.com/careerbeam/">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0025 5.35948C7.88098 5.35948 5.36319 7.87783 5.36319 11C5.36319 14.1222 7.88098 16.6405 11.0025 16.6405C14.1239 16.6405 16.6417 14.1222 16.6417 11C16.6417 7.87783 14.1239 5.35948 11.0025 5.35948ZM11.0025 14.6671C8.98528 14.6671 7.3362 13.0225 7.3362 11C7.3362 8.97746 8.98037 7.33292 11.0025 7.33292C13.0245 7.33292 14.6687 8.97746 14.6687 11C14.6687 13.0225 13.0196 14.6671 11.0025 14.6671ZM18.1877 5.12875C18.1877 5.8602 17.5988 6.44438 16.8724 6.44438C16.1411 6.44438 15.5571 5.85529 15.5571 5.12875C15.5571 4.40221 16.146 3.81312 16.8724 3.81312C17.5988 3.81312 18.1877 4.40221 18.1877 5.12875ZM21.9227 6.46402C21.8393 4.70166 21.4368 3.14058 20.146 1.8544C18.8601 0.568225 17.2994 0.165681 15.5374 0.0773179C13.7215 -0.0257726 8.27853 -0.0257726 6.46258 0.0773179C4.70552 0.160772 3.14479 0.563316 1.85399 1.84949C0.56319 3.13567 0.165644 4.69675 0.0773006 6.45911C-0.0257669 8.27547 -0.0257669 13.7196 0.0773006 15.536C0.160736 17.2983 0.56319 18.8594 1.85399 20.1456C3.14479 21.4318 4.70061 21.8343 6.46258 21.9227C8.27853 22.0258 13.7215 22.0258 15.5374 21.9227C17.2994 21.8392 18.8601 21.4367 20.146 20.1456C21.4319 18.8594 21.8344 17.2983 21.9227 15.536C22.0258 13.7196 22.0258 8.28037 21.9227 6.46402ZM19.5767 17.4849C19.1939 18.4471 18.4528 19.1883 17.4859 19.5761C16.038 20.1505 12.6025 20.018 11.0025 20.018C9.40245 20.018 5.96196 20.1456 4.51902 19.5761C3.55705 19.1932 2.81595 18.452 2.42822 17.4849C1.85399 16.0367 1.9865 12.6004 1.9865 11C1.9865 9.39964 1.8589 5.95838 2.42822 4.51512C2.81104 3.55294 3.55215 2.81167 4.51902 2.42385C5.96687 1.84949 9.40245 1.98204 11.0025 1.98204C12.6025 1.98204 16.0429 1.8544 17.4859 2.42385C18.4479 2.80676 19.189 3.54803 19.5767 4.51512C20.1509 5.96329 20.0184 9.39964 20.0184 11C20.0184 12.6004 20.1509 16.0416 19.5767 17.4849Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/careerbeam/">
              <svg
                width="13"
                height="22"
                viewBox="0 0 13 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.375 0.618135C12.375 0.456111 12.3157 0.300724 12.2102 0.186156C12.1048 0.0715881 11.9617 0.0072244 11.8125 0.0072244H9C7.58376 -0.0693949 6.19717 0.465885 5.14319 1.49611C4.0892 2.52634 3.45351 3.96775 3.375 5.50542V8.80433H0.5625C0.413316 8.80433 0.270242 8.8687 0.164753 8.98327C0.0592633 9.09783 0 9.25322 0 9.41524V12.592C0 12.754 0.0592633 12.9094 0.164753 13.024C0.270242 13.1385 0.413316 13.2029 0.5625 13.2029H3.375V21.3891C3.375 21.5511 3.43426 21.7065 3.53975 21.8211C3.64524 21.9356 3.78832 22 3.9375 22H7.3125C7.46168 22 7.60476 21.9356 7.71025 21.8211C7.81574 21.7065 7.875 21.5511 7.875 21.3891V13.2029H10.8225C10.9476 13.2048 11.0697 13.1614 11.1695 13.0796C11.2694 12.9977 11.3412 12.882 11.3738 12.7508L12.1838 9.57408C12.2061 9.48382 12.2091 9.38918 12.1925 9.29745C12.1759 9.20572 12.1402 9.11936 12.088 9.045C12.0359 8.97064 11.9687 8.91028 11.8917 8.86855C11.8146 8.82683 11.7298 8.80486 11.6438 8.80433H7.875V5.50542C7.90298 5.20299 8.03375 4.92275 8.24178 4.71941C8.4498 4.51608 8.72014 4.40426 9 4.40578H11.8125C11.9617 4.40578 12.1048 4.34142 12.2102 4.22685C12.3157 4.11228 12.375 3.95689 12.375 3.79487V0.618135Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
            <a href="https://twitter.com/careerbeamindia">
              <svg
                width="25"
                height="20"
                viewBox="0 0 25 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.3293 2.34627C23.4465 2.73694 22.5015 3.00571 21.5055 3.1276C22.5218 2.51208 23.2988 1.53844 23.6693 0.378553C22.716 0.938811 21.6615 1.34462 20.5403 1.57402C19.6462 0.607956 18.3683 0 16.95 0C14.2313 0 12.0278 2.22362 12.0278 4.96434C12.0278 5.35879 12.075 5.73734 12.1567 6.09621C8.067 5.90315 4.4415 3.91953 2.01675 0.922911C1.59075 1.65276 1.3485 2.50072 1.3485 3.42363C1.3485 5.15059 2.21925 6.6648 3.5355 7.55515C2.73075 7.53168 1.97325 7.30682 1.3095 6.9366V6.99641C1.3095 9.40476 3.0045 11.4126 5.2545 11.8699C4.84425 11.9797 4.40625 12.0433 3.9615 12.0433C3.64575 12.0433 3.34425 12.0122 3.0435 11.9562C3.6765 13.9277 5.4885 15.3632 7.6485 15.4063C5.96925 16.7351 3.84075 17.527 1.54725 17.527C1.1565 17.527 0.7665 17.5081 0.375 17.4604C2.56275 18.8686 5.145 19.6923 7.93425 19.6923C16.9845 19.6923 21.93 12.125 21.93 5.57154C21.93 5.36258 21.93 5.14983 21.9143 4.93633C22.8795 4.24206 23.715 3.3623 24.375 2.3652L24.3293 2.34627Z"
                  fill="#C4CDE4"
                />
              </svg>
            </a>
          </SocialHandle>
        </MobileChildTwo>
      </ParentMobile>
    </Div>
  );
};

export default ContactusScreen;

const SocialHandle = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 2.3rem;

  @media (min-width: 786px) {
    position: relative;
    top: 9rem;
    right: 2rem;
  }
`;

const Div = styled.div`
  background-color: #f3f6fb;
`;

const MobileHead = styled.span`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  overflow: hidden;
  position: relative;
  bottom: 3rem;
  margin-left: 2rem;
  text-transform: capitalize;
  color: #ffffff;
`;

const Heading = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  position: absolute;
  text-transform: capitalize;
  top: 14rem;
  left: 16rem;
  color: #ffffff;

  @media (max-width: 786px) {
    display: none;
  }
`;

const ParentDiv = styled.div`
  @media (max-width: 786px) {
    display: none;

    .img {
      display: none;
    }
  }

  display: flex;
  justify-content: center;
  position: relative;
  bottom: 14rem;
`;

const ParentMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 6rem;
  img {
    width: 100%;
    /* padding-top: 5rem; */
  }
`;

const MobileChildOne = styled.div`
  width: 343px;
  height: 441px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 40px;
    color: #062229;
  }
  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    margin: auto;
    width: 303px;
    height: 46px;
    margin-top: 16px;
    text-align: center;

    color: #062229;
  }
`;

const MobileForm = styled.form`
  margin-top: 24px;

  input {
    background: #f9f9fa;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 303px;
    height: 45px;
    display: flex;
    margin: auto;
    margin-top: 16px;
    text-indent: 14px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #062229;
  }

  button {
    width: 303px;
    height: 49px;
    left: 36px;
    top: 598px;
    display: flex;
    margin: auto;
    justify-content: center;
    background: #1b75ba;
    border-radius: 6px;
    margin-top: 32px;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

const Childone = styled.div`
  height: 561px;
  width: 448px;
  background: #e6f1f9;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
`;

const Childtwo = styled.div`
  height: 561px;
  width: 448px;
  box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.1);

  background: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  input {
    width: 320px;
    height: 51px;
    left: 704px;
    top: 563px;
    display: flex;
    margin: auto;
    background: #f9f9fa;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-top: 32px;
    text-indent: 16px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #062229;
    line-height: 19px;
  }

  button {
    width: 320px;
    padding: 1rem;
    left: 704px;
    top: 804px;
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 40px;
    background: #1b75ba;
    border-radius: 6px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;

const H1 = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-top: 64px;
  text-align: center;
  color: #062229;
`;

const P = styled.p`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  width: 320px;
  margin: auto;
  color: #062229;
  margin-top: 24px;
`;

const Form = styled.form``;

const MobileChildTwo = styled.div`
  width: 343px;
  height: 369px;
  background: #e6f1f9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  a {
    display: flex;
  }
  h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    position: relative;
    /* bottom: 1rem; */
    color: #062229;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  justify-content: center;
  width: 291px;
  margin: auto;
  height: 78px;

  a {
    display: flex;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    color: rgba(6, 34, 41, 0.9);
  }

  svg {
    display: inline;
    position: relative;
    right: 2.5rem;
    top: 1.5rem;
  }
`;

const ContactMobile = styled.div`
  a {
    display: flex;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    /* or 162% */
    width: 278px;
    margin: auto;
    margin-right: 20px;
    margin-top: 10px;
    /* height: 49px; */
    align-items: center;
    color: rgba(6, 34, 41, 0.9);
  }

  svg {
    display: inline;
    position: relative;
    left: 1.4rem;
    /* right: 2.5rem; */
    top: 2rem;
  }
`;
