import React from "react";
import styled from "styled-components";

import podcast from "../assets/podcastupper.png";
import podcastpic from "../assets/podcastpic.png";
import hpDesktop from "../assets/hd-desktop.png";
import podcastmobilebanner from "../assets/podcastmobilebanner.png";

const PodcastFirstSection = () => {
  return (
    <>
      <Div>
        <Img src={podcast} alt="" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6rem",
            paddingBottom: "6rem",
            marginLeft: "6rem",
            justifyContent: "center",
            backgroundColor: "#F3F6FB;",
          }}
        >
          <div style={{ flex: 1, marginLeft: "6rem" }}>
            <H2>About HP Future Ready Podcast</H2>

            <P>
              HP Future Ready Podcast aims at building awareness about the
              future of classrooms by introducing the ingenious and inventive HP
              & Microsoft systems in schools. The main objective of this
              campaign is to support the academic community by unveiling HP’s
              new PCs and educating them about the latest tech-led and
              future-proof solutions from Microsoft.
            </P>
          </div>
          <div style={{ flex: 1, marginRight: "7rem" }}>
            <img
              src={hpDesktop}
              alt="Info Image"
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "flex",
                margin: "auto",
              }}
            />
          </div>
        </div>
      </Div>

      <MobileDiv>
        <div>
          <div className="div">
            <ImgMobile src={podcastmobilebanner} />
            <h2 className="h2">About HP Future Ready Podcast</h2>

            <img
              className="mobile-banner m-auto"
              src={podcastpic}
              alt="Info Image"
            />
            <p className="mobile-para">
              HP Future Ready Podcast aims at building awareness about the
              future of classrooms by introducing the ingenious and inventive HP
              & Microsoft systems in schools. The main objective of this
              campaign is to support the academic community by unveiling HP’s
              new PCs and educating them about the latest tech-led and
              future-proof solutions from Microsoft.
            </p>
            <br />
            <p className="mobile-para">
              This event will help students find out where to study abroad and
              which course is the best match for their ambitions. Learn all you
              need to know about student life at the world’s top universities.
            </p>
          </div>
        </div>
      </MobileDiv>
    </>
  );
};

export default PodcastFirstSection;

const Img = styled.img`
  /* height: auto; */
  position: relative;
  bottom: 0.9rem;
  width: 100%;
  @media (max-width: 768px) {
    padding-top: 5.2rem;
  }
`;

const ImgMobile = styled.img`
  margin-top: 4rem;
`;

const H2 = styled.h2`
  color: #062229;
  font-family: "Manrope", Sans-serif;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 40px;
`;

const P = styled.p`
  text-align: left;
  color: #062229;
  width: 80%;
  font-family: "Rubik", Sans-serif;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 786px) {
    text-align: center;
  }
`;

const Div = styled.div`
  @media (max-width: 786px) {
    display: none;
  }
`;

const MobileDiv = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  @media (max-width: 786px) {
    .div {
      display: flex;
      flex-direction: column;
      margin: auto;
      /* justify-content: center; */
    }
    .h2 {
      display: flex;
      justify-content: center;
      color: #062229;
      font-family: "Manrope", Sans-serif;
      font-size: 24px;
      line-height: 32px;
      margin: auto;
      font-weight: 500;
      width: 259px;
      margin-top: 40px;
      text-align: center;
    }
    .mobile-para {
      /* text-align: left; */
      text-align: center;
      display: flex;
      margin: auto;
      justify-content: center;
      color: #062229;
      width: 300px;
      margin: 5px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      /* or 164% */

      text-align: center;

      color: #062229;
      margin: auto;
    }
    .mobile-banner {
      width: 295px;
      margin-bottom: 2rem;
      margin-top: 32px;
    }
  }
`;
