import React from "react";
import styled from "styled-components";
import one from "../careerbeamLogos/1.png";
import two from "../careerbeamLogos/2.png";
import three from "../careerbeamLogos/3.png";
import four from "../careerbeamLogos/4.png";
import five from "../careerbeamLogos/5.png";
import six from "../careerbeamLogos/6.png";
import seven from "../careerbeamLogos/7.png";
import eight from "../careerbeamLogos/8.png";

const CareerDomain2 = () => {
  return (
    <>
      <DomainComponent>
        <h1>Facilities</h1>
        <div className="grid-container">
          <div className="grid-item">
            <img src={one} alt="" />
            <span>Law & Finance</span>
          </div>
          <div className="grid-item">
            <img src={two} alt="" />
            <span>Engineering</span>
          </div>
          <div className="grid-item">
            <img src={three} alt="" />
            <p className="two-liners">Computer Applications</p>
          </div>
          <div className="grid-item">
            <img src={four} alt="" />
            <span>Sports</span>
          </div>
          <div className="grid-item">
            <img src={five} alt="" />
            <p className="two-liners">Events & Management</p>
          </div>
          <div className="grid-item">
            <img src={six} alt="" />
            <span>Paramedical</span>
          </div>
          <div className="grid-item">
            <img src={seven} alt="" />
            <span>Hospitality</span>
          </div>
          <div className="grid-item">
            <img src={eight} alt="" />
            <span>Filmmaking</span>
          </div>
        </div>
      </DomainComponent>

      <DomainComponentMobile>
        <h1>Explore Your Career Options</h1>
        <div className="flex-container">
          <div className="flex-item">
            <img className="mobile-image" src={one} alt="" />
            <span>Law & Finance</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={two} alt="" />
            <span>Engineering</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={three} alt="" />
            <span>Computer Applications</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={four} alt="" />
            <span>Sports</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={five} alt="" />
            <span>Events & Management</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={six} alt="" />
            <span>Paramedical</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={seven} alt="" />
            <span>Hospitality</span>
          </div>
          <div className="flex-item">
            <img className="mobile-image" src={eight} alt="" />
            <span>Filmmaking</span>
          </div>
        </div>
      </DomainComponentMobile>
    </>
  );
};

export default CareerDomain2;

const DomainComponentMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  .mobile-image {
    height: 60%;
    position: relative;
    top: 10px;
    left: 10px;
    /* width: 15%; */
  }

  justify-content: center;
  background: #a8daff;

  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    width: 100%;
    line-height: 33px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 48px;
    /* identical to box height */
    text-align: center;
    color: #062229;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 16px;
  }

  .flex-item {
    margin: auto;
    /* gap: 20px; */
    background: #ffffff;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 267px;
    height: 60px;
    /* margin-top: 16px; */
    display: flex;
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #062229;
      display: flex;
      align-items: center;
      text-indent: 1.5rem;
      justify-content: center;
    }
  }
`;

const DomainComponent = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  margin : 3rem 0;

//   height: fit-content;

  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    /* identical to box height, or 100% */
    // position: relative;
    // top: 88px;
    text-align: center;
    color: #062229;
    margin-bottom : 2rem;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    // width: 75%;
    position: relative;
    // top: 10rem;
    margin: auto;
  }

  .grid-item {
    width: 249px;
    height: 76px;
    background: #ffffff;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    img {
      width: 54px;
      height: 54px;
      display: flex;
      margin-top: 7px;
      margin-left: 5px;
      vertical-align: center;
    }
    span {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 2.2rem;
      margin-left: 70px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */
      width: 131px;
      height: 50px;
      color: #062229;
    }
    p {
      position: relative;
      bottom: 3.2rem;
      display: flex;
      justify-content: center;
      position: relative;
      margin-left: 80px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */
      width: 131px;
      height: 50px;
      color: #062229;
    }
  }
`;
