import { useState, useEffect } from 'react';
import styled from 'styled-components';
import mobileImage1 from '../assets/mobileb1.png';
import mobileImage2 from '../assets/mobileFestival.png';
import mobileImage3 from '../assets/mobileb3.png';
import { Link } from 'react-router-dom';

const ImageSliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-top: 2rem;

  @media (max-width: 768px) {
    padding-top: 0;
  }

  .image-slide {
    overflow: hidden;
    /* z-index: -20; */
  }
`;

const DotsContainerDesktop = styled.div`
  position: absolute;
  top: 50%;
  right: 40px;
  padding: 1rem;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  z-index: 10;
`;

const DotsContainerMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  position: absolute;
  top: 35%;
  right: 20px;
  padding: 1rem;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  z-index: 30;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#1b75ba' : 'gray')};
`;

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 8000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  const [isAnimating, setIsAnimating] = useState(false);

  const toggleAnimation = () => {
    setIsAnimating(!isAnimating);
  };

  const mobileImages = [
    // mobileImage2,
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/banner2-M.png',
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/banner3-M.png'
  ];
  const numDots = images.length;

  return (
    <>
      <ImageSliderContainer>
        <div className="image-slider">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image-slide ${
                currentImageIndex === index ? 'active' : ''
              }`}
            >
              <Link to={currentImageIndex === 0 ? `/careerbeam-festival` : ''}>
                <Img src={image} alt={`Slide ${index}`} />
              </Link>
              {index === 0 && (
                <Link to="/careerbeam-festival">
                  <Button
                    className={isAnimating ? 'Button--animate' : ''}
                    onMouseEnter={toggleAnimation}
                    onMouseLeave={toggleAnimation}
                  >
                    Get Started
                  </Button>
                </Link>
              )}
              {/* {index === 1 && (
                <>
                  <Link to="/careerbeamfestival">
                    
                    <Button1
                      className={isAnimating ? "Button--animate" : ""}
                      onMouseEnter={toggleAnimation}
                      onMouseLeave={toggleAnimation}
                    >
                      Enter Festival
                    </Button1>
                  </Link>
                </>
              )} */}
            </div>
          ))}
          <DotsContainerDesktop numDots={numDots} className="desktop-dot">
            {images.map((image, index) => (
              <Dot
                key={index}
                active={currentImageIndex === index}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </DotsContainerDesktop>
        </div>
      </ImageSliderContainer>
      <div className="mobile-image-slider">
        {mobileImages.map((image, index) => (
          <div
            key={index}
            className={`image-slide ${
              currentImageIndex === index ? 'active' : ''
            }`}
          >
            <Link to={currentImageIndex === 0 ? `/careerbeam-festival` : ''}>
              <Img src={image} alt={`Slide ${index}`} />
            </Link>

            {index === 0 && (
              <>
                <Link to="/careerbeam-form">
                  <Button
                    className={isAnimating ? 'Button--animate' : ''}
                    onMouseEnter={toggleAnimation}
                    onMouseLeave={toggleAnimation}
                  >
                    Register Now
                  </Button>
                </Link>
              </>
            )}
          </div>
        ))}
      </div>
      <DotsContainerMobile numDots={numDots} className="mobile-dots">
        {images.map((image, index) => (
          <Dot
            key={index}
            active={currentImageIndex === index}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </DotsContainerMobile>
    </>
  );
};

export default ImageSlider;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    height: auto;
    width: auto;
    position: relative;
    padding-top: 4.2rem;
    overflow: hidden;
  }
`;

const Button = styled.button`
  @media (min-width: 768px) {
    position: relative;
    bottom: 40rem;
    color: #000000;
    background: #ffffff;
    width: 340px;
    height: 200px;
    left: 5rem;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    opacity: 0;
  }

  @media (max-width: 768px) {
    position: relative;
    bottom: 17.3rem;
    color: white;
    background: #1b75ba;
    width: 130px;
    height: 33px;
    left: 1rem;
    border-radius: 6px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    opacity: 0;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  }
`;

const Button1 = styled.button`
  @media (min-width: 768px) {
    position: relative;
    bottom: 24rem;

    color: #000000;
    background: #ffffff;
    width: 162px;
    height: 51px;
    left: 7.5rem;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
  }

  @media (max-width: 768px) {
    position: relative;
    bottom: 4.4rem;
    color: white;
    background: #1b75ba;
    width: 110px;
    height: 33px;
    left: 1.4rem;
    border-radius: 6px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  }
`;
