import React from "react";
import qs from "../assets/card1.png";
import int from "../assets/card2.png";
import hp from "../assets/card3.png";
import { Link } from "react-router-dom";
// import "../styles/EventCards.css";
import "../components/Cards.css";
import qsmobile from "../assets/qsmobile.png";
import styled from "styled-components";
import beamcard from "../assets/beamcard.png";

const Cards = () => {
  return (
    <section>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24  cards-div">
        <div class="flex flex-col w-full mb-8 text-center">
          <span className="colab ">View More Events</span>
        </div>

        <div class="">
          <div class="mt-8 flex flex-wrap justify-center gap-4">
            <div className="flex cards-main-div">
              <div class="pt-6 m-4">
                <img class="img-cards" src={int} alt="blog" />
                <div className="big-button qs-button">
                  <div class="container-events ">
                    <h1 class="flex justify-center  font-medium text-black lg:text-lg">
                      INITIUM 2023
                    </h1>
                    <Link
                      to="/initium"
                      className="banner-button read-more"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
              <div class="pt-6 m-4">
                <img class="img-cards" src={beamcard} alt="blog" />
                <div className="big-button qs-button">
                  <div class="container-events">
                    <h1 class="flex justify-center font-medium text-black lg:text-lg">
                      CAREER BEAM FESTIVAL
                    </h1>
                    <Link to="/careerbeam" className="banner-button read-more">
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>

              <div class="pt-6 m-4">
                <img class="img-cards" src={hp} alt="blog" />
                <div className="big-button b-t relative left-3">
                  <div class="container-events hp-podcast">
                    <h1 class="flex justify-center items-center  font-medium text-black lg:text-lg">
                      HP FUTURE READY PODCAST
                    </h1>
                    <Link
                      to="/hp-future-ready-podcast"
                      className="banner-b-t read-more"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;

const Img = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;
