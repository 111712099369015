import React from "react";
import styled from "styled-components";
import eventone from "../assets/eventone.png";
import eventTwo from "../assets/event2.png";
import eventThree from "../assets/eventThree.png";
import eventFour from "../assets/eventFour.png";
import eventFive from "../assets/eventFive.png";
import eventSix from "../assets/eventSix.png";
import eventSeven from "../assets/eventSeven.png";
import eventEight from "../assets/eventEight.png";

const EventAttraction = () => {
  return (
    <>
      <EventAttractionComponent>
        <h1>
          Event <span style={{ color: "#0061AB" }}>Attractions</span>{" "}
        </h1>

        <div className="cards">
          <div className="card">
            <img src={eventone} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventTwo} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventThree} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventFour} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventFive} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventSix} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventSeven} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
          <div className="card">
            <img src={eventEight} />
            <div className="head-tag">
              <span className="span">Music Jam Sessions</span>
            </div>
          </div>
        </div>
      </EventAttractionComponent>
    </>
  );
};

export default EventAttraction;

const EventAttractionComponent = styled.div`
  height: 746px;
  background-color: #c2e6ff;

  @media (max-width: 768px) {
    height: 870px;

    .cards {
      width: 80%;
      margin: auto;
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 57px;
    }
    .span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #062229;
    }

    h1 {
      font-family: "DIN";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      padding-top: 48px;
      padding-bottom: 40px;
      color: #44546a;
    }

    .head-tag {
      position: relative;
      bottom: 1rem;
      right: 4px;
      width: 156px;
      height: 40px;
      background: #ffffff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      width: 156px;
      height: 40px;
      background: #ffffff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card {
      width: 146px;
      height: 125px;
      margin: auto;
    }
  }

  @media (min-width: 786px) {
    h1 {
      font-family: "DIN";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      /* identical to box height, or 100% */
      margin: auto;
      text-align: center;
      padding-top: 80px;
      color: #44546a;
    }

    .cards {
      width: 80%;
      margin: auto;
      justify-content: center;
      display: grid;

      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      row-gap: 54px;
      margin-top: 72px;
    }

    .card {
      width: 216px;
      height: 195px;
      margin: auto;
    }

    .head-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      width: 236px;
      height: 52px;
      position: relative;
      bottom: 1.5rem;
      /* top: 10rem; */
      right: 0.8rem;

      .span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #062229;
      }
    }
  }
`;
