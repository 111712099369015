import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import DropdownMenu from './DropdownMenu';
import ServicesDropdownMenu from './ServicesDropdownMenu';
import { useState } from 'react';
// import { FaBars } from "react-icons/fa";
// import { IoClose } from "react-icons/io5";
import './Hamburger.css';
// import RightNav from "./RightNav";
// import Burger from "./Burger";
import dn from '../assets/dn.png';
// import pencil from "../assets";

import HamburgerMenu from './HamMenu';

export const NewNav = () => {
  const { pathname } = useLocation();

  // const [isOpen, setIsOpen] = useState(false);

  // const handleToggle = () => setIsOpen(!isOpen);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleServicesDropdownClick = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  return (
    <>
      <StyledNav>
        <h1>
          <Link id="logo" to="/">
            <img className="logogo" src={Logo} alt="" />
          </Link>
        </h1>
        <ul>
          <li>
            <Link className="nav-head" to="/">
              Home
            </Link>
            <Line
              transition={{ duration: 0.75 }}
              initial={{ width: '0%' }}
              animate={{ width: pathname === '/' ? '50%' : '0%' }}
            />
          </li>
          <li>
            <Link className="nav-head" to="/about-us">
              About
            </Link>
            <Line
              transition={{ duration: 0.75 }}
              initial={{ width: '0%' }}
              animate={{ width: pathname === '/about-us' ? '50%' : '0%' }}
            />
          </li>
          <li>
            <Link className="nav-head" to="/contact-us">
              Contact Us
            </Link>
            <Line
              transition={{ duration: 0.75 }}
              initial={{ width: '0%' }}
              animate={{ width: pathname === '/contact-us' ? '50%' : '0%' }}
            />
          </li>

          {/* <li>
            <Link className="nav-head" onClick={handleDropdownClick}>
              Events <img style={{ display: 'inline' }} src={dn} alt="" />
              {isDropdownOpen && <DropdownMenu />}
            </Link>
            <Line
              transition={{ duration: 0.75 }}
              initial={{ width: '0%' }}
              animate={{
                width: pathname === '/qs-mba-and-master-fair' ? '50%' : '0%'
              }}
            />
          </li> */}
          {/* 
          <li>
            <Link className="nav-head" onClick={handleServicesDropdownClick}>
              Services <img style={{ display: 'inline' }} src={dn} alt="" />
              {isServicesDropdownOpen && <ServicesDropdownMenu />}
            </Link>
          </li> */}
        </ul>
      </StyledNav>
      <HamburgerMenu />
    </>
  );
};

const StyledNav = styled.nav`
  min-height: 10vh;
  display: flex;
  width: auto;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 6rem;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 30;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .survey {
    background: #1b75ba;
    border-radius: 6px;
    width: 163px;
    /* height: 60px; */
    padding: 0.7rem;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }

  @media (max-width: 768px) {
    display: none;
  }

  Link {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
    color: #062229;
  }
  ul {
    display: flex;
    list-style: none;
  }
  #logo {
    width: 7rem;
  }
  .logogo {
    width: 131px;
    height: 34px;
  }
  span {
    color: red;
  }
  li {
    padding-left: 6rem;
    position: relative;
  }
  @media (max-width: 1250px) {
    flex-direction: column;
    padding: 1.3rem 1rem;
    width: 100%;
    #logo {
      display: inline-block;
      /* margin: 1rem; */
    }

    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
`;
const Line = styled(motion.div)`
  height: 0.3rem;
  background: #0070c0;
  width: 5%;
  position: absolute;
  bottom: -80%;
  left: 60%;

  @media (max-width: 1250px) {
    left: 0%;
  }
`;
