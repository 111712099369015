import React, { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    // Function to load the Google Tag Manager script
    const loadGoogleTagManager = () => {
      const script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PLNZTZH');`;
      document.head.appendChild(script);
    };

    // Load Google Tag Manager script when the component mounts
    loadGoogleTagManager();

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.querySelector("script");
      document.head.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-PLNZTZH"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManager;
