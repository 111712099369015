import React from "react";
import "../styles/CityList.css";

const cities = [
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Dehradun",
  "Hyderabad",
  "Pune",
  "Ranchi",
  "Lucknow",
  "Guwahati",
  "Indore",
  "Delhi",
  "Darjeeling",
  "Patna",
  "Goa",
  "Chandigarh",
  "Kolkata",
  "Jaipur",
  "Bhopal",
  "Ahmedabad",
  "Mangalore",
];

function CityList() {
  return (
    <div className="city-grid-container">
      {cities.map((city) => (
        <div className="city-grid-item" key={city}>
          <span className="city-list">{city}</span>
        </div>
      ))}
    </div>
  );
}

export default CityList;
