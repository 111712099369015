import ReactLoading from "react-loading";

export const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 200,

      }}
    >
      <div className="space-y-4">
        <ReactLoading type={"spin"} color={"white"} height={100} width={100} />
        <h3 className="text-2xl">Loading...</h3>
      </div>
    </div>
  );
};
