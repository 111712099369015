import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import backbtn from "../assets/icon-pen.png";
import { Link } from "react-router-dom";
import background from "../assets/formback.png";
import uploadImage from "../assets/oploadArrow.png";
import ReactGA from "react-ga";
import Loader from "./Loader";
import Modal from "react-modal";
// import OtpComponent from "../components/OtpComponent";
// import OtpComponent from "./OtpComponent";
Modal.setAppElement("#root");

const ContactusScreen = () => {
  const [utmParams, setUtmParams] = useState({});
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const capturedUtmParams = {};

    capturedUtmParams.source = queryParams.get("utm_source");
    capturedUtmParams.medium = queryParams.get("utm_medium");
    capturedUtmParams.campaign = queryParams.get("utm_campaign");

    setUtmParams(capturedUtmParams);
  }, [location.search]);

  useEffect(() => {
    // Function to load the Google Tag Manager script
    const loadGoogleTagManager = () => {
      const script = document.createElement("script");
      script.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-11161595087/Ran0CMOS5JsYEM_Zocop'});`;
      document.head.appendChild(script);
    };

    // Load Google Tag Manager script when the component mounts
    loadGoogleTagManager();

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.querySelector("script");
      document.head.removeChild(script);
    };
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [college, setcollege] = useState("");
  const [course, setCourse] = useState("");
  const [city, setcity] = useState("");
  const [interested, setInterested] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [file, setFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Masterclass");
  const [selectedCity, setSelectedCity] = useState("Mumbai");
  const [referral, setReferral] = useState("");
  const [isSubscribed, setSubscribed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [otpCode, setOtpCode] = useState(null);
  const [verified, setVerified] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSent, setIsSent] = useState(false);

  console.log(otpCode);

  console.log(selectedCheckboxes);

  const maxSize = 1000000;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const Checkbox = ({ label, isSelected, onCheckboxChange, id }) => {
    const [selectedCount, setSelectedCount] = useState(0);

    const handleCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      onCheckboxChange(event);
      setSelectedCount((prevCount) =>
        isChecked ? prevCount + 1 : prevCount - 1
      );
    };

    return (
      <div className="checkbox-div">
        <label className="checkbox-listing">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
          {label}
        </label>
        {/* {selectedCount === 0 && alert("please select at least one event")} */}
      </div>
    );
  };

  const RadioButton = ({ label, isSelected, onRadioChange }) => (
    <div>
      <label className="radioLabel">
        <input
          className="radio-input"
          type="radio"
          checked={isSelected}
          onChange={onRadioChange}
        />
        {label}
      </label>
    </div>
  );

  const citiesToCheckboxes = {
    Mumbai: {
      Masterclass: [
        "Building & Owning Your Personal Style (13th May) ",
        "How Product Designers Think! (13th May) ",
        "The Power of Design Thinking (13th May) ",
        "Building Successful Campaigns (13th May) ",
        "Learn A-Z of Digital Marketing (14th May) ",
        "What’s Next in Advertising? (14th May) ",
        "E-Sport- The New Education (14th May) ",
      ],
      Workshop: [
        "Fashion: Beyond Designing (13th May)",
        "All About Lighting (13th May)",
        "Brainstorming Ideas and Developing Prototypes (13th May)",
        "User Experience Design (13th May)",
        "Draw Bollywood Faces (13th May)",
        "A New Age: Learn Working with AI (13th May)",
        "Calligraphy, Come Learn the Art (13th May)",
        "Game Designing: What's New? (13th May)",
        "Emerging Technologies in Mechanical Engineering (13th May)",
        "Post Covid: Marketing in the New Normal (13th May)",
        "Making The Menu is Magic! (14th May)",
        "Creative Writing: Is Chat GPT Killing Creativity? (14th May)",
        "Returning to the Aviation Market (14th May)",
        "How Can a Wrong Note Be Perfect? (14th May)",
        "Advertising to the Heart of The Consumers (14th May)",
        "Maximising Esport Business (14th May)",
        "How to Manage Personal Finances for Gen Z (14th May)",
        "Legal Research Writing Workshop (14th May)",
        "Diversifying the Next Generation of Doctors (14th May)",
        "Build Your Own Buisness (14th May)",
      ],
    },
    Banglore: {
      Masterclass: [
        "The Language of Fashion (20th May) ",
        "What’s Next in Advertising? (20th May) ",
        "Design For a Better World: Masterclass (20th May) ",
        "From Idea to Final Layout- UI/UX Masterclass (21st May) ",
        "What’s Next in Advertising? (21st May) ",
      ],
      Workshop: [
        "Hi Fashion (20th May)",
        "Interior Design is a Career Choice (20th May)",
        "Transforming Insights into Design Principles (20th May)",
        "Visual Communication, Content Curation on the Go (20th May)",
        "The Role of insights in Communication (20th May)",
        "AI: An Advance Tool in Design Industry (20th May)",
        "Shoot a Short Film Workshop (20th May)",
        "Scope of Gaming Industry in 21st Century  (20th May)",
        "Mobile, Robotics & Details  (20th May)",
        "Revolution in Digital Marketing (20th May)",
        "What is a New Trend in Hospitality? (21st May)",
        "Advanced Fundamentals of Cyber Security (21st May)",
        "Returning to the Aviation Market (21st May)",
        "How Can a Wrong Note Be Perfect? (21st May)",
        "Media- A New Age Platform (21st May)",
        "Maximising Esport Business (21st May)",
        "Financial eEducation for Gen Z (21st May)",
        "How to Excel in  Law School (21st May)",
        "Medicine and Innovation (21st May)",
        "who is an Entrepreneur? (21st May)",
      ],
    },
    Delhi: {
      Masterclass: [
        "From Trends to Memes: Social Media Masterclass (27th May) ",
        "Principles of Styling Masterclass (27th May) ",
        "Fashion Editorial Masterclass (27th May) ",
        "Designing Products Like a Pro (28th May) ",
        "Product Design Using Gamification (28th May) ",
      ],
      Workshop: [
        "Building a Brand Called You (27th May)",
        "Build a Career in Architecture (27th May)",
        "Become a Product Designer (27th May)",
        "Expressive Typography (27th May)",
        "Creative Art Techniques (27th May)",
        "AI- Preparing for the Future (27th May)",
        "Deconstruct Cinema- Film Making Workshop (27th May)",
        "Why Gaming ? (27th May)",
        "Emerging Trends in Computational, Thermal & Material Engineering (27th May)",
        "Shape Your Digital Marketing Career (27th May)",
        "Future of Hospitality (28th May)",
        "Explaining Data Science (28th May)",
        "Returning to the Aviation Market",
        "Vocal & Song Writing Workshop (28th May)",
        "Power Media (28th May)",
        "Maximising Esport Business (28th May)",
        "Personal Finance Workshop (28th May)",
        "Interested in Law as a Profession (28th May)",
        "Interested in Health Care as a Profession (28th May)",
        "Workshop on Entrepreneur Skills, Attitude & Behaviour (28th May)",
      ],
    },
  };
  const CheckboxGroup = ({
    city,
    event,
    selectedCheckboxes = {},
    onCheckboxChange,
    startIndex = 0,
  }) => {
    const checkboxes = citiesToCheckboxes[city][event];

    return (
      <div>
        {checkboxes.slice(startIndex).map((option) => (
          <div>
            <Checkbox
              className="checkbox-div"
              key={option}
              label={option}
              isSelected={!!selectedCheckboxes[option]}
              onCheckboxChange={(e) =>
                onCheckboxChange(
                  {
                    ...selectedCheckboxes,
                    [option]: !selectedCheckboxes[option],
                    [e.target.id]: e.target.checked,
                  },
                  e.target.id
                )
              }
            />
          </div>
        ))}
      </div>
    );
  };

  let values;

  let goiningCity;

  let newOtp;

  newOtp = otpCode;

  const history = useNavigate();

  console.log(values);

  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };

  console.log(selectedCheckboxes);

  goiningCity = selectedCity;

  values = Object.keys(selectedCheckboxes).map((item) => {
    return item.trim();
  });

  console.log(values);

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    // setSelectedOption("Masterclass");
    // setSelectedCheckboxes({});
    setSelectedCity(selectedCity);
    // const data = axios.get(`http://localhost:5000/api/events/${selectedCity}`);
    // const info = data.map((item) => {});
  };

  console.log(referral);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("image", file);
    formData.append("email", email);

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://careerbeam-backend.onrender.com/upload",
        formData
      );

      const { url } = response.data;

      const data = {
        name,
        email,
        phone,
        college,
        course,
        city,
        events: { eventName: values, city: goiningCity },
        url,
        referral,
        isSubscribed,
      };
      const apiCallPromise = await axios.post(
        "https://careerbeam-backend.onrender.com/api/studentInfo",
        data
      );

      ReactGA.event({
        category: "Button",
        action: "Click",
      });

      await Promise.all([response]);
      await Promise.all([apiCallPromise]);

      setIsLoading(false);

      history("/thankyou");

      localStorage.setItem("user", JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const Otpresponse = await axios.post("http://localhost:5000/verify-otp", {
  //       phoneNumber: phone,
  //     });

  //     setShowPopup(true);
  //     const { sent } = Otpresponse.data;

  //     console.log(sent);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handlePopupSubmit = async (e, otpCode) => {
  //   e.preventDefault();

  //   setShowPopup(false);
  //   const formData = new FormData();

  //   formData.append("image", file);
  //   formData.append("email", email);

  //   console.log(utmParams.source);

  //   formData.append("utm_source", utmParams.source);
  //   formData.append("utm_medium", utmParams.medium);
  //   formData.append("utm_campaign", utmParams.campaign);

  //   setIsLoading(true);

  //   try {
  //     const OtpVerified = await axios.post("http://localhost:5000/check-otp", {
  //       phoneNumber: phone,
  //       otpCode: newOtp,
  //     });

  //     console.log(OtpVerified.data);

  //     const { verified } = OtpVerified.data;

  //     if (verified) {
  //       alert("OTP verified successfully");
  //       setVerified(true);
  //       const response = await axios.post(
  //         "https://careerbeam.vigorlaunchpad.in/upload",
  //         formData
  //       );

  //       const { url } = response.data;

  //       const data = {
  //         name,
  //         email,
  //         phone,
  //         college,
  //         course,
  //         city,
  //         events: { eventName: values, city: goiningCity },
  //         url,
  //         referral,
  //         isSubscribed,
  //       };
  //       const apiCallPromise = await axios.post(
  //         "https://careerbeam.vigorlaunchpad.in/api/studentInfo",
  //         data
  //       );

  //       ReactGA.event({
  //         category: "Button",
  //         action: "Click",
  //       });
  //       await Promise.all([OtpVerified]);
  //       await Promise.all([response]);
  //       await Promise.all([apiCallPromise]);

  //       setIsLoading(false);

  //       history("/thankyou");

  //       localStorage.setItem("user", JSON.stringify(data));
  //     } else {
  //       alert("Wrong OTP entered");
  //       setVerified(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleSubscribe = (event) => {
    setSubscribed(event.target.checked);
    console.log(event.target.checked);
  };

  console.log(selectedCity);

  const handleInvalidPhone = (e) => {
    e.target.setCustomValidity("You need to enter a 10-digit number");
  };

  const handlePhoneChange = (e) => {
    e.target.setCustomValidity("");
    setPhone(e.target.value);
  };

  return (
    <FormComponenet>
      <ParentDiv>
        <Link to="/">
          <Button>
            <img
              style={{ height: "12px", position: "relative", right: "8px" }}
              src={backbtn}
            />
            Back
          </Button>
        </Link>
        <Childtwo>
          <H1>ASCend Beyond with CareerBeam</H1>
          <P>
            Complete the registration form to get access to personalized career
            assessment tests, expert career counsellors, workshops & panel
            discussions.
          </P>

          <div className="line"></div>
          {isLoading && <Loader />}
          <form onSubmit={handleSubmit} className="form">
            <label className="label" htmlFor="name">
              Full Name<span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Rahul Sharma"
              required
            />
            <label className="label" htmlFor="email">
              Email Id<span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="example@example.com"
              // required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <label className="label" htmlFor="tel">
              Mobile Number<span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={handlePhoneChange}
              onInvalid={handleInvalidPhone}
              type="phone"
              placeholder="10 Digit Mobile Number"
              pattern="[0-9]{10}"
              required
            />

            <label className="label" htmlFor="school">
              Which College/School are you studying in?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={(e) => setcollege(e.target.value)}
              type="text"
              placeholder="Example High School"
              required
            />
            <label className="label" htmlFor="email">
              What are you currently studying?
              <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={(e) => setCourse(e.target.value)}
              type="text"
              placeholder="TY BCom, SY BSC, FY BA"
              required
            />
            <label className="label" htmlFor="email">
              Which city are you currently residing in?
              <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              onChange={(e) => setcity(e.target.value)}
              type="text"
              placeholder="Mumbai"
              required
            />

            <RadioForm>
              <div className="radio-option">
                <>
                  <div className="">
                    <label className="label mobile-label">
                      Where are you planning to attend the event?
                      <span style={{ color: "red" }}>*</span>
                      <div>
                        <select
                          className="select mobileSelect"
                          value={selectedCity}
                          onChange={handleCityChange}
                        >
                          <option value="Mumbai">Mumbai</option>
                          <option value="Banglore">Bangalore</option>
                          <option value="Delhi">Delhi</option>
                        </select>
                      </div>
                    </label>
                  </div>

                  <label
                    className="ref-label radio-label mobile-label"
                    htmlFor="email"
                  >
                    What are you interested in? (You can select either or both)
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="radio-component">
                    <div className="r-g">
                      <RadioButton
                        label="Panel Discussion"
                        className="r-g"
                        isSelected={selectedOption === "Masterclass"}
                        onRadioChange={() => handleRadioChange("Masterclass")}
                      />

                      <RadioButton
                        className="r-g"
                        label="Workshop"
                        isSelected={selectedOption === "Workshop"}
                        onRadioChange={() => handleRadioChange("Workshop")}
                      />
                    </div>
                  </div>
                  <div className="checkbox-div">
                    {[0].map((startIndex) => (
                      <CheckboxGroup
                        key={startIndex}
                        city={selectedCity}
                        event={selectedOption}
                        selectedCheckboxes={selectedCheckboxes}
                        startIndex={startIndex}
                        onCheckboxChange={(selectedValues, id) => {
                          setSelectedCheckboxes((prevState) => ({
                            ...prevState,
                            ...selectedValues,
                          }));
                          console.log(`Checkbox with id ${id} is selected`);
                        }}
                      />
                    ))}
                    <div className="selected ">
                      {Object.entries(selectedCheckboxes)
                        .filter(([_, value]) => value)
                        .map(([key, _]) => key)
                        .join("|")}
                    </div>
                  </div>
                </>
              </div>
            </RadioForm>

            <label className="label">
              Upload an Image of your School/College Id Card
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="big-container">
              <input
                className="file-upload"
                type="file"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="mobile-ref">
              <label className="label" htmlFor="email">
                Enter reference code (provided by your coordinator)
              </label>
              <Input
                onChange={(e) => setReferral(e.target.value)}
                type="text"
                placeholder="XLM85"
              />
            </div>

            <div className="checkbox-listing subscribe">
              <input
                type="checkbox"
                defaultChecked
                className="subscribe-text"
                onClick={handleSubscribe}
              />
              Subscribe for event updates & newsletter.
            </div>
            <button name="image" className="flex">
              SUBMIT NOW
            </button>
            {/* <button className="flex">Verify OTP</button> */}
            {/* <OtpComponent /> */}
            {/* {showPopup && (
              <OTPcomponent>
                <div className="popup">
                  <div className="popup-content">
                    <h2>Enter OTP</h2>
                    <input
                      type="text"
                      onChange={(e) => setOtpCode(e.target.value)}
                      placeholder="Enter OTP"
                    />
                    <button onClick={handlePopupSubmit}>Submit OTP</button>
                  </div>
                </div>
              </OTPcomponent>
            )} */}
          </form>
        </Childtwo>
      </ParentDiv>

      <ParentMobile>
        <Link to="/">
          <ButtonMob>Back</ButtonMob>
        </Link>

        <MobileHead>Contact Us</MobileHead>

        <MobileChildOne>
          <h1>ASCend Beyond with CareerBeam</h1>
          <p>
            Complete the registration form to get access to personalized career
            assessment tests, expert career counsellors, workshops & panel
            discussions.
          </p>
          <div className="line"></div>
          {isLoading && <Loader />}

          <MobileForm onSubmit={handleSubmit} className="form">
            <label htmlFor="name" className="mobile-label">
              Full Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Rahul Sharma"
              required
            />
            <label htmlFor="name" className="mobile-label">
              Email Id<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="example@example.com"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <label htmlFor="name" className="mobile-label">
              Mobile Number<span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={handlePhoneChange}
              onInvalid={handleInvalidPhone}
              type="phone"
              placeholder="10 Digit Mobile Number"
              pattern="[0-9]{10}"
            />
            <label htmlFor="name" className="mobile-label">
              Which College/School are you studying in?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={(e) => setcollege(e.target.value)}
              type="phone"
              Example
              High
              School
              placeholder="Example High School"
              required
            />
            <label htmlFor="name" className="mobile-label">
              What are you currently studying?
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={(e) => setCourse(e.target.value)}
              type="phone"
              placeholder="TY BCom, SY BSC, FY BA"
              required
            />
            <label htmlFor="name" className="mobile-label">
              Which city are you currently residing in?
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="mobile-input"
              onChange={(e) => setcity(e.target.value)}
              type="text"
              placeholder="Mumbai"
              required
            />
            <>
              <div className="">
                <label className="label mobile-label">
                  Where are you planning to attend the event?
                  <span style={{ color: "red" }}>*</span>
                  <div>
                    <select
                      className="select mobileSelect"
                      value={selectedCity}
                      onChange={handleCityChange}
                    >
                      <option value="Mumbai">Mumbai</option>
                      <option value="Banglore">Bangalore</option>
                      <option value="Delhi">Delhi</option>
                    </select>
                  </div>
                </label>
              </div>
              <label
                className="ref-label radio-label mobile-label"
                htmlFor="email"
              >
                What are you interested in? (You can select either or both)
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="radio-component">
                <div className="r-g">
                  <RadioButton
                    label="Panel Discussion"
                    className="r-g"
                    isSelected={selectedOption === "Masterclass"}
                    onRadioChange={() => handleRadioChange("Masterclass")}
                  />

                  <RadioButton
                    className="r-g"
                    label="Workshop"
                    isSelected={selectedOption === "Workshop"}
                    onRadioChange={() => handleRadioChange("Workshop")}
                  />
                </div>
              </div>
              <div className="checkbox-div">
                {[0].map((startIndex) => (
                  <CheckboxGroup
                    key={startIndex}
                    city={selectedCity}
                    event={selectedOption}
                    selectedCheckboxes={selectedCheckboxes}
                    startIndex={startIndex}
                    onCheckboxChange={(selectedValues, id) => {
                      setSelectedCheckboxes((prevState) => ({
                        ...prevState,
                        ...selectedValues,
                      }));
                      console.log(`Checkbox with id ${id} is selected`);
                    }}
                  />
                ))}
                <div className="selected ">
                  {Object.entries(selectedCheckboxes)
                    .filter(([_, value]) => value)
                    .map(([key, _]) => key)
                    .join(" | ")}
                </div>
              </div>
            </>
            <label className="mobile-label">
              Upload an Image of your School/College Id Card
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              class="file-upload"
              type="file"
              onChange={handleFileChange}
              required
            />
            <div className="mobile-ref">
              <label htmlFor="name" className="ref-label mobile-label">
                Enter reference code (provided by your coordinator)
              </label>
              <input
                className="mobile-input"
                onChange={(e) => setReferral(e.target.value)}
                type="phone"
                placeholder="TXLM85"
              />
            </div>
            <div className="checkbox-listing subscribe">
              <input
                type="checkbox"
                defaultChecked
                onClick={handleSubscribe}
                className="subscribe-text"
              />
              Subscribe for event updates & newsletter.
            </div>
            <button className="flex">SUBMIT NOW</button>
          </MobileForm>
        </MobileChildOne>
      </ParentMobile>
    </FormComponenet>
  );
};

export default ContactusScreen;

const RadioForm = styled.div`
  margin: auto;
  margin-top: 25px;
`;

const FormComponenet = styled.div`
  background-image: url(${background});
  background-color: orange;
  background-color: rgba(255, 165, 0, 0.4);
  background-attachment: fixed;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    .line {
      height: 0px;
      margin-top: 40px;
      border: 1px solid #cccccc;
    }
    .mobile-ref {
      margin-top: 1.5rem;
    }
    .selected {
      width: 439px;
      height: 147px;
      height: auto;
      padding: 1rem;
      margin-right: 25px;
      border: 1px solid #d9d9d9;
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      margin-top: 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      color: #062229;
      align-items: start;
      margin-left: 85px;
      opacity: 0.8;
    }
    .selected ul li {
      text-align: center;
    }
    .selected ul {
      position: relative;
      margin: auto;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 1rem;

    .selected {
      padding: 1rem;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;

      color: #062229;

      opacity: 0.8;
      width: 311px;
      height: 263px;
      height: auto;
      margin: auto;
      border: 1px solid #d9d9d9;
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      margin-top: 10px;
    }
    .line {
      height: 0px;
      margin-top: 80px;
      border: 1px solid #cccccc;
    }
    .selected ul li {
      text-align: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #062229;

      opacity: 0.8;
    }
    .selected p {
    }
    .selected ul {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Input = styled.input`
  background: #f9f9fa;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 469px;
  height: 47px;
  display: flex;
  margin: auto;
  margin-top: 16px;
  text-indent: 14px;
  margin-bottom: 20px;
`;

const Button = styled.div`
  left: 23rem;
  position: relative;
  bottom: 4rem;
  width: 105px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #1b75ba;
`;

const MobileHead = styled.h3`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  overflow: hidden;
  position: relative;
  bottom: 3rem;
  margin-left: 2rem;
  text-transform: capitalize;
  color: #ffffff;
`;

const ParentDiv = styled.div`
  @media (max-width: 786px) {
    display: none;

    .img {
      display: none;
    }
  }
  position: relative;
  top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
`;

const ParentMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }
`;

const ButtonMob = styled.button`
  position: relative;
  top: 6rem;
  left: 1rem;
  width: 105px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #1b75ba;
`;

const MobileChildOne = styled.div`
  margin: auto;
  width: 363px;
  height: 1580px;
  height: auto;
  padding-bottom: 3rem;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 3rem;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  border-radius: 12px;

  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 40px;
    color: #0d5e9c;
  }
  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    margin: auto;
    width: 303px;
    height: 46px;
    margin-top: 16px;
    text-align: center;
    color: #062229;
  }
`;

const MobileForm = styled.form`
  margin-top: 24px;
  @media (max-width: 786px) {
    .mobile-ref {
      margin-top: 2rem;
    }

    .ref-label {
      display: flex;
      justify-content: center;
      width: 300px;
      padding-bottom: 8px;
    }
    .radio-option {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      row-gap: 10px;
      align-items: start;
    }
    .radioLabel {
      margin: auto;
      display: flex;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 136px;
      height: 29px;
      text-indent: 3px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    .radio-input {
      margin-left: 6px;
    }

    .checkbox-label {
      display: flex;
      justify-content: center;
      margin: auto;
      text-indent: 3px;
      text-align: start;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-left: 5px;
      position: relative;
      color: #062229;
    }
    .checkbox-div {
      display: flex;
      width: 300px;
      margin: auto;
      flex-direction: column;
      text-align: left;
      margin-top: 6px;
    }
  }

  .mobile-input {
    background: #f9f9fa;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 303px;
    height: 45px;
    display: flex;
    margin: auto;
    margin-top: 16px;
    text-indent: 14px;
    margin-bottom: 20px;
  }

  .select {
    background: #f9f9fa;
    border: 1px solid #d9d9d9;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 301px;

    height: 45px;
    margin: auto;
    margin-left: 2rem;
    margin-bottom: 1rem;
    text-indent: 9px;
    margin-top: 6px;
  }

  button {
    width: 303px;
    height: 49px;
    display: flex;
    margin: auto;
    justify-content: center;
    background: #1b75ba;
    border-radius: 6px;
    margin-top: 30px;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  textarea {
    background: #f9f9fa;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 320px;
    padding-top: 14px;
    height: 400px;
    height: 132px;
    margin: auto;
    justify-content: center;
    margin-top: 16px;
    text-indent: 14px;
    display: flex;
    justify-content: center;
  }

  .mobile-label {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    top: 10px;
    margin-left: 35px;
    margin-top: 24px;
    color: #062229;
  }
`;

const Childtwo = styled.div`
  height: 1570px;
  height: auto;
  padding-bottom: 4rem;
  width: 800px;
  box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.1);
  margin: auto;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  border-radius: 10px;

  .form {
    margin-top: 2rem;
  }
  .label {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    width: 74px;
    position: relative;
    top: 0.5rem;
    height: 26px;
    margin-left: 10.3rem;
    color: #062229;
  }

  button {
    width: 439px;
    height: 51px;
    padding: 1rem;
    left: 704px;
    top: 804px;
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 45px;
    background: #1b75ba;
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  textarea {
    background: #f9f9fa;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 320px;
    padding-top: 14px;
    height: 400px;
    height: 132px;
    margin: auto;
    justify-content: center;
    margin-top: 16px;
    text-indent: 14px;
    display: flex;
    justify-content: center;
  }
`;

const H1 = styled.h1`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-top: 64px;
  text-align: center;
  text-align: center;
  color: #0d5e9c;
`;

const P = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  width: 599px;
  margin: auto;
  color: #062229;
  margin-top: 24px;
`;
const OTPcomponent = styled.div`
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .popup h2 {
    margin-bottom: 10px;
  }

  .popup input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .popup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .popup button:hover {
    background-color: #0056b3;
  }
`;
