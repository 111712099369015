const Card = ({ card, title }) => {
  return (
    <div className="max-w-601 md:max-w-60 flex flex-col justify-center">
      <img src={card} alt="Card" />
      <div className="text-center bg-white rounded-md p-2">
        <h3
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "28px",
            color: "#062229",
          }}
          className="two-liners"
        >
          {title}
        </h3>
      </div>
    </div>
  );
};

export default Card;
