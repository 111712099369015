import { useState } from "react";
import InputBox2 from "../../01_atoms/InputBox2";
import RadioBtn2 from "../../01_atoms/RadioBtn2";
import Button1 from "../../01_atoms/button1";
import SelectEventOverlay from "../SelectEventOverlay";
import InputBox from "../../01_atoms/InputBox";

const OthersLogin = ({ details, setDetails, onSubmit }) => {
  const [showList, setShowList] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBottom: "4rem",
          padding: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <InputBox
            text={"Tell us about yourself"}
            placeholderText={"I am"}
            mark
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  about: e.target.value,
                },
              })
            }
          />

          <InputBox
            text={"What made you interested in CareerBeam Festival?"}
            mark
            placeholderText={"The workshop topics etc"}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  reason_of_interest: e.target.value,
                },
              })
            }
          />
          <RadioBtn2
            text={"Which days would you be attending ASCend Beyond?"}
            options={["4th May", "5th May", "4th & 5th May"]}
            name={"attending_day"}
            mark
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  attending_day: e,
                },
              })
            }
          />

          <RadioBtn2
            mark
            text={"Choose the sessions you wish to attend"}
            options={["Select session", "Just visiting"]}
            name={"chooseEvent"}
            onChange={(e) => {
              if (e === "Select session") {
                setShowList(true);
              }
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  chooseEvent: e,
                  attending_events: {
                    "4th May 2024": [],
                    "5th May 2024": [],
                  },
                },
              });
            }}
          />

          <InputBox2
            text={
              "What are the biggest concerns or challenges you have faced when it comes to education?"
            }
            placeholderText={"Confusing choices in career selection.."}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  biggest_challenge: e.target.value,
                },
              })
            }
          />
          <InputBox2
            text={
              "What topics would you like to see covered in CareerBeam festival?"
            }
            placeholderText={"AI, Military, Coding"}
            onChange={(e) =>
              setDetails({
                ...details,
                details: {
                  ...details.details,
                  topic_suggested: e.target.value,
                },
              })
            }
          />
          <Button1
            style={{ width: "10rem" }}
            text={"Submit Form"}
            onClick={onSubmit}
          />
        </div>

        {showList && (
          <SelectEventOverlay
            details={details}
            setDetails={setDetails}
            setShowList={setShowList}
          />
        )}
      </div>
    </>
  );
};

export default OthersLogin;
