import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Affix,
  Grid
} from 'antd';

import {
  first_section_background,
  career_beam_festival,
  call,
  whatsapp,
  Desktop_bg
} from '../festival_images';
import MetaTags from 'react-meta-tags';

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const StickyCallUsAndChatNow = ({
  aboutSectionRef,
  registrationSectionRef,
  handleScrollOnClick
}) => {
  const screens = useBreakpoint();

  return (
    <>
      <div style={{ height: 0 }}>
        <div className="max-md:hidden">
          <Flex justify="flex-end" style={{ marginRight: '30px' }}>
            <Affix offsetTop={500}>
              {/* <Row>
                <Button
                  type="secondary"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 99,
                    marginLeft: 32,
                    padding: 0,
                    width: 95
                  }}
                >
                  <a href="tel:+917208070768">
                    <Row>
                      <Col span={16} style={{ padding: 3 }}>
                        Call Us
                      </Col>
                      <Col span={8}>
                        <img src={call} alt="call" style={{ padding: 3 }} />
                      </Col>
                    </Row>
                  </a>
                </Button>
              </Row> */}
              <Row style={{ marginTop: 10 }}>
                <Button
                  type="secondary"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 99,
                    padding: 2,
                    marginLeft: 9,
                    width: 120
                  }}
                  onClick={() =>
                    window.open(
                      'https://wa.me/917208070768',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  <Row>
                    <Col span={18}>Chat Now</Col>
                    <Col span={6}>
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        style={{ padding: 1 }}
                      />
                    </Col>
                  </Row>
                </Button>
              </Row>
            </Affix>
          </Flex>
        </div>
        <div className="sm:hidden">
          <Flex justify="flex-end" style={{ marginRight: 10 }}>
            <Affix offsetTop={650}>
              {/* <Row>
                <Button
                  type="secondary"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 99,
                    marginLeft: 32,
                    padding: 0,
                    width: 95
                  }}
                >
                  <a href="tel:+917208070768">
                    <Row>
                      <Col span={16} style={{ padding: 3 }}>
                        Call Us
                      </Col>
                      <Col span={8}>
                        <img src={call} alt="call" style={{ padding: 3 }} />
                      </Col>
                    </Row>
                  </a>
                </Button>
              </Row> */}
              <Row style={{ marginTop: 10 }}>
                <Button
                  type="secondary"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 99,
                    padding: 2,
                    marginLeft: 9,
                    width: 120
                  }}
                  onClick={() =>
                    window.open(
                      'https://wa.me/917208070768',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  <Row>
                    <Col span={18}>Chat Now</Col>
                    <Col span={6}>
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        style={{ padding: 1 }}
                      />
                    </Col>
                  </Row>
                </Button>
              </Row>
            </Affix>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default StickyCallUsAndChatNow;
