

export const call = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_call_icon.svg"
export const career_beam_festival = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_career_beam_festival.svg"
export const first_section_background = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_First_section_bg.svg"
export const festival_Mobile_Bg = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_Mobile_Bg.png"
export const mobile_festival_people = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_mobile_festival_people.svg"
export const mumbai = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_Mumbai.svg"
export const eventone = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_music_jam.png"
export const festival_people = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_people.svg"
export const whatsapp = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_whatsapp_icon.svg"
export const Desktop_bg = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_Desktop_bg.png"
//logos

export const tech = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_tech.svg"
export const sports = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_sports.svg"
export const pharmacy = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_pharmacy.svg"
export const paramedical = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_paramedical.svg"
export const music = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_music.svg"
export const military = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_military.svg"
export const medical = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_medical.svg"
export const media = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_media.svg"
export const law = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_law.svg"
export const hospitality = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_hospitality.svg"
export const filmMaking = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_film-making.svg"
export const fashion = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_fashion.svg"
export const events = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_events.svg"
export const engineering = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_engineering.svg"
export const education = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_education.svg"
export const computer = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_computer.svg"
export const aviation = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_aviation.svg"
export const art = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_art.svg"
export const achitecture = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_achitecture.svg"
export const animate = "https://careerbeam-assets.s3.eu-north-1.amazonaws.com/festival_logo_animate.svg"