import React, { useState } from "react";
import styled from "styled-components";
import "../styles/Courses.css";
import EventLocations from "./GridContainer";
import MbaGrid from "./MbaGrid";
import { motion } from "framer-motion";

function TabContentOne() {
  return (
    <>
      <P>
        Discover top universities from around the world in our free virtual and
        in-person student events. From networking sessions to university fairs
        to seminars and panels, it’s the ultimate way to find your master’s
        degree.
      </P>
      <EventLocations />
    </>
  );
}
const P = styled.div`
  width: 1042px;
  height: 60px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #062229;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    width: 343px;
    height: 115px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */

    text-align: center;

    color: #062229;
  }
`;

function TabContentTwo() {
  return (
    <>
      <P>
        Find your MBA programme and meet your future business school at our free
        virtual and in-person events. From networking sessions, expert panels
        and a drop-in digital platform, it’s a clear and simple way to find the
        MBA Program to suit your ambitions.
      </P>
      <MbaGrid />
    </>
  );
}

const Tabs = React.forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs-container ">
      <div className="tab-header">
        <button
          onClick={() => handleTabClick("tab1")}
          class=""
          className={activeTab === "tab1" ? "active" : ""}
        >
          <span className="degree">Master's</span>
        </button>
        <button
          class=""
          onClick={() => handleTabClick("tab2")}
          className={activeTab === "tab2" ? "active" : "" || "heading"}
        >
          <span className="degree ml-2">MBA</span>
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "tab1" ? <TabContentOne /> : <TabContentTwo />}
      </div>
    </div>
  );
});

export default Tabs;
