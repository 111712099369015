import React, { useState } from "react";
import styled from "styled-components";
import EventHighlights from "./EventHighlights";
import KeyHigh from "./KeyHigh";
import EventScreen from "./EventScreen";
import CareerDomain from "./CareerDomain";
import MoreEventsQS from "../events/MoreEventsQS";
import StandoutBeam from "./StandoutBeam";
import AscendMobilePic from "../assets/ascendMobile.png";
import banner2 from "../assets/ascendbanner.png";
import { Link } from "react-router-dom";
import acsendold from "../assets/acsendold.png";
import blue from "../assets/eventBlue.png";
import girl from "../assets/girlwithplane.png";
import EventAttraction from "./EventAttraction";
import CBC from "./CBC";
import bluebm from "../assets/bluebm.png";
import mgirl from "../assets/mgirl.png";
import GoogleTagManager from "./GoogleTagManeger";

const Careerbeam = () => {
  return (
    <Cbparent>
      <GoogleTagManager />
      <Ascend>
        <img className="img" src={blue} />
        <img className="girl-img" src={girl} alt="" />
        <h1 className="heading-careerbeam">
          <span>ASC</span>end Beyond
        </h1>

        <P>
          CareerBeam's 2023 theme, ASCend Beyond, is designed to broaden
          students' career prospects beyond the traditional Arts, Science, and
          Commerce fields of study. The event offers students personal guidance
          from industry experts, counsellors, and advisors, as well as
          interactive sessions, workshops, and seminars to provide them with an
          in-depth understanding of future career opportunities.
        </P>
      </Ascend>
      <AscendMobile>
        <div className="ascend-mobile">
          {/* <img className="mobile-background" src={bluebm} /> */}
          <img className="mgirl" src={mgirl} />
          <h1 className="asc-head">
            <sapn style={{ color: "#F9DC16" }}>ASC</sapn>end Beyond
          </h1>
          <p className="asc-mobile-para">
            CareerBeam's 2023 theme, ASCend Beyond, is designed to broaden
            students' career prospects beyond the traditional Arts, Science, and
            Commerce fields of study. The event offers students personal
            guidance from industry experts, counsellors, and advisors, as well
            as interactive sessions, workshops, and seminars to provide them
            with an in-depth understanding of future career opportunities.
          </p>
          <Link to="/careerbeam-form">
            <button>Register Now</button>
          </Link>
        </div>
      </AscendMobile>
      <CBC />
      <EventHighlights />
      <EventAttraction />
      {/* <KeyHigh /> */}
      <StandoutBeam />
      <EventScreen />
      <CareerDomain />
    </Cbparent>
  );
};

export default Careerbeam;

const Cbparent = styled.div`
  background: #f3f8ff;
`;

const Ascend = styled.div`
  .img {
    position: relative;
    width: 100%;
    height: 93vh;
  }

  .heading-careerbeam {
    position: absolute;
    top: 13rem;
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 36px;
    color: #ffffff;
    margin-left: 119px;
  }

  .heading-careerbeam span {
    color: #f9dc16;
  }

  .girl-img {
    position: absolute;
    top: 7rem;
    right: 9rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
  button {
    position: relative;
    width: 151px;
    top: 3rem;
    bottom: 16px;
    height: 47px;
    background: #1b75ba;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
`;
const P = styled.p`
  position: absolute;
  bottom: 12rem;
  left: 119px;
  width: 507px;
  height: 210px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  color: #ffffff;

  @media (max-width: 786px) {
    text-align: center;
  }
`;
const H2 = styled.h2`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 36px;
  color: #ffff;
  margin-bottom: 40px;
  position: relative;
  top: 130px;

  span {
    color: #fec112;
  }

  @media (max-width: 786px) {
  }
`;
const AscendMobile = styled.div`
  .mobile-background {
    width: 100%;
  }
  .ascend-mobile {
    background-image: url(${bluebm});
    background-repeat: no-repeat;
    background-size: cover;
    height: 1000px;
  }
  .asc-mobile-para {
    margin: auto;
    position: relative;
    top: 14rem;
    /* bottom: 43rem; */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    width: 343px;
    height: 207px;
  }
  .mgirl {
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    width: 286px;
    height: 305px;
    right: 1.4rem;
    top: 9rem;
  }
  .asc-head {
    position: relative;
    /* bottom: 26rem; */
    margin: auto;
    top: 11rem;
    display: flex;
    justify-content: center;
    font-family: "DIN";
    font-style: normal;
    align-items: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    right: 0.4rem;

    /* identical to box height, or 112% */
  }

  button {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 17rem;
    width: 147px;
    height: 45px;
    background: #fffffd;
    border-radius: 6px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #1971b6;
  }
  padding-bottom: 48px;

  @media (min-width: 786px) {
    display: none;
  }
  @media (max-width: 786px) {
    .div {
      display: flex;
      flex-direction: column;
      margin: auto;
      /* justify-content: center; */
    }
    .h2 {
      display: flex;
      justify-content: center;
      color: #062229;
      font-size: 24px;
      line-height: 32px;
      margin: 2rem 0;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      /* identical to box height */

      color: #062229;
    }
    .mobile-para {
      /* text-align: left; */
      text-align: center;
      display: flex;
      margin: auto;
      justify-content: center;
      color: #062229;
      width: 300px;
      margin: 5px;
      font-family: "Rubik", Sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin: auto;
    }
    .mobile-banner {
      width: 295px;
      margin-bottom: 2rem;
    }
  }
`;
const MobileDiv = styled.div``;
