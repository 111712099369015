import React, { useState } from 'react';
import styled from 'styled-components';
import {
  message,
  Col,
  Divider,
  Row,
  Button,
  Flex,
  Typography,
  Form,
  Input,
  Alert,
  Grid
} from 'antd';

import {
  festival_people,
  mobile_festival_people,
  mumbai
} from '../festival_images';

// import festival_people from '../assets/festival_people.svg';
// import mobile_festival_people from '../assets/festival_mobile_festival_people.svg';
// import mumbai from '../assets/festival_Mumbai.svg';

import EventScheduleModal from './ModalEventSchedule';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const ViewScheduleSection = ({
  scheduleSectionRef,
  handleScrollOnClick,
  registrationSectionRef,
  setSelectedScheduleInModal
}) => {
  const screens = useBreakpoint();

  const navigate = useNavigate();

  const [showForSection, setShowForSection] = useState('');
  const [openRegisterSection, setOpenRegisterSection] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setSelectedScheduleInModal('4');
    setOpen(true);
  };

  const handleOk = () => {};

  const selectedSectionToDate = () => {
    if (showForSection == 4) {
      return '4thMay';
    } else if (showForSection == 5) {
      return '5thMay';
    } else {
      return '4th&5thMay';
    }
  };

  const onFinish = async (values) => {
    const data = {
      ...values,
      selectedDate: showForSection ? selectedSectionToDate() : null
    };
    setIsSubmitting(true);

    try {
      const response = await axios
        .post('https://backend.careerbeam.in/api/eventUser/create', data)
        .then((res) => {
          setIsSubmitting(false);

          navigate('/registration-success');
        });
    } catch (error) {
      setIsSubmitting(false);

      return (
        <Alert message="Error" description={error} type="error" showIcon />
      );
    }
  };

  const RegisterForm = () => {
    return (
      <Form
        name="wrap"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          rules={[
            {
              message: ''
            }
          ]}
        >
          <StyledInput
            placeholder="Enter your name"
            style={{ width: '100%', height: 45 }}
            labelColor="#ffffff"
            required
          />
        </Form.Item>
        <Form.Item name="mobileNumber" style={{ marginTop: -10 }}>
          <StyledInput
            placeholder="Mobile number"
            type="number"
            required
            style={{ width: '100%', height: 45 }}
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: ''
            }
          ]}
          style={{ marginTop: -10 }}
        >
          <StyledInput
            placeholder="Email Id"
            required
            style={{ width: '100%', height: 45 }}
          />
        </Form.Item>

        <Row justify={'center'}>
          <Col span={24}>
            <Form.Item>
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: '#173D7A',
                  color: '#ffffff',
                  width: '100%',
                  marginTop: -10,
                  height: 45
                }}
              >
                {isSubmitting ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                    fullscreen
                  />
                ) : (
                  'Register'
                )}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <ThirdSection
      style={{
        minHeight: screens?.xs ? 640 : 700
      }}
      ref={scheduleSectionRef}
      id="schedule"
    >
      <Flex justify="center">
        <CardSection
          style={{ width: screens?.xs ? '345px' : '502px', borderRadius: 16 }}
        >
          <Row justify={'center'}>
            <img src={mumbai} alt="mumbai" style={{ padding: 10 }} />
            <Col span={24}>
              <Title
                level={1}
                style={{ color: '#ffffff', marginTop: 12, textAlign: 'center' }}
              >
                MUMBAI
              </Title>
            </Col>
            <Col sm={{ span: 18 }} xs={{ span: 22 }}>
              <Paragraph
                level={3}
                style={{
                  color: '#ffffff',
                  textAlign: 'center',
                  fontSize: 16,
                  textAlign: 'center'
                }}
              >
                Hall No-02, NESCO, Goregaon (East), Mumbai, Maharashtra 400063
              </Paragraph>
            </Col>
          </Row>
          <RegisterDateSection>
            <Row justify={'center'} align={'center'}>
              <Col span={4} offset={3}>
                <Paragraph style={{ color: '#212121', textAlign: 'center' }}>
                  4th May
                </Paragraph>
              </Col>
              <Col span={10}>
                <Paragraph style={{ color: '#173D7A' }}>
                  -{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 500,
                      cursor: 'pointer',
                      paddingLeft: 15
                    }}
                    onClick={() => {
                      setOpenRegisterSection(!openRegisterSection);
                      setShowForSection('4');
                    }}
                  >
                    Register Now
                  </span>
                </Paragraph>
              </Col>
              {openRegisterSection && showForSection === '4' && (
                <Col span={22}>
                  <RegisterForm />
                </Col>
              )}
            </Row>

            <Row justify={'center'}>
              <Col span={4} offset={3}>
                <Paragraph style={{ color: '#212121', textAlign: 'center' }}>
                  5th May
                </Paragraph>
              </Col>
              <Col span={10}>
                <Paragraph style={{ color: '#173D7A' }}>
                  -{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 500,
                      cursor: 'pointer',
                      paddingLeft: 15
                    }}
                    onClick={() => {
                      setOpenRegisterSection(!openRegisterSection);
                      setShowForSection('5');
                    }}
                  >
                    Register Now
                  </span>
                </Paragraph>
              </Col>
              {openRegisterSection && showForSection === '5' && (
                <Col span={22}>
                  <RegisterForm />
                </Col>
              )}
            </Row>

            <Row justify={'center'}>
              <Col span={7} offset={1}>
                <Paragraph style={{ color: '#212121', textAlign: 'center' }}>
                  4th & 5th May
                </Paragraph>
              </Col>
              <Col span={11}>
                <Paragraph style={{ color: '#173D7A' }}>
                  -{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 500,
                      cursor: 'pointer',
                      paddingLeft: 15
                    }}
                    onClick={() => {
                      setOpenRegisterSection(!openRegisterSection);
                      setShowForSection('both');
                    }}
                  >
                    Register Now
                  </span>
                </Paragraph>
              </Col>
              {openRegisterSection && showForSection === 'both' && (
                <Col span={22}>
                  <RegisterForm />
                </Col>
              )}
            </Row>
          </RegisterDateSection>

          <Row justify={'center'}>
            <Button
              ghost
              style={{
                marginTop: 30,
                marginBottom: 30,
                height: 47,
                paddingRight: 40,
                paddingLeft: 40,
                fontSize: 16
              }}
              onClick={showModal}
            >
              View Schedule
            </Button>
          </Row>
        </CardSection>
      </Flex>
      {open && (
        <EventScheduleModal
          showModal={showModal}
          open={open}
          handleCancel={handleCancel}
          handleOk={handleOk}
          handleScrollOnClick={handleScrollOnClick}
          registrationSectionRef={registrationSectionRef}
          setSelectedScheduleInModal={setSelectedScheduleInModal}
        />
      )}
    </ThirdSection>
  );
};

export default ViewScheduleSection;

const ThirdSection = styled.div`
  background-image: url(${festival_people});
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;

  @media (max-width: 430px) {
    background-image: url(${mobile_festival_people});
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
  }
`;

const CardSection = styled.div`
  background-color: #005898;
  margin-top: 40px;
`;

const RegisterDateSection = styled.div`
  background-color: #ffffff;
  padding-top: 20px;
`;

const StyledInput = styled(Input)`
  &::placeholder {
    color: #1e1e2f;
    opacity: 50%;
  }
`;
