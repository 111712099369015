// // // import squarecard from "../assets/squareCard.png";
// // import React from "react";
// // import qs from "../assets/qs.png";
// // import int from "../assets/Website_Banner_750x465_2.png";
// // import hp from "../assets/Website_Banner_750x465 1.png";
// // import { Link } from "react-router-dom";
// // import "./Cards.css";
// // import styled from "styled-components";

// // const Cards = () => {
// //   return (
// //     <section>
// //       <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl bg-slate-100">
// //         <div class="flex flex-col w-full mb-8 text-center">
// //           <span class="text-3xl font-bold tracking-wide text-gray-700 align-middle pt-5">
// //             More Events
// //           </span>
// //         </div>

// //         <div class="">
// //           <p class="font-semibold sm:text-xl sm:leading-relaxed flex justify-center text-center">
// //             Join us on a transformative journey to discover the Career you want
// //             to be in
// //           </p>

// //           <div class="grid w-full grid-cols-1 lg:grid-cols-2 m-auto">
// //             <div className=" ">
// //               <img
// //                 class="event-pic event-pic1 object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl"
// //                 src={int}
// //                 alt="blog"
// //               />

// //               <div class="container-events internal-button ">
// //                 <h1 class="flex justify-center font-medium text-black lg:text-lg">
// //                   QS
// //                 </h1>
// //                 <Link to="/intium-event" className="banner-button read-more">
// //                   Read More »
// //                 </Link>
// //               </div>
// //             </div>
// //             <div className=" ">
// //               <img
// //                 class="event-pic object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl"
// //                 src={hp}
// //                 alt="blog"
// //               />
// //               <div class="container-events">
// //                 <h1 class="">HP FUTURE READY PODCAST</h1>
// //                 <Link to="/podcastscreen" className="banner-button read-more ">
// //                   Read More »
// //                 </Link>
// //               </div>
// //             </div>
// //           </div>
// //           <div>
// //             <Link className="explore-btn">Explore New Events</Link>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // const Div = styled.div`
// //   position: relative;
// //   background-color: white;
// //   width: 306px;
// //   margin: auto;
// //   display: flex;
// //   padding: 1rem;
// //   /* height: 75px; */
// //   background: #ffffff;
// //   box-shadow: 0px 10px 30px rgba(17, 12, 79, 0.1);
// //   border-radius: 8px;
// //   h1 {
// //     font-family: "Manrope";
// //     font-style: normal;
// //     font-weight: 600;
// //     font-size: 19px;
// //     line-height: 34px;
// //     vertical-align: middle;
// //     text-align: center;
// //     margin: auto;
// //     color: #172541;
// //   }
// // `;

// // export default Cards;

// import React from "react";
// import { Link } from "react-router-dom";
// import int from "../assets/card2.png";
// import hp from "../assets/card3.png";

// const MoreEvents = () => {
//   return (
//     <>
//       <div className="parent-div">
//         <img src={int} alt="" />
//         <img src={hp} alt="" />
//       </div>
//       <style jsx>{`
//         .button-div {
//           display: flex;
//           justify-content: center;
//         }

//         .parent-div {
//           display: flex;
//           justify-content: center;
//           margin: 24px;
//         }

//         .buttons:hover {
//           height: 80px;
//         }
//         .buttons {
//           /* width: 275px; */
//           height: 50px;
//           /* background-color: #ffffff; */
//           /* border-radius: 10px; */
//           /* position: relative; */
//           /* overflow: hidden; */
//           /* margin: auto; */
//           /* transition: height 0.5s ease; */
//           /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
//         }

//         @media (max-width: 768px) {
//           .parent-div {
//             display: flex;
//             flex-direction: column;
//           }
//         }
//         .parent-div img {
//           margin: 24px;
//         }
//         .buttons {
//           display: flex;
//           background: #ffffff;
//           box-shadow: 0px 10px 30px rgba(17, 12, 79, 0.1);
//           border-radius: 8px;
//           width: 306px;
//           padding: 1rem;
//           justify-content: center;
//           /* text-align: center; */
//         }
//         .button1 {
//           position: relative;
//           bottom: 5rem;
//           right: 3rem;
//         }
//         .button2 {
//           position: relative;
//           bottom: 5rem;
//           left: 3.2rem;
//         }
//       `}</style>
//       <div className="button-div">
//         <div className="event-div">
//           <h1 className="buttons button1">QS</h1>
//           <Link to="/qs-mba-and-master-fair" className="read">
//             Read More »
//           </Link>
//         </div>
//         <div className="event-div">
//           <h1 className="buttons button2">HP</h1>
//           <Link to="/intium-event" className="read">
//             Read More »
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MoreEvents;
import squarecard from "../assets/squareCard.png";
import React from "react";
import qs from "../assets/card1.png";
import int from "../assets/card2.png";
import hp from "../assets/card3.png";
import { Link } from "react-router-dom";
// import "../styles/EventCards.css";
import "../components/Cards.css";
import qsmobile from "../assets/qsmobile.png";
import styled from "styled-components";
import beamcard from "../assets/beamcard.png";

const Cards = () => {
  return (
    <section>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24  cards-div">
        <div class="flex flex-col w-full  text-center">
          <span className="colab ">View More Events</span>
        </div>

        <div class="">
          <div class="mt-8 flex flex-wrap justify-center gap-4">
            <div className="flex cards-main-div">
              <div class="pt-6 m-4">
                <img class="img-cards" src={qs} alt="blog" />
                <div className="big-button qs-button">
                  <div class="container-events ">
                    <h1 class="flex justify-center  font-medium text-black lg:text-lg">
                      QS
                    </h1>
                    <p className="banner-button read-more">
                      Read More →
                    </p>
                  </div>
                </div>
              </div>
              <div class="pt-6 m-4">
                <img class="img-cards" src={beamcard} alt="blog" />
                <div className="big-button qs-button">
                  <div class="container-events">
                    <h1 class="flex justify-center font-medium text-black lg:text-lg">
                      CAREER BEAM FESTIVAL
                    </h1>
                    <Link to="/careerbeam-festival" className="banner-button read-more">
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>

              <div class="pt-6 m-4">
                <img class="img-cards" src={int} alt="blog" />
                <div className="big-button relative left-3">
                  <div class="container-events hp-podcast">
                    <h1 class="flex justify-center items-center  font-medium text-black lg:text-lg">
                      INTIUM 2023
                    </h1>
                    <Link
                      to="/initium"
                      className="banner-button read-more"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link className="explore-btn">Explore New Events</Link>
        </div>
      </div>
    </section>
  );
};

export default Cards;

const Img = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;
