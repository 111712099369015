import React, { useState } from "react";
import "../styles/Box.css";
import pm from "../assets/personal-meeting.png";
import network from "../assets/network.png";
import panel from "../assets/panels.png";
import meet from "../assets/meeting-expert.png";
import scholar from "../assets/scholarship.png";
import infodesk from "../assets/infodesk.png";
import styled from "styled-components";
import GoogleTagManager from "./GoogleTagManeger";

const Box = ({ title, description, icon }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  const onClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="box-container">
      <div
        className={`box-singal ${isFlipped ? "flipped" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        <div className="front">
          <img src={icon} alt="" />
          <h2> {title}</h2>
        </div>
        <div className="back">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const BoxGrid = () => {
  return (
    <>
      <BoxComponent className="box-grid">
        <Box
          icon={network}
          title="Networking"
          description="Chat with admissions representatives at their booths (both virtual and in-person) and alumni at our tour events."
        />
        <Box
          icon={pm}
          title="Personal Meetings"
          description="
							We’ll use your CV to match you with universities or business schools for short in-person or Zoom meetings.						"
        />
        <Box
          icon={panel}
          title="Panels & Seminars"
          description="
							Our tour events include expert-led panels, seminars and workshops on a range of career and subject-focused topics.						"
        />
        <Box
          icon={meet}
          title="Meet Experts"
          description="
							Our events include representatives from test providers from IELTS, GRE and GMAT, CV experts and financial advisors.						"
        />
        <Box
          icon={scholar}
          title="Exclusive Scholarships"
          description="
							Event attendees can apply to our exclusive QS World Merit Scholarships.						"
        />
        <Box
          icon={infodesk}
          title="QS Info Desks"
          description="
							Our brilliant team is on-hand to help at both our virtual and in-person events.						"
        />
      </BoxComponent>

      <MobileBoxComponent>
        <div>
          <img src={network} />
          <h1>Networking</h1>
          <p>
            Chat with admissions representatives at their booths (both virtual
            and in-person) and alumni at our tour events.
          </p>
        </div>
        <div>
          <img src={panel} />
          <h1>Meet Experts</h1>
          <p>
            We’ll use your CV to match you with universities or business schools
            for short in-person or Zoom meetings.
          </p>
        </div>
        <div>
          <img src={meet} />
          <h1>Networking</h1>
          <p>
            Our events include representatives from test providers from IELTS,
            GRE and GMAT, CV experts and financial advisors.
          </p>
        </div>
        <div>
          <img src={scholar} />
          <h1>Exclusive Scholarships</h1>
          <p>
            Event attendees can apply to our exclusive QS World Merit
            Scholarships.
          </p>
        </div>
        <div>
          <img src={network} />
          <h1>Networking</h1>
          <p>
            Chat with admissions representatives at their booths (both virtual
            and in-person) and alumni at our tour events.
          </p>
        </div>
        <div>
          <img src={infodesk} />
          <h1>QS Info Desks</h1>
          <p>
            Our brilliant team is on-hand to help at both our virtual and
            in-person events.
          </p>
        </div>
      </MobileBoxComponent>
    </>
  );
};
const box = [
  {
    logo: network,
    heading: "Networking",
    paragraph:
      "Chat with admissions representatives at their booths (both virtual and in-person) and alumni at our tour events.",
  },
  {
    logo: panel,
    heading: "Personal Meetings",
    paragraph:
      "We’ll use your CV to match you with universities or business schools for short in-person or Zoom meetings.",
  },
  {
    logo: meet,
    heading: "Meet Experts",
    paragraph:
      "Our events include representatives from test providers from IELTS, GRE and GMAT, CV experts and financial advisors.",
  },
  {
    logo: scholar,
    heading: "Exclusive Scholarships",
    paragraph:
      "Event attendees can apply to our exclusive QS World Merit Scholarships.		",
  },
  {
    logo: infodesk,
    heading: "QS Info Desks",
    paragraph:
      "Our brilliant team is on-hand to help at both our virtual and in-person events.		",
  },
];

export default function App() {
  return (
    <div className="app-container-box">
      <GoogleTagManager />;<h1>What to Expect</h1>
      <BoxGrid />
    </div>
  );
}
const BoxComponent = styled.div`
  @media (max-width: 786px) {
    display: flex;
    flex-direction: column;
    display: none;
  }
`;

const MobileBoxComponent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  div {
    width: 327px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-bottom: 24px;
    background-color: #ffffff;
    img {
      width: 52px;
      height: 52px;
      margin: auto;
      justify-content: center;
      position: relative;
      top: 0.8rem;
    }
    h1 {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #1d1d1b;
      margin: auto;
      /* margin-top: 12px; */
    }
    p {
      width: 279px;
      height: 69px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #1d1d1b;
      display: flex;
      margin: auto;
      margin-top: 2px;
      padding-bottom: 28px;
    }
  }
`;
