import React from "react";
import styled from "styled-components";
import indianmap from "../assets/map.png";
import CityList from "./CityList";
import mobilemap from "../assets/mobilemap.png";
import CityListMobile from "./CityListMobile";

const OurReach = () => {
  return (
    <>
      <NewDiv>
        <Parent>
          <Chlid>
            <Img src={indianmap} alt="" />
            <Div>
              <h1>Our Reach</h1>
              <p>
                We’ve set up a strong network of youth across India by
                constantly working with the young generation. We’ve affiliations
                with A and A+ grade colleges in 20+ cities across India:
              </p>
            </Div>
          </Chlid>
          <CityList />
        </Parent>
      </NewDiv>
      <NewDivMobile>
        <MobileParent>
          <h1>Our Reach</h1>
          <p>
            We’ve set up a strong network of youth across India by constantly
            working with the young generation. We’ve affiliations with A and A+
            grade colleges in 20+ cities across India:
          </p>
          <img src={mobilemap} alt="" />
          <CityListMobile />
        </MobileParent>
      </NewDivMobile>
    </>
  );
};

export default OurReach;

const NewDivMobile = styled.div`
  @media (max-width: 768px) {
    height: 883px;
    background: #f3f6fb;
  }
`;

const MobileParent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  height: 779px;
  background: #eef2fa;
  box-shadow: -6px 0px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  h1 {
    font-family: "Manrope";
    font-style: normal;
    position: relative;
    top: 3rem;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1869a7;
    /* margin-top: 80px; */
  }
  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    width: 304px;
    margin: auto;
    height: 92px;
    margin-top: 60px;
    /* margin: 24px 0px; */
    text-align: center;

    color: #062229;
  }
  img {
    width: 336px;
    height: 309px;
    margin: auto;
    margin-top: 32px;
  }
`;

const Child = styled.div``;

const Img = styled.img`
  width: 375px;
  height: 446px;
`;

const NewDiv = styled.div`
  height: 604px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
  background: #f3f6fb;
`;

const Div = styled.div`
  position: relative;
  width: 567px;
  height: auto;
  background-color: #eef2fa;
  /* bottom: 27.9rem; */
  padding: 2rem;
  box-shadow: -12px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  /* left: 23.5rem; */

  h1 {
    position: relative;
    bottom: 0.6rem;
    right: 0.6rem;
    width: 137px;
    height: 38px;
    color: #1869a7;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }

  p {
    font-weight: 500;
    width: 487px;
    position: relative;
    height: 78px;
    right: 7px;
    line-height: 26px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #062229;
    height: 78px;
    width: 487px;
  }
`;
const Chlid = styled.div`
  /* background-color: #ffffff; */
  display: flex;
  margin: auto;
  overflow: hidden;
  /* position: relative; */
  /* box-shadow: -12px 0px 20px rgba(0, 0, 0, 0.05); */
  border-radius: 12px;
`;

const Parent = styled.div`
  position: relative;
  top: 5rem;
  left: 17rem;
  /* padding: 3rem; */
  /* justify-content: center; */
  /* overflow: hidden; */
  /* margin: auto; */
  margin: auto;
  /* margin-top: 3rem; */
  /* background-color: #f3f6fb; */
`;
