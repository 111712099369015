import React, { useState } from "react";
import styled from "styled-components";
import image1 from "../assets/mask3.png";
import image2 from "../assets/masktwo.png";
// import image3 from "../assets/Goenkaa.png";
import next from "../assets/nextBTN.png";
import prev from "../assets/prevBTN.png";
import { goenkaa } from "../images";

const CBC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { image: image1, name: "ACHYUT PALAV", role: "Master Calligrapher" },
    {
      image: image2,
      name: "RANJIT DAHIYA",
      role: "Founder - Bollywood Art Project",
    },
    {
      image: goenkaa,
      name: "ANIKA GUPTA GOENKA",
      role: "Creative Director - Art Lounge India",
    },
  ];

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) {
        return images.length - 1;
      } else {
        return prevIndex - 1;
      }
    });
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === images.length - 1) {
        return 0;
      } else {
        return prevIndex + 1;
      }
    });
  };

  const displayImages = images
    .slice(currentImageIndex, currentImageIndex + 3)
    .map((image, index) => (
      <div className="card" key={index}>
        <img src={image.image} alt="Carousel Image" />
        <div className="info-tab">
          <h1>{image.name}</h1>
          <h2>{image.role}</h2>
        </div>
      </div>
    ));

  const displayImagesMobile = images
    .slice(currentImageIndex, currentImageIndex + 1)
    .map((image, index) => (
      <div className="card" key={index}>
        <img
          className="carousel-image"
          src={image.image}
          alt="Carousel Image"
        />
        <div className="info-tab">
          <h1 className="speaker-name">{image.name}</h1>
          <h2 className="speaker-role">{image.role}</h2>
        </div>
      </div>
    ));

  return (
    <>
      <CBCcomponent>
        <h1 className="main-heading">
          Join the Conversation on Career Advancement with our Esteemed
          Panelists & Speakers
        </h1>

        <div className="carousel"></div>
        <button className="previous" onClick={previousImage}>
          {/* &larr; */}
        </button>
        <div className="next cards-container">{displayImages}</div>
        <button className="next" onClick={nextImage}>
          {/* &rarr; */}
        </button>
      </CBCcomponent>

      <CBmobile>
        <h1 className="cb-mobie-head">
          Join the Conversation on Career Advancement with our Esteemed
          Panelists & Speakers
        </h1>

        <div className="mobile-display">{displayImagesMobile}</div>
        <button className="previous" onClick={previousImage}>
          <img src={prev} />
        </button>
        <button className="next" onClick={nextImage}>
          <img src={next} />
        </button>
      </CBmobile>
    </>
  );
};

export default CBC;

const CBCcomponent = styled.div`
  @media (max-width: 786px) {
    display: none;
  }

  padding-bottom: 8rem;
  height: 700px;

  .main-heading {
    margin-top: 4rem;
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    margin-left: 134px;
    width: 915px;
    color: #44546a;
  }

  .cards-container {
    display: flex;
    margin: auto;
    justify-content: space-between;
    width: 75%;
    margin-top: 71px;
  }

  .card {
    width: 320px;
    height: 388px;
  }

  .info-tab {
    bottom: 5.5rem;
    left: 1.5rem;
    position: relative;
    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #1b75ba;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #44546a;
    }
  }
`;

const CBmobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }

  .previous {
    position: absolute;
    left: 0;
    margin-left: 1rem;
    top: 88rem;
  }
  .next {
    position: absolute;
    right: 0;
    margin-right: 1rem;

    top: 88rem;
  }
  .mobile-display {
    width: 315px;
    height: 400px;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .cb-mobie-head {
    width: 336px;
    height: 152px;
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin: auto;
    color: #44546a;
  }
  .speaker-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    position: relative;
    bottom: 5.8rem;
    left: 1.5rem;
    /* identical to box height, or 161% */

    color: #1b75ba;
  }
  .speaker-role {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */
    position: relative;
    bottom: 5.5rem;
    left: 1.4rem;
    color: #44546a;
  }
  .carousel-images {
    padding-bottom: 1rem;
  }
`;
