import React from 'react';
import AboutHero from '../components/AboutHero';
import AboutGoals from '../components/AboutGoals';
import CBexp from '../components/CBexp';
import { WhyComponent } from '../components/WhyComponent';
import OurReach from '../components/OurReach';
import styled from 'styled-components';
import abtm from '../assets/aboutusmobile.png';
import GoogleTagManager from '../components/GoogleTagManeger';
import MetaTags from 'react-meta-tags';
import { aboutus } from '../images';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AboutScreen = () => {
  return (
    <>
      <GoogleTagManager />
      <Helmet>
        <title>About CareerBeam: Our Story</title>
        {/* <meta name="title" content="About CareerBeam: Our Story" /> */}
        <meta
          name="description"
          content="Learn about CareerBeam’s journey, mission, and commitment to empowering individuals in their career paths"
        />
        <link rel="canonical" href="https://careerbeam.in/about-us" />
        <meta name="robots" content="all" />

        <meta property="og:title" content="About CareerBeam: Our Story" />
        <meta property="og:site_name" content="CareerBeam" />
        <meta property="og:url" content="https://careerbeam.in/about-us" />
        <meta
          property="og:description"
          content="Learn about CareerBeam’s journey, mission, and commitment to empowering individuals in their career paths"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://careerbeam.in/static/media/mobileb3.9e67f566426f9a90a2cc.png"
        />
      </Helmet>
      {/* <MetaTags>
        <meta name="title" content="About CareerBeam: Our Story" />
        <meta
          name="description"
          content="Learn about CareerBeam’s journey, mission, and commitment to empowering individuals in their career paths"
        />
      </MetaTags> */}

      <div className="bg-slate-200">
        <img
          src={
            'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/About+Us_1442x456.jpg'
          }
          className="h-full w-full ddd"
          alt=""
        />
        <MobileImg
          src={
            'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/About+Us_375x230.jpg'
          }
        />
        <H1>About Us</H1>
        <AboutHero />
        <AboutGoals />
        <WhyComponent />
        <CBexp />
        <OurReach />
      </div>
    </>
  );
};

export default AboutScreen;

// const De = styled.div`
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const H1 = styled.div`
  position: absolute;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;

  @media (max-width: 786px) {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    position: absolute;
    left: 2rem;
    top: 13rem;
    text-transform: capitalize;
    color: #136b81;
  }

  text-align: center;
  text-transform: capitalize;
  color: #136b81;
  left: 19rem;
  top: 16rem;
`;

const MobileImg = styled.img`
  @media (min-width: 786px) {
    display: none;
  }
  margin-top: 4.5rem;
  width: 100%;
`;
