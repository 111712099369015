import React from 'react';
import { Button, Flex, Segmented, Row, Typography, Col, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import register_success from '../assets/register_success.gif';
import MetaTags from 'react-meta-tags';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const PageNotFound = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex,nofollow" />
      </MetaTags>

      <div style={{ height: '100vh', alignContent: 'center', marginTop: -60 }}>
        <Row justify={'center'}>
          <img
            src={
              'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/404+Error+(1).gif'
            }
            alt="success"
            style={{ height: 421, width: 400 }}
          />
        </Row>
        <Row justify={'center'}>
          <h1
            style={{
              fontSize: screens?.xs ? 20 : 40,
              textAlign: 'center',
              fontFamily: 'sans-serif'
            }}
          >
            Oops! Lost? Let’s take you to the right page.
          </h1>
        </Row>
        <Row justify={'center'} style={{ marginTop: 20 }}>
          <Button
            htmlType="submit"
            size="large"
            style={{
              backgroundColor: '#173D7A',
              color: '#ffffff',
              minWidth: '107px'
            }}
            onClick={() => navigate('/careerbeam-festival')}
          >
            Back To Home Page
          </Button>
        </Row>
      </div>
    </>
  );
};

export default PageNotFound;
