import Card from "../components1/02_molecules/Card/Card";
import { bartending, dance, flash, flea, gaming, movie, music, vr } from "../images";
// import music from "../assets1/music.png";
// import flash from "../assets1/flash.png";
// import movie from "../assets1/movie.png";
// import bartending from "../assets1/bartending.png";
// import dance from "../assets1/dance.png";
// import gaming from "../assets1/gaming.png";
// import vr from "../assets1/vr.png";
// import flea from "../assets1/flea.png";

const EventSection = () => {
  const cards = [
    { thumbnail: music, title: "Music Jam Sessions" },
    { thumbnail: flash, title: "Flash Mobs" },
    { thumbnail: movie, title: "Silent Movie Screening" },
    { thumbnail: bartending, title: "Bartending Workshops" },
    { thumbnail: dance, title: "B-Boying Showcase" },
    { thumbnail: flea, title: "Flea Market" },
    { thumbnail: gaming, title: "Beat The Gaming Master" },
    { thumbnail: vr, title: "VR Gaming Zone" },
  ];

  return (
    <div className="bg-[#C2E6FF] pb-10">
      <div className="">
        <h3 className="text-3xl pt-10 text-center font-bold">
          Event <span className="text-[#0061AB]">Attractions</span>
        </h3>
      </div>
      {/* Card */}
      <div className=" w-full py-10 mx-auto md:ml-2">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 max-w-[80%] mx-auto justify-center">
          {cards.map((data, idx) => (
            <Card card={data.thumbnail} title={data.title} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventSection;
