import sqUni from "../assets/SqUniversity.png";
import CareerDomain2 from "../components/CareerDomain2";

const UniversityCard = () => {
  return (
    <div className="flex items-center mt-6 mb-6 shadow-md">
      <div className="w-1/3">
        <img src={sqUni} alt="university img" className="w-full" />
      </div>

      <div>
        <div className="p-6">
          <div className="mt-4">
            <p className="text-3xl mb-2">Somaiya University</p>
            <p>www.somaiya.edu</p>
            <p>93 Mukundapur Main Road Kolkata 700099,</p>
            <p>NAAC | Awards</p>
          </div>

          <div className="mt-8 flex gap-2 w-full">
            <button
              className="w-1/2"
              style={{
                border: "1px solid blue",
                padding: "8px 12px",
                color: "#173D7A",
                width: "50%",
              }}
            >
              View details
            </button>
            <button
              className="w-1/2"
              style={{
                border: "1px solid #173D7A",
                padding: "8px 12px",
                marginLeft: "8px",
                backgroundColor: "#173D7A",
                color: "white",
                width: "50%",
              }}
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutCard = () => {
  return (
    <div className="mt-6 mb-6 shadow-md p-6">
      <p className="text-3xl mb-2">About the University</p>
      <p>
        Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis
        molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
        fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
        elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
        lectus. Class aptent taciti sociosqu ad litora torquent per conubia
        nostra, per inceptos himenaeos. Praesent auctor purus luctus enim
        egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
        ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi
        convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
      </p>
    </div>
  );
};

const Placement = () => {
  const Card = () => {
    return (
      <div
        style={{
          width: "20%",
          backgroundColor: "red",

          aspectRatio: "1/1",
        }}
      ></div>
    );
  };

  return (
    <div className="mb-6 mt-6">
      <p className="text-3xl mb-2 text-center">Placement</p>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
};

const University = () => {
  return (
    <div>
      <div className="m-auto w-full max-w-6xl">
        <UniversityCard />
        <AboutCard />
        <CareerDomain2 />
        <Placement />
      </div>
    </div>
  );
};

export default University;
