import Header from "../../02_molecules/Header";
import DropDown from "../../01_atoms/DropDown";
import InputBox from "../../01_atoms/InputBox";
import styles from "./index.module.css";

const AppHeader = ({ details, setDetails }) => {
  return (
    <div className={styles.VerContainer}>
      <Header />
      <div className={styles.flex}>
        <div className={styles.width50}>
          <InputBox
            text={"Name"}
            mark
            placeholderText={"Enter name"}
            onChange={(e) =>
              setDetails({
                ...details,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.width50}>
          <InputBox
            text={"Contact number (Kindly add your whatsapp number)"}
            mark
            placeholderText={"Enter mobile number"}
            type={"number"}
            onChange={(e) =>
              setDetails({
                ...details,
                contact: e.target.value,
              })
            }
          />
        </div>
      </div>
      <DropDown
        text={"Who are you?"}
        options={["student", "faculty", "parent", "others"]}
        onSelect={(e) =>
          setDetails({ ...details, role: e.target.value, details: {} })
        }
      />
    </div>
  );
};

export default AppHeader;
