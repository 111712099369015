import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import Header from '../Header';
import InputBox from '../../01_atoms/InputBox';
import Button1 from '../../01_atoms/button1';
import { useContext, useState } from 'react';
import MainContext from '../../helper/context/MainContext';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const Verification = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { loginAPI, setUser, setLoading } = useContext(MainContext);

  let [searchParams] = useSearchParams();

  const utm_source = searchParams.get('utm_source');
  const utm_content = searchParams.get('utm_content');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_GCLID = searchParams.get('utm_GCLID');
  const utm_FBCID = searchParams.get('utm_FBCID');

  const utmApiCall = async () => {
    try {
      const response = await axios
        .post('https://backend.careerbeam.in/api/utmInfo/create', {
          utm_source,
          utm_content,
          utm_medium,
          utm_campaign,
          utm_GCLID,
          utm_FBCID,
          pageName: 'login'
        })
        .then((res) => {
          setIsSubmitting(false);
        });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await loginAPI({
      email,
      utm_source,
      utm_content,
      utm_medium,
      utm_campaign,
      utm_GCLID,
      utm_FBCID
    });
    setLoading(false);
    if (!res.success) {
      alert('Error in sending message!');
      return;
    }

    if (utm_source) {
      utmApiCall();
    }
    setUser({ email, verified: false });
    navigate('/verify');
  };

  return (
    <div className={styles.VerContainer}>
      <Header />
      <InputBox
        text={'Email ID'}
        email={''}
        placeholderText={'Enter Email ID'}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button1 text={'Send OTP'} onClick={onSubmit} />
    </div>
  );
};

export default Verification;
