import { useContext, useState } from 'react';
import InputBox from '../../01_atoms/InputBox';
import Button1 from '../../01_atoms/button1';
import Header from '../../02_molecules/Header';
import styles from './index.module.css';
import MainContext from '../../helper/context/MainContext';
import { useNavigate } from 'react-router-dom';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const { verifyOtpAPI, loginAPI, user, setUser, setLoading } =
    useContext(MainContext);

  const onClick = async () => {
    setLoading(true);
    const res = await verifyOtpAPI({ email: user.email, otp });
    setLoading(false);
    if (!res.success) {
      alert('Error in sending message!');
      return;
    }

    setUser({ email: user.email, verified: true });

    localStorage.setItem('user', res.token);
    navigate('/appForm');
  };

  const resendOtp = async () => {
    setLoading(true);
    const res = await loginAPI({ email: user.email });
    setLoading(false);
    if (!res.success) {
      alert('Error in sending message!');
      return;
    }

    alert('OTP sent successfully');
  };

  return (
    <div className={styles.VerContainer}>
      <Header />
      <InputBox
        text={'The OTP has been sent to '}
        email={user?.email}
        placeholderText={'Enter OTP'}
        onChange={(e) => setOtp(e.target.value)}
      />
      <Button1 text={'Verify'} onClick={onClick} />
      <p
        style={{
          color: '#173D7A',
          fontSize: '1rem',
          cursor: 'pointer',
          marginTop: '-1rem'
        }}
      >
        <span onClick={resendOtp}>Resend OTP</span>
        <span style={{ color: 'black' }}> or </span>
        <span
          onClick={() => {
            navigate('/event-registration');
          }}
        >
          Change Email ID
        </span>
      </p>
    </div>
  );
};

export default OtpVerification;
