import React from 'react';
import one from '../Institution logo/1.png';
import two from '../Institution logo/2.png';
import three from '../Institution logo/3.png';
import four from '../Institution logo/4.png';
import five from '../Institution logo/5.png';
import six from '../Institution logo/6.png';
import seven from '../Institution logo/7.png';
import eight from '../Institution logo/8.png';
import nine from '../Institution logo/9.png';
import ten from '../Institution logo/10.png';
import elevan from '../Institution logo/11.png';
import twelve from '../Institution logo/12.png';
import thirteen from '../Institution logo/Picture13.png';
import fourteen from '../Institution logo/iiii.png';
import fifteen from '../Institution logo/p.png';
import sixteen from '../Institution logo/Picture16.png';
import seventeen from '../Institution logo/Picture17.png';
import eighteen from '../Institution logo/Picture18.png';
import nineteen from '../Institution logo/Picture20.png';
import twenty from '../Institution logo/Picture21.png';
import twentyone from '../Institution logo/Picture22.png';
import twentytwo from '../Institution logo/Picture23.png';
import twentythree from '../Institution logo/Picture24.png';
import twentyfour from '../Institution logo/Picture25.png';
import twentyfive from '../Institution logo/Picture26.png';
import twentysix from '../Institution logo/Picture27.png';
import twentySeven from '../Institution logo/Picture28.png';
import twentyNine from '../Institution logo/Picture32.png';
import thirty from '../Institution logo/Picture33.png';
// import thirtyone from "../Institution logo/Picture35.png";
import thirtytwo from '../Institution logo/Picture36.png';
import thirtythree from '../Institution logo/Picture38.png';

import {
  coloradoUniversity,
  lehighU,
  amityUniversity,
  vU,
  atlas,
  kirloskar,
  indiana,
  rit,
  whistling,
  mit,
  universityLiving,
  bennett,
  worcester,
  junaita,
  rameshSippyAcademy,
  strate,
  britts,
  aiUniversal,
  pearlAcademy,
  texilaMaerican,
  mbaEsg,
  flameLogo,
  mahindraUni,
  pcuLogo,
  shieldLogo,
  eduFund,
  ametLogo,
  mkes,
  sanjayUni,
  trinity,
  jagranUni,
  nuNiit,
  unitedWorld,
  hetic,
  ecole,
  karnavati,
  plaksha,
  idp,
  mAdSchool,
  igiLogo,
  uclLogo,
  iismLogo,
  lMTSM,
  nifd,
  mET,
  bSS,
  tolani,
  gICED,
  krea,
  vijaybhoomi,
  atlasSkillTech,
  raffles,
  brds,
  nsam
} from '../NewInstitutionLogos';

import { useState, useEffect, useRef } from 'react';

import './LogoSlider.css';
import { Link } from 'react-router-dom';

const HandleSlide = () => {
  const logos = [
    coloradoUniversity,
    lehighU,
    amityUniversity,
    vU,
    atlas,
    kirloskar,
    indiana,
    rit,
    whistling,
    mit,
    universityLiving,
    bennett,
    worcester,
    junaita,
    rameshSippyAcademy,
    strate,
    britts,
    aiUniversal,
    pearlAcademy,
    texilaMaerican,
    mbaEsg,
    flameLogo,
    mahindraUni,
    pcuLogo,
    shieldLogo,
    eduFund,
    ametLogo,
    mkes,
    sanjayUni,
    trinity,
    jagranUni,
    nuNiit,
    unitedWorld,
    hetic,
    ecole,
    karnavati,
    plaksha,
    idp,
    mAdSchool,
    igiLogo,
    uclLogo,
    iismLogo,
    lMTSM,
    nifd,
    mET,
    bSS,
    tolani,
    gICED,
    krea,
    vijaybhoomi,
    atlasSkillTech,
    raffles,
    brds,
    nsam
  ];

  const numLogosShown = 6;

  const [position, setPosition] = useState(0);
  const [transition, setTransition] = useState(false);
  const filmstripRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTransition(true);
      setPosition((position + 1) % logos.length);
    }, 1500);

    return () => {
      clearInterval(intervalId);
    };
  }, [position, logos.length]);

  useEffect(() => {
    if (filmstripRef.current) {
      const handleTransitionEnd = () => {
        setTransition(false);
      };

      filmstripRef.current.addEventListener(
        'transitionend',
        handleTransitionEnd
      );
    }
  }, []);

  const duplicateLogos = [...logos, ...logos, ...logos];

  return (
    <div class="pb-20 pt-8">
      <div class="flex flex-col w-full p-6 mt-4 text-center">
        <span class="colab">
          The esteemed institutes we have collaborated with
        </span>
      </div>
      <div className="filmstrip-container">
        <div
          className="filmstrip"
          ref={filmstripRef}
          style={{
            transform: `translateX(-${position * (100 / logos.length)}%)`,
            transition: transition ? 'transform 1s ease-out' : 'none'
            // width: `${(100 / logos.length) * duplicateLogos.length}%`
          }}
        >
          {duplicateLogos.map((logo, index) => (
            <img key={index} src={logo} alt={logo} className="logo" />
          ))}
        </div>
      </div>
      <div className="button-section">
        <span className="btn-spn mt-10 subhead">Want to partner with us?</span>
        <Link
          to="/contact-us"
          fdprocessedid="3nm9dv"
          className="contact-button"
        >
          Get In Touch
        </Link>
      </div>
      <style jsx>{`
        .filmstrip-container {
          max-width: 100%;
          overflow: hidden;
          position: relative;
        }

        .filmstrip {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;
          width:0vh
          transition: transform 3s ease-out;
        }

        .logo {
          display: block;
          margin: 0;
          height: 95px;
          width: auto;
          max-width: 100%;
        }

        .logo.active {
          opacity: 1;
        }

        @media screen and (min-width: 640px) {
          .filmstrip {
            justify-content: center;
          }
          .contact-button {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            /* identical to box height */

            text-align: center;

            color: #ffffff;
          }

          .logo {
            height: 95px;
            margin: 0 5px;
            overflow: hidden;
          }
          .subhead {
            font-family: "Rubik";
            /* font-style: italic; */
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
          }
        }

        @media screen and (min-width: 768px) {
          .logo {
            height: 230px;
          }
        }

        @media (max-width: 768px) {
          .logo {
            height: 140px;
            width: auto;
            margin: 0px 0px;
            .subhead {
              font-family: "Rubik";
              /* font-style: italic; */
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
            }
            /* width: 100%; */
          }
          .contact-button {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */

            text-align: center;

            color: #ffffff;
          }
        }
      `}</style>
    </div>
  );
};

export default HandleSlide;
