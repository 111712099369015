import React from 'react';
import Modal from '../assets/modal1.png';
import styled from 'styled-components';

const ModalComponent = () => {
  return (
    <Papa>
      <ModalContainer className="animate">
        <FirstDiv>
          <h1>What Is CareerBeam?</h1>
          <p>
            CareerBeam is a next-generation Career guidance platform for young
            aspirants. It takes them on a transformative journey where they are
            introduced to careers and their scope in a rapidly changing world.
            Our well-curated career-oriented events aim at helping them discover
            career opportunities and unlock forward-looking insights. Our focus
            is to make
            <span className="font-semibold">
              career opportunities highly Accessible to young enthusiasts across
              India.
            </span>
          </p>
        </FirstDiv>
        <img
          src={
            'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/What+is+CB+Section_445x390.jpg'
          }
          alt=""
        />
        <SecondDiv>
          <p>
            <span className="font-semibold">
              One thing that makes CareerBeam stand out?
            </span>
            Its strong networking advantage. We have a large network and
            influence among the student population of India. While our network
            and events continue to evolve, we work with a single aim to make the
            students of schools and colleges Career-focused!
          </p>
        </SecondDiv>
      </ModalContainer>
      <ModalContainerMobile>
        <FirstDivMobile>
          <h1>What is Careerbeam?</h1>
          <Para>
            <p>
              CareerBeam is a next-generation Career guidance platform for young
              aspirants. It takes them on a transformative journey where they
              are introduced to careers and their scope in a rapidly changing
              world. Our well-curated career-oriented events aim at helping them
              discover career opportunities and unlock forward-looking insights.
              Our focus is to make{' '}
              <span className="font-semibold">
                Career Opportunities Highly Accessible to young enthusiasts
                across India.
              </span>
            </p>
          </Para>
        </FirstDivMobile>
        <SecondDivMobile>
          <ParaTwo>
            <p>
              <span className="font-semibold">
                One thing that makes CareerBeam stand out?
              </span>
              Its strong networking advantage. We have a large network and
              influence among the student population of India. While our network
              and events continue to evolve, we work with a single aim to make
              the students of schools and colleges Career-focused!
            </p>
          </ParaTwo>
        </SecondDivMobile>
      </ModalContainerMobile>
    </Papa>
  );
};

export default ModalComponent;

const ModalContainerMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }
  @media (max-width: 786px) {
    /* position: relative; */
    /* top: 6rem; */
    /* padding-top: 6rem; */
  }
`;

const Papa = styled.div`
  @media (max-width: 786px) {
    position: relative;
    top: 4.5rem;
    padding-bottom: 2rem;
  }
`;

const Para = styled.div`
  width: 343px;
  margin: auto;
`;

const ParaTwo = styled.div`
  width: 343px;
  margin: auto;
`;

const FirstDivMobile = styled.div`
  @media (max-width: 768px) {
    /* height: 230px; */
    margin-top: 24rem;
    position: relative;
    h1 {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      margin: auto;
      margin-top: 24px;
      position: relative;
      line-height: 33px;
      display: flex;
      justify-content: center;
      color: #062229;
    }
    p {
      height: 207px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      margin-top: 28px;
      text-align: center;
      color: #062229;
    }
  }
`;

const SecondDivMobile = styled.div`
  @media (max-width: 768px) {
    width: auto;
    height: 210px;
    background: #0378c6;
    margin-top: 2rem;
    p {
      margin: auto;
      padding-top: 1rem;
      margin: 4px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      /* or 186% */
      height: 182px;
      text-align: center;

      color: #ffffff;
    }
  }
`;

const ModalContainer = styled.div`
  @media (max-width: 786px) {
    display: none;
  }

  position: relative;
  img {
    position: absolute;
    width: 443px;
    height: 390px;
    bottom: 6.5rem;
    z-index: 1;
    /* top: 64rem; */
    left: 174px;
  }
  h1 {
    position: relative;
    left: 43.6rem;
    top: 8rem;
    width: 363px;
    height: 36px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
  }
`;
const FirstDiv = styled.div`
  position: relative;
  height: 444px;
  background-color: #f3f6fb;
  p {
    position: absolute;
    left: 43.1rem;
    top: 12.5rem;
    width: 614px;
    height: 240px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
`;

const SecondDiv = styled.div`
  position: relative;
  height: 214px;
  background: #0378c6;

  p {
    position: absolute;
    left: 43rem;
    top: 1.8rem;
    width: 616px;

    height: 150px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: white;
  }
`;
