import React, { useState } from "react";
import styled from "styled-components";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import Seema from "../assets/Sima-1.png";
import Ishika from "../assets/Group-226.png";
import Gouri from "../assets/Mask-group-1.png";

const TestimonialContainer = styled.div`
  /* width: 100%; */
  /* max-width: 600px; */

  @media (min-width: 768px) {
    display: none;
  }

  /* margin: 0 auto; */
  display: flex;
  text-align: center;
  margin-top: 48px;
  /* margin-bottom: 12rem; */
  margin-bottom: 32px;
  /* overflow-x: hidden; */
  touch-action: pan-y;
`;

const Cards = styled.div`
  width: 303px;
  margin: auto;
  height: 357px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
  border-radius: 8px;

  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #062229;
  }
  img {
    margin: auto;
    position: relative;
    bottom: 2rem;
    width: 72px;
    height: 72px;
  }

  svg {
    margin: auto;
    position: relative;
    bottom: 10px;
  }
`;

const TestimonialText = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  /* or 164% */
  margin: auto;
  width: 280px;
  height: 138px;
  margin-top: 16px;
  text-align: center;

  color: #062229;
`;

const Name = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 24px;
  color: #062229;
`;

const TestimonialAuthor = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding-top: 4px;
  margin: auto;
  color: #062229;
`;

// const TestimonialIcon = styled.span`
//   margin: auto;
// `;

const TestimonialDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;
  position: relative;
`;

const TestimonialDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "black" : "lightgray")};
  margin: 0 0.5rem;
  cursor: pointer;
`;

const testimonials = [
  {
    id: 2,
    quote: "Principal",
    text: "My experience with CareerBeam has been seamless. I've been conducting webinars and seminars on different career-related topics. This has helped me expand my student network across India as well.",
    name: "Ms Seema Negi",
    company: "Sanjeevani World Shool",
    image: Seema,
  },
  {
    id: 2,
    quote: "Student",
    text: "CareerBeam is my go-to career guidance platform. All the career-related events are insightful and give me good clarity about further studies. Currently, I'm pursuing my MBA degree at one of the top universities in the UK. All thanks to CareerBeam.					",
    name: "Ms Ishika Shetty",
    company: "S M Shetty Collage - Mumbai",
    image: Ishika,
  },
  {
    id: 3,
    quote: "Parent",
    text: "From career counselling to scholarship programs, all sessions are planned very thoughtfully, which give parents a crystal clear picture of how the university will change the life of students. It has made the lives of students and parents tension-free.",
    name: "Mrs. Gouri Sawnat",
    image: Gouri,
    company: "Mumbai",
  },
];

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <TestimonialContainer>
      <Cards>
        <img src={testimonials[activeIndex].image} alt="" />
        <svg
          width="39"
          height="29"
          viewBox="0 0 39 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7746 21.9923C15.7746 23.9113 15.0837 25.5617 13.702 26.9434C12.397 28.2484 10.7467 28.9009 8.75086 28.9009C5.98743 28.9009 3.8381 27.9413 2.30286 26.0223C0.767619 24.0265 0 21.7236 0 19.1137C0 15.1221 1.18981 11.5143 3.56943 8.29029C5.94905 4.98952 8.6741 2.22609 11.7446 0L15.6594 3.91486C15.0453 4.60571 14.2777 5.41171 13.3566 6.33286C12.4354 7.17724 11.6294 8.09838 10.9386 9.09629C10.3245 10.0942 10.0174 11.0921 10.0174 12.09C10.0174 13.0879 10.478 13.9707 11.3991 14.7383C12.397 15.5059 13.3566 16.427 14.2777 17.5017C15.2756 18.5764 15.7746 20.0732 15.7746 21.9923ZM38.2274 21.9923C38.2274 23.9113 37.5366 25.5617 36.1549 26.9434C34.8499 28.2484 33.1995 28.9009 31.2037 28.9009C28.4403 28.9009 26.291 27.9413 24.7557 26.0223C23.2205 24.0265 22.4529 21.7236 22.4529 19.1137C22.4529 15.1221 23.6427 11.5143 26.0223 8.29029C28.4019 4.98952 31.127 2.22609 34.1974 0L38.1123 3.91486C37.4982 4.60571 36.7306 5.41171 35.8094 6.33286C34.8883 7.17724 34.0823 8.09838 33.3914 9.09629C32.7773 10.0942 32.4703 11.0921 32.4703 12.09C32.4703 13.0879 32.9309 13.9707 33.852 14.7383C34.8499 15.5059 35.8094 16.427 36.7306 17.5017C37.7285 18.5764 38.2274 20.0732 38.2274 21.9923Z"
            fill="#0070C0"
          />
        </svg>
        <TestimonialText>{testimonials[activeIndex].text}</TestimonialText>
        <Name>{testimonials[activeIndex].name}</Name>
        <TestimonialAuthor>{testimonials[activeIndex].quote}</TestimonialAuthor>
        <span>{testimonials[activeIndex].company}</span>
        <TestimonialDotsContainer>
          {testimonials.map((testimonial, index) => (
            <TestimonialDot
              key={index}
              active={index === activeIndex}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </TestimonialDotsContainer>
      </Cards>
    </TestimonialContainer>
  );
};

export default TestimonialCarousel;
