import Imageslider from './Imageslider';
// import image1 from "../assets/banner1.png";
// import image2 from '../assets/festivalBanner.png';
// import image3 from "../assets/banner3.png";
import { image2, banner1, banner3 } from '../images';

const MyComponent = () => {
  const images = [
    // image2,
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/new-banners/Banner-1.png',
    'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/new-banners/Banner-2.png'
  ];
  return (
    <>
      <Imageslider images={images} className />
    </>
  );
};

export default MyComponent;
