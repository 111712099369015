export const coloradoUniversity =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Colorado-State-University-Logo-1957.svg';

export const lehighU =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/LehighU_official-logo_Color-72dpi.svg';

export const amityUniversity =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/amity-univesity.svg';

export const vU =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/VU-logo_high-Res.svg';

export const atlas =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Atlas-logo.svg';

export const kirloskar =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/kirloskar-institute-logo.svg';

export const indiana =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/indiana-uni-logo.svg';

export const rit =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/RIT-logo.svg';

export const whistling =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/whistling-logo.svg';

export const mit =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/MIT-WPU_LOGO-02.svg';

export const universityLiving =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/University-Living.svg';

export const bennett =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/bennett-uni-logo.svg';

export const worcester =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Worcester-Polytechnic-Institute.svg';

export const junaita =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Junaita-logo.svg';

export const rameshSippyAcademy =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Ramesh-Sippy-Academy-of-Cinema-and-Entertainment.svg';

export const strate =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Strate.svg';

export const britts =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Britts.svg';

export const aiUniversal =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/ai-universal-uni-logo.svg';

export const pearlAcademy =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/pearl-academy-logo.svg';

export const texilaMaerican =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/texila-maerican-uni-logo.svg';

export const mbaEsg =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/mba-esg-logo.svg';

export const flameLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/FLAME-LOGO-COREL-logo.svg';

export const mahindraUni =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/mahindra-uni-logo.svg';

export const pcuLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/PCU-Logo.svg';

export const shieldLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/shield-logo.svg';

export const eduFund =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/EduFund-logo.svg';

export const ametLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/AMET-LOGO.svg';

export const mkes =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/MKES-IMSR-logo.svg';

export const sanjayUni =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/sanjay-uni-logo.svg';

export const trinity =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/trinity.svg';

export const jagranUni =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/jagran-uni-logo.svg';

export const nuNiit =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/nu-niit-uni-logo.svg';

export const unitedWorld =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/united-world-logo.svg';

export const hetic =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/hetic-logo.svg';

export const ecole =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/ecole-logo.svg';

export const karnavati =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/karnavati-logo.svg';

export const plaksha =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/plaksha-logo.svg';

export const idp =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/idp-logo.svg';

export const mAdSchool =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/m-ad-school.svg';

export const igiLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/igi-logo.svg';

export const uclLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/ucl-logo.svg';

export const iismLogo =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/iism-logo.svg';

export const lMTSM =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/LMTSM-Logo.svg';

export const nifd =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/nifd-logo.svg';

export const mET =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/MET-Logo.svg';

export const bSS =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/BSS-logo.svg';

export const tolani =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Tolani-logo.svg';

export const gICED =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/GICED-MAIN-LOGO.svg';

export const krea =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Krea-logo.svg';

export const vijaybhoomi =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Vijaybhoomi_University_Logo.svg';

export const atlasSkillTech =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/atlas-skillTech.svg';

export const raffles =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/Raffles-Logo.svg';

export const brds =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/BRDS-LOGO.svg';

export const nsam =
  'https://careerbeam-assets.s3.eu-north-1.amazonaws.com/client-logo/NSAM-LOGO.svg';
