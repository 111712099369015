import React from 'react';
import { GoLocation } from 'react-icons/go';
import '../styles/Footer.css';
import { IoCallOutline } from 'react-icons/io5';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NewFooter from './NewFooter';
import { Divider } from 'antd';
import { homeRoute } from '../utils';

export const Footer = ({ handlesubmit, coursesRef }) => {
  const parsedData = window.location.pathname;

  return (
    <>
      <div className="footer forDesktop">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid row-gap-10 mb-8 lg:grid-cols-9">
            <div className="grid lg:col-span-4 ">
              <div>
                <p className="about-company-head">About Company</p>
                <ul className="mt-2 space-y-2">
                  <li className="locationsymbol flex p-2 ml-5">
                    <a
                      href="https://goo.gl/maps/s2xhbbDdCvdfvHwL8"
                      className="footer-info "
                    >
                      <svg
                        class=" inline relative right-2 "
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.96612 15.919C5.9663 15.9191 5.96643 15.9191 6.2223 15.5558L5.96612 15.919ZM6.47849 15.919L6.48009 15.9178L6.48413 15.915L6.49826 15.9048C6.5104 15.8961 6.52786 15.8835 6.55026 15.8671C6.59506 15.8341 6.65973 15.7859 6.74147 15.7231C6.90485 15.5975 7.13658 15.4133 7.41392 15.1762C7.96797 14.7024 8.70714 14.0148 9.44746 13.1584C10.9174 11.4579 12.4446 9.03101 12.4446 6.25648C12.4446 4.59802 11.7897 3.00693 10.6231 1.8334C9.45635 0.659804 7.87339 0 6.2223 0C4.57122 0 2.98826 0.659804 1.82154 1.8334C0.654942 3.00693 0 4.59802 0 6.25648C0 9.03101 1.52718 11.4579 2.99715 13.1584C3.73747 14.0148 4.47664 14.7024 5.03069 15.1762C5.30803 15.4133 5.53976 15.5975 5.70314 15.7231C5.78488 15.7859 5.84954 15.8341 5.89434 15.8671C5.91675 15.8835 5.93421 15.8961 5.94635 15.9048L5.96048 15.915L5.96452 15.9178L5.96612 15.919C6.11959 16.027 6.32502 16.027 6.47849 15.919ZM6.2223 15.5558L6.47849 15.919C6.47831 15.9191 6.47817 15.9191 6.2223 15.5558ZM8.44456 6.2223C8.44456 7.44961 7.44961 8.44456 6.2223 8.44456C4.995 8.44456 4.00005 7.44961 4.00005 6.2223C4.00005 4.995 4.995 4.00005 6.2223 4.00005C7.44961 4.00005 8.44456 4.995 8.44456 6.2223Z"
                          fill="white"
                          fill-opacity="0.9"
                        />
                      </svg>
                      Vigor LaunchPad, Suit 3, 4th Floor, Samruddhi Venture
                      Park,
                      <br />
                      <span className="ml-5">
                        MIDC Central Rd, Andheri East, Mumbai - 400093
                      </span>
                    </a>
                  </li>
                  <li className="locationsymbol flex p-2 ml-5">
                    <a href="tel:022 4978 2431" className="footer-info">
                      <svg
                        class="inline relative right-2 "
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5437 11.3062L12.0436 9.80614C11.8941 9.74241 11.728 9.72899 11.5701 9.76788C11.4123 9.80677 11.2714 9.89587 11.1686 10.0218L9.61861 11.9155C7.18598 10.7686 5.22828 8.81089 4.08132 6.37826L5.9751 4.82824C6.10126 4.72564 6.19054 4.58472 6.22945 4.42684C6.26836 4.26896 6.25477 4.10269 6.19073 3.95322L4.69071 0.453162C4.62043 0.292037 4.49613 0.160484 4.33925 0.0811864C4.18236 0.00188914 4.00273 -0.0201817 3.83132 0.0187795L0.58126 0.768792C0.415998 0.806955 0.26855 0.900007 0.162983 1.03276C0.0574151 1.16551 -3.80697e-05 1.33013 1.8926e-08 1.49974C1.8926e-08 9.51551 6.49699 16 14.5003 16C14.6699 16.0001 14.8346 15.9427 14.9674 15.8371C15.1002 15.7315 15.1933 15.584 15.2315 15.4187L15.9815 12.1687C16.0202 11.9964 15.9977 11.8161 15.9178 11.6587C15.8379 11.5012 15.7056 11.3766 15.5437 11.3062Z"
                          fill="white"
                          fill-opacity="0.9"
                        />
                      </svg>
                      7208070768 | 022 4978 2431
                    </a>
                  </li>
                  <li className="locationsymbol flex p-2 ml-5">
                    <a
                      href="mailto:enquiry@vigorlaunchpad.com"
                      className="footer-info"
                    >
                      <svg
                        class="inline relative right-2"
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.23372 0C1.66892 0 0.400391 1.26853 0.400391 2.83333V3.11851L8.90053 7.6955L17.4004 3.11866V2.83333C17.4004 1.26853 16.1319 0 14.5671 0H3.23372Z"
                          fill="white"
                          fill-opacity="0.9"
                        />
                        <path
                          d="M17.4004 4.72768L9.23635 9.12371C9.0267 9.23659 8.77436 9.23659 8.56471 9.12371L0.400391 4.72754V11.3334C0.400391 12.8982 1.66892 14.1667 3.23372 14.1667H14.5671C16.1319 14.1667 17.4004 12.8982 17.4004 11.3334V4.72768Z"
                          fill="white"
                          fill-opacity="0.9"
                        />
                      </svg>
                      enquiry@vigorlaunchpad.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className=" lg:col-span-2">
              <span className="text-base font-normal tracking-wide text-white  about-company">
                Important Links
              </span>
              <ul className="footer-info ml-2 mt-3">
                <a href="https://careerbeam.in/blog/">
                  <li className="my-3 font-semibold">Blog</li>
                </a>
                <Link to="/terms-condition">
                  <li className="my-4 font-semibold">Terms and Condition</li>
                </Link>
                <Link to="/cancellation-refund-policy">
                  <li className="my-4  font-semibold">
                    Cancellation and Refund Policy
                  </li>
                </Link>
                <Link to="/privacy-policy">
                  <li className="my-4 font-semibold">Privacy Policy</li>
                </Link>
                {/* <Link to="/shipping"><li className="my-4 font-semibold">Shipping and Delivery Policy</li></Link> */}
              </ul>
            </div>

            <div className="md:max-w-md lg:col-span-3 px-8">
              <span className="text-base tracking-wide text-white  about-company">
                Let's Connect
              </span>
              <form
                onSubmit={handlesubmit}
                className="flex flex-col mt-4 md:flex-row"
              >
                <input
                  placeholder="Enter your email..."
                  required
                  type="text"
                  className="myInput text-white flex-grow w-full h-12 px-4 mb-3 transition duration-200  border border-blue-800 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                <button
                  type="submit"
                  className="inline-flex items-center  justify-center h-12 px-6 font-normal tracking-wide bg-yellow transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.5545 0L0 11.3576L5.67878 15.6167V25.5545L11.3576 20.5856L18.456 25.5545L25.5545 0ZM21.4951 4.09272L7.09848 22.7151V15.4031L21.4951 4.09272ZM18.3313 4.77484L6.24722 14.2691L2.81444 11.6931L18.3313 4.77484ZM22.5904 5.371L17.6658 23.1033L12.5943 19.3655L22.5904 5.371Z"
                      fill="#142355"
                    />
                  </svg>
                </button>
                <ToastContainer />
              </form>
            </div>
          </div>
          {homeRoute?.includes(parsedData) ? (
            <div>
              <div>
                <Divider style={{ borderColor: '#FFFFFF' }} />
              </div>
              <div
                className="grid lg:col-span-12 "
                style={{ marginLeft: '1.6rem' }}
              >
                <div className="mt-4">
                  <p className="home-detail-footer-title">About Company</p>
                  <p className="home-detail-footer-info">
                    CareerBeam is a next-generation career guidance platform
                    dedicated to empowering young aspirants across India. Our
                    mission is to empower students to explore potential career
                    paths and gain the skills needed for a fulfilling future.
                    The platform guides students with the tools and knowledge to
                    conquer and thrive in today's ever-evolving landscape of
                    opportunities. Through our career-oriented events and
                    experienced counsellors, we offer personalised support. From
                    identifying student interests to understanding job trends,
                    we help them make informed decisions about their career. At
                    CareerBeam, we believe guidance is about finding a student's
                    purpose. Join us on this journey of self-discovery and
                    become well-prepared for all the exciting career
                    opportunities ahead.
                  </p>
                </div>

                <div className="mt-8">
                  <p className="home-detail-footer-title">
                    What Is Career Guidance?
                  </p>
                  <p className="home-detail-footer-info">
                    Career guidance is a student's roadmap to a fulfilling
                    professional journey. It's a process that helps students
                    explore their interests, skills, and values to identify
                    career paths that align with them. It equips them with the
                    knowledge and strategies to help them achieve their
                    long-term career aspirations. While traditional career
                    guidance offers valuable tools, CareerBeam elevates the
                    experience. With CareerBeam, career guidance and counselling
                    transforms into an ongoing journey of exploration and
                    propulsion, ensuring students stay ahead of the curve in the
                    dynamic job market. Our approach is designed to help them
                    navigate their career path with confidence and clarity.
                  </p>
                </div>

                <div className="mt-8">
                  <p className="home-detail-footer-title">
                    Frequently Asked Questions About Careerbeam
                  </p>
                  <div>
                    <p className="home-detail-faq-title">
                      Who can benefit from CareerBeam?
                    </p>
                    <p className="home-detail-faq-info">
                      CareerBeam empowers India's students and young
                      professionals to navigate their careers. Whether they are
                      just starting their educational journey or looking to
                      transition into a new field.
                    </p>
                  </div>
                  <div className="mt-8">
                    <p className="home-detail-faq-title">
                      How often are career events held?
                    </p>
                    <p className="home-detail-faq-info">
                      CareerBeam accelerates career growth with a rich calendar
                      of events planned throughout the year. Explore our website
                      to check out the latest events calendar.
                    </p>
                  </div>

                  <div className="mt-8">
                    <p className="home-detail-faq-title">
                      How do I sign up for CareerBeam events?
                    </p>
                    <p className="home-detail-faq-info">
                      Registering for CareerBeam events is a breeze. Simply
                      visit the "Events Page" on our website, select the event
                      that is intriguing and follow the registration
                      instructions.
                    </p>
                  </div>

                  <div className="mt-8">
                    <p className="home-detail-faq-title">
                      What makes CareerBeam different from other career guidance
                      platforms?
                    </p>
                    <p className="home-detail-faq-info">
                      Stay ahead of the curve! Before the career festival,
                      strategically plan your visit by familiarising yourself
                      with the schedule. Take a look at the lineup in advance
                      and strategize accordingly. This preparation will ensure
                      you're ready on the day to explore the workshops,
                      seminars, and panels that align with and inspire your
                      career goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="footer shadow-4xl  forMobile">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid row-gap-10 mb-8 lg:grid-cols-9">
            <div className="grid lg:col-span-4 ">
              <div>
                <div className="md:max-w-md lg:col-span-2">
                  <span className="text-base font-normal tracking-wide text-white ml-1  about-company">
                    Let's Connect
                  </span>
                  <form className="flex flex-row mt-4 md:flex-row">
                    <input
                      placeholder="Enter your email..."
                      required
                      type="text"
                      className="myInput flex-grow w-48 h-12 px-4 mb-3 transition duration-200 border border-blue-800 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    />
                    <button
                      type="submit"
                      className="bg-yellow w-20 inline-flex items-center justify-center h-12 px-6 font-normal tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.5545 0L0 11.3576L5.67878 15.6167V25.5545L11.3576 20.5856L18.456 25.5545L25.5545 0ZM21.4951 4.09272L7.09848 22.7151V15.4031L21.4951 4.09272ZM18.3313 4.77484L6.24722 14.2691L2.81444 11.6931L18.3313 4.77484ZM22.5904 5.371L17.6658 23.1033L12.5943 19.3655L22.5904 5.371Z"
                          fill="#142355"
                        />
                      </svg>
                    </button>
                  </form>
                </div>

                <p className="font-normal tracking-wide text-white ml-2 mt-4  about-company">
                  About Company
                </p>
                <ul className="mt-2 space-y-2">
                  <li className=" flex p-2">
                    <svg
                      className="mt-1"
                      width="13"
                      height="16"
                      viewBox="0 0 13 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.96612 15.919C5.9663 15.9191 5.96643 15.9191 6.2223 15.5558L5.96612 15.919ZM6.47849 15.919L6.48009 15.9178L6.48413 15.915L6.49826 15.9048C6.5104 15.8961 6.52786 15.8835 6.55026 15.8671C6.59506 15.8341 6.65973 15.7859 6.74147 15.7231C6.90485 15.5975 7.13658 15.4133 7.41392 15.1762C7.96797 14.7024 8.70714 14.0148 9.44746 13.1584C10.9174 11.4579 12.4446 9.03101 12.4446 6.25648C12.4446 4.59802 11.7897 3.00693 10.6231 1.8334C9.45635 0.659804 7.87339 0 6.2223 0C4.57122 0 2.98826 0.659804 1.82154 1.8334C0.654942 3.00693 0 4.59802 0 6.25648C0 9.03101 1.52718 11.4579 2.99715 13.1584C3.73747 14.0148 4.47664 14.7024 5.03069 15.1762C5.30803 15.4133 5.53976 15.5975 5.70314 15.7231C5.78488 15.7859 5.84954 15.8341 5.89434 15.8671C5.91675 15.8835 5.93421 15.8961 5.94635 15.9048L5.96048 15.915L5.96452 15.9178L5.96612 15.919C6.11959 16.027 6.32502 16.027 6.47849 15.919ZM6.2223 15.5558L6.47849 15.919C6.47831 15.9191 6.47817 15.9191 6.2223 15.5558ZM8.44456 6.2223C8.44456 7.44961 7.44961 8.44456 6.2223 8.44456C4.995 8.44456 4.00005 7.44961 4.00005 6.2223C4.00005 4.995 4.995 4.00005 6.2223 4.00005C7.44961 4.00005 8.44456 4.995 8.44456 6.2223Z"
                        fill="white"
                        fill-opacity="0.9"
                      />
                    </svg>
                    <a
                      href="https://goo.gl/maps/s2xhbbDdCvdfvHwL8"
                      className="text-white transition-colors duration-300 hover:text-deep-purple-accent-200 font-normal info ml-2"
                    >
                      Vigor LaunchPad, Suit 3, 4th Floor, Samruddhi Venture
                      Park,
                      <br />
                      MIDC Central Rd, Andheri East, Mumbai - 400093
                    </a>
                  </li>
                  <li className="locationsymbol flex p-2">
                    <svg
                      width="16"
                      height="16"
                      className="mt-1"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5437 11.3062L12.0436 9.80614C11.8941 9.74241 11.728 9.72899 11.5701 9.76788C11.4123 9.80677 11.2714 9.89587 11.1686 10.0218L9.61861 11.9155C7.18598 10.7686 5.22828 8.81089 4.08132 6.37826L5.9751 4.82824C6.10126 4.72564 6.19054 4.58472 6.22945 4.42684C6.26836 4.26896 6.25477 4.10269 6.19073 3.95322L4.69071 0.453162C4.62043 0.292037 4.49613 0.160484 4.33925 0.0811864C4.18236 0.00188914 4.00273 -0.0201817 3.83132 0.0187795L0.58126 0.768792C0.415998 0.806955 0.26855 0.900007 0.162983 1.03276C0.0574151 1.16551 -3.80697e-05 1.33013 1.8926e-08 1.49974C1.8926e-08 9.51551 6.49699 16 14.5003 16C14.6699 16.0001 14.8346 15.9427 14.9674 15.8371C15.1002 15.7315 15.1933 15.584 15.2315 15.4187L15.9815 12.1687C16.0202 11.9964 15.9977 11.8161 15.9178 11.6587C15.8379 11.5012 15.7056 11.3766 15.5437 11.3062Z"
                        fill="white"
                        fill-opacity="0.9"
                      />
                    </svg>

                    <a
                      href="tel:022 4978 2431"
                      className="text-white transition-colors duration-300 hover:text-deep-purple-accent-200 font-normal info ml-2"
                    >
                      {' '}
                      7208070768 | 022 4978 2431
                    </a>
                  </li>
                  <li className="locationsymbol flex p-2">
                    <svg
                      className="mt-1"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.23372 0C1.66892 0 0.400391 1.26853 0.400391 2.83333V3.11851L8.90053 7.6955L17.4004 3.11866V2.83333C17.4004 1.26853 16.1319 0 14.5671 0H3.23372Z"
                        fill="white"
                        fill-opacity="0.9"
                      />
                      <path
                        d="M17.4004 4.72768L9.23635 9.12371C9.0267 9.23659 8.77436 9.23659 8.56471 9.12371L0.400391 4.72754V11.3334C0.400391 12.8982 1.66892 14.1667 3.23372 14.1667H14.5671C16.1319 14.1667 17.4004 12.8982 17.4004 11.3334V4.72768Z"
                        fill="white"
                        fill-opacity="0.9"
                      />
                    </svg>

                    <a
                      href="mailto:enquiry@vigorlaunchpad.com"
                      className="text-white transition-colors duration-300 hover:text-deep-purple-accent-200 font-normal info ml-2"
                    >
                      enquiry@vigorlaunchpad.com
                    </a>
                  </li>
                </ul>
              </div>

              <div className=" lg:col-span-2 text-start mx-4">
                <br />
                <span className="text-base font-normal tracking-wide text-white  about-company">
                  Important Links
                </span>
                <ul className="text-base info text-white ml-2">
                  <a href="https://careerbeam.in/blog/">
                    <li className="my-3 font-semibold">Blog</li>
                  </a>
                  <Link to="/terms-condition">
                    <li className="my-3 font-semibold">Terms and Condition</li>
                  </Link>
                  <Link to="/cancellation-refund-policy">
                    <li className="my-3  font-semibold">
                      Cancellation and Refund Policy
                    </li>
                  </Link>
                  <Link to="/privacy-policy">
                    <li className="my-3 font-semibold">Privacy Policy</li>
                  </Link>
                  {/* <Link to="/shipping"><li className="my-3 font-semibold">Shipping and Delivery Policy</li></Link> */}
                </ul>
              </div>

              {homeRoute?.includes(parsedData) ? (
                <div>
                  <div>
                    <Divider style={{ borderColor: '#FFFFFF' }} />
                  </div>
                  <div className="grid lg:col-span-12 ml-1">
                    <div className="mt-2">
                      <p className="home-detail-footer-title">About Company</p>
                      <p className="home-detail-footer-info">
                        CareerBeam is a next-generation career guidance platform
                        dedicated to empowering young aspirants across India.
                        Our mission is to empower students to explore potential
                        career paths and gain the skills needed for a fulfilling
                        future. The platform guides students with the tools and
                        knowledge to conquer and thrive in today's ever-evolving
                        landscape of opportunities. Through our career-oriented
                        events and experienced counsellors, we offer
                        personalised support. From identifying student interests
                        to understanding job trends, we help them make informed
                        decisions about their career. At CareerBeam, we believe
                        guidance is about finding a student's purpose. Join us
                        on this journey of self-discovery and become
                        well-prepared for all the exciting career opportunities
                        ahead.
                      </p>
                    </div>

                    <div className="mt-8">
                      <p className="home-detail-footer-title">
                        What Is Career Guidance?
                      </p>
                      <p className="home-detail-footer-info">
                        Career guidance is a student's roadmap to a fulfilling
                        professional journey. It's a process that helps students
                        explore their interests, skills, and values to identify
                        career paths that align with them. It equips them with
                        the knowledge and strategies to help them achieve their
                        long-term career aspirations. While traditional career
                        guidance offers valuable tools, CareerBeam elevates the
                        experience. With CareerBeam, career guidance and
                        counselling transforms into an ongoing journey of
                        exploration and propulsion, ensuring students stay ahead
                        of the curve in the dynamic job market. Our approach is
                        designed to help them navigate their career path with
                        confidence and clarity.
                      </p>
                    </div>

                    <div className="mt-8">
                      <p className="home-detail-footer-title">
                        Frequently Asked Questions About Careerbeam
                      </p>
                      <div>
                        <p className="home-detail-faq-title">
                          Who can benefit from CareerBeam?
                        </p>
                        <p className="home-detail-faq-info">
                          CareerBeam empowers India's students and young
                          professionals to navigate their careers. Whether they
                          are just starting their educational journey or looking
                          to transition into a new field.
                        </p>
                      </div>
                      <div className="mt-8">
                        <p className="home-detail-faq-title">
                          How often are career events held?
                        </p>
                        <p className="home-detail-faq-info">
                          CareerBeam accelerates career growth with a rich
                          calendar of events planned throughout the year.
                          Explore our website to check out the latest events
                          calendar.
                        </p>
                      </div>

                      <div className="mt-8">
                        <p className="home-detail-faq-title">
                          How do I sign up for CareerBeam events?
                        </p>
                        <p className="home-detail-faq-info">
                          Registering for CareerBeam events is a breeze. Simply
                          visit the "Events Page" on our website, select the
                          event that is intriguing and follow the registration
                          instructions.
                        </p>
                      </div>

                      <div className="mt-8">
                        <p className="home-detail-faq-title">
                          What makes CareerBeam different from other career
                          guidance platforms?
                        </p>
                        <p className="home-detail-faq-info">
                          Stay ahead of the curve! Before the career festival,
                          strategically plan your visit by familiarising
                          yourself with the schedule. Take a look at the lineup
                          in advance and strategize accordingly. This
                          preparation will ensure you're ready on the day to
                          explore the workshops, seminars, and panels that align
                          with and inspire your career goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};
