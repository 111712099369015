import React from "react";
import styled from "styled-components";
// import Eventback from "../assets/event-background.png";
import mum from "../assets/mum.png";
import ban from "../assets/ban.png";
import del from "../assets/del.png";
import mobileevent from "../assets/eventbackmobile.png";
import { Link } from "react-router-dom";
import { eventBackground } from "../images";

const EventScreen = () => {
  return (
    <>
      <BigComponent>
        <h1 className="event-details">Event Details</h1>
        <EventComponent>
          <EventBox>
            <EventLocation>
              <img src={mum} alt="" />
              <h1>MUMBAI</h1>
              <p>May 13th & 14th</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </EventLocation>
            <EventLocationCenter>
              <img src={ban} alt="" />
              <h1>BANGLORE</h1>
              <p>May 20th & 21st</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </EventLocationCenter>
            <EventLocationRight>
              <img src={del} alt="" />
              <h1>DELHI</h1>
              <p>May 27th & 28th</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </EventLocationRight>
          </EventBox>
        </EventComponent>

        <Link className="register-button" to="/careerbeam-form">
          Register Now{" "}
        </Link>
      </BigComponent>

      <SmallComponent>
        <h1 className="event-details">Event Details</h1>

        <EvenetComponentMobile>
          {/* <h1 className="event-details">Event Details</h1> */}

          <SubBox>
            <Location>
              <img src={mum} alt="" />
              <h1>MUMBAI</h1>
              <p>May 13th & 14th</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </Location>
            <SecondLocation>
              {" "}
              <img src={ban} alt="" />
              <h1>BANGLORE</h1>
              <p>May 20th & 21st</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </SecondLocation>
            <ThirdLocation>
              <img src={del} alt="" />
              <h1>DELHI</h1>
              <p>May 27th & 28th</p>
              <Link to="/careerbeam-form">Register Now {">"} </Link>
            </ThirdLocation>
          </SubBox>
        </EvenetComponentMobile>
        <Link className="register-button-mob" to="careerbeam-form">
          Register Now
        </Link>
      </SmallComponent>
    </>
  );
};

export default EventScreen;

const SmallComponent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  background-image: url(${mobileevent});

  .event-details {
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 114% */
    position: relative;
    top: 4.5rem;
    text-align: center;

    color: #ffffff;
  }
  .register-button-mob {
    width: 293px;
    height: 45px;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #1971b6;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 3.8rem;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
`;

const BigComponent = styled.div`
  @media (max-width: 786px) {
    display: none;
  }
  background-image: url(${eventBackground});

  .event-details {
    font-family: "DIN";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    position: relative;
    top: 12rem;
  }
  .register-button {
    width: 169px;
    height: 47px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    position: relative;
    bottom: 12.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    color: #1971b6;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
`;

const EvenetComponentMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }
  background-image: url(${mobileevent});

  height: 828px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

const SubBox = styled.div`
  width: 343px;
  height: 840px;
  background: #005898;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Location = styled.div`
  width: 295px;
  height: 219px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px 12px 0px 0px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  h1 {
    margin: auto;
    margin-top: 13px;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #ffffff;
  }
  p {
    /* margin-top: 8px; */
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */

    text-align: center;

    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */
    margin-top: 12px;
    text-align: center;

    color: #fddd14;
  }

  img {
    margin: auto;
    margin-top: 16px;
  }
`;

const SecondLocation = styled.div`
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);

  width: 295px;
  height: 219px;
  h1 {
    margin: auto;
    margin-top: 13px;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #ffffff;
  }
  p {
    /* margin-top: 8px; */
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */

    text-align: center;

    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */
    margin-top: 12px;
    text-align: center;

    color: #fddd14;
  }

  img {
    margin: auto;
    margin-top: 16px;
  }
`;

const ThirdLocation = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px 0px 12px 12px;
  width: 295px;
  height: 219px;
  /* border-radius: 16px; */
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  width: 295px;
  height: 219px;
  h1 {
    margin: auto;
    margin-top: 13px;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #ffffff;
  }
  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
    text-align: center;
    color: #fddd14;
  }

  img {
    margin: auto;
    margin-top: 16px;
  }
`;

const EventComponent = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventBox = styled.div`
  width: 900px;
  height: 465px;
  background: #005898;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventLocationCenter = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 262px;
  height: 261px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  cursor: pointer;
  width: 262px;
  background: #005898;
  img {
    margin: auto;
    margin-top: 32px;
  }
  h1 {
    margin: auto;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin-top: 24px;
    color: #ffffff;
  }
  p {
    margin-top: 8px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-top: 16px;
    color: #fddd14;
  }
`;
const EventLocationRight = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  cursor: pointer;
  width: 262px;
  height: 261px;
  background: #005898;
  img {
    margin: auto;
    margin-top: 32px;
  }
  h1 {
    margin: auto;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin-top: 24px;
    color: #ffffff;
  }
  p {
    margin-top: 8px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-top: 16px;
    color: #fddd14;
  }
`;

const EventLocation = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  width: 262px;
  height: 261px;
  background: #005898;
  img {
    margin: auto;
    margin-top: 32px;
  }
  cursor: pointer;

  h1 {
    margin: auto;
    text-align: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin-top: 24px;
    color: #ffffff;
  }
  p {
    margin-top: 8px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  a {
    display: flex;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 150% */

    text-align: center;
    margin-top: 16px;
    color: #fddd14;
  }
`;
