import styles from "./index.module.css";

const Header = () => {
  return (
    <div>
      <p className={styles.heading}>Event Registration</p>
    </div>
  );
};

export default Header;
