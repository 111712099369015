import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NewFooter = () => {
  return (
    <Footer>
      <div>
        <span>© 2024 . All Rights Reserved.</span>
      </div>
      <UL>
        <a href="https://www.facebook.com/careerbeam/">FACEBOOK</a>
        <a href="https://twitter.com/careerbeamindia">TWITTER</a>
        <a href="https://www.instagram.com/careerbeam/">INSTAGRAM</a>
      </UL>
      <MobileUl>
        <a href="https://www.instagram.com/careerbeam/">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0022 4.87225C7.16453 4.87225 4.87563 7.16166 4.87563 10C4.87563 12.8383 7.16453 15.1277 10.0022 15.1277C12.8399 15.1277 15.1288 12.8383 15.1288 10C15.1288 7.16166 12.8399 4.87225 10.0022 4.87225ZM10.0022 13.3337C8.16843 13.3337 6.66927 11.8387 6.66927 10C6.66927 8.16133 8.16397 6.6663 10.0022 6.6663C11.8405 6.6663 13.3352 8.16133 13.3352 10C13.3352 11.8387 11.836 13.3337 10.0022 13.3337ZM16.5343 4.6625C16.5343 5.32746 15.9989 5.85853 15.3385 5.85853C14.6737 5.85853 14.1428 5.32299 14.1428 4.6625C14.1428 4.00201 14.6782 3.46647 15.3385 3.46647C15.9989 3.46647 16.5343 4.00201 16.5343 4.6625ZM19.9297 5.87638C19.8539 4.27424 19.488 2.85507 18.3146 1.68582C17.1456 0.516568 15.7267 0.150619 14.1249 0.070289C12.4741 -0.0234297 7.52593 -0.0234297 5.87507 0.070289C4.27775 0.146156 2.8589 0.512105 1.68544 1.68136C0.511991 2.85061 0.150586 4.26978 0.0702733 5.87192C-0.0234244 7.52315 -0.0234244 12.4724 0.0702733 14.1236C0.146124 15.7258 0.511991 17.1449 1.68544 18.3142C2.8589 19.4834 4.27328 19.8494 5.87507 19.9297C7.52593 20.0234 12.4741 20.0234 14.1249 19.9297C15.7267 19.8538 17.1456 19.4879 18.3146 18.3142C19.4835 17.1449 19.8494 15.7258 19.9297 14.1236C20.0234 12.4724 20.0234 7.52761 19.9297 5.87638ZM17.797 15.8953C17.449 16.7701 16.7752 17.4439 15.8963 17.7965C14.58 18.3186 11.4568 18.1981 10.0022 18.1981C8.54769 18.1981 5.41997 18.3142 4.1082 17.7965C3.23369 17.4484 2.55996 16.7745 2.20747 15.8953C1.68544 14.5788 1.80591 11.4549 1.80591 10C1.80591 8.54513 1.68991 5.41671 2.20747 4.10465C2.55549 3.22995 3.22922 2.55606 4.1082 2.2035C5.42443 1.68136 8.54769 1.80185 10.0022 1.80185C11.4568 1.80185 14.5845 1.68582 15.8963 2.2035C16.7708 2.5516 17.4445 3.22548 17.797 4.10465C18.319 5.42118 18.1985 8.54513 18.1985 10C18.1985 11.4549 18.319 14.5833 17.797 15.8953Z"
              fill="#C4CDE4"
            />
          </svg>
        </a>
        <a href="https://www.facebook.com/careerbeam/">
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 0.561941C11.25 0.414647 11.1961 0.273385 11.1002 0.169233C11.0043 0.0650801 10.8743 0.00656764 10.7386 0.00656764H8.18182C6.89433 -0.0630863 5.63379 0.423532 4.67562 1.3601C3.71746 2.29667 3.13955 3.60705 3.06818 5.00493V8.00394H0.511364C0.375742 8.00394 0.245674 8.06245 0.149775 8.16661C0.0538758 8.27076 0 8.41202 0 8.55931V11.4473C0 11.5945 0.0538758 11.7358 0.149775 11.84C0.245674 11.9441 0.375742 12.0026 0.511364 12.0026H3.06818V19.4446C3.06818 19.5919 3.12206 19.7332 3.21796 19.8373C3.31386 19.9415 3.44392 20 3.57955 20H6.64773C6.78335 20 6.91342 19.9415 7.00932 19.8373C7.10522 19.7332 7.15909 19.5919 7.15909 19.4446V12.0026H9.83864C9.95235 12.0044 10.0634 11.965 10.1541 11.8905C10.2449 11.8161 10.3102 11.7109 10.3398 11.5917L11.0761 8.70371C11.0965 8.62165 11.0992 8.53561 11.0841 8.45223C11.069 8.36884 11.0365 8.29032 10.9891 8.22272C10.9417 8.15513 10.8806 8.10025 10.8106 8.06232C10.7406 8.02439 10.6635 8.00442 10.5852 8.00394H7.15909V5.00493C7.18453 4.72999 7.30341 4.47522 7.49252 4.29038C7.68164 4.10553 7.9274 4.00387 8.18182 4.00525H10.7386C10.8743 4.00525 11.0043 3.94674 11.1002 3.84259C11.1961 3.73844 11.25 3.59718 11.25 3.44988V0.561941Z"
              fill="#C4CDE4"
            />
          </svg>
        </a>
        <a href="https://twitter.com/careerbeamindia">
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9619 1.95522C19.2263 2.28078 18.4388 2.50476 17.6088 2.60634C18.4556 2.0934 19.1031 1.28203 19.4119 0.315461C18.6175 0.782342 17.7388 1.12052 16.8044 1.31169C16.0594 0.50663 14.9944 0 13.8125 0C11.5469 0 9.71062 1.85302 9.71062 4.13695C9.71062 4.46566 9.75 4.78112 9.81812 5.08018C6.41 4.91929 3.38875 3.26628 1.36813 0.769093C1.01313 1.3773 0.81125 2.08393 0.81125 2.85303C0.81125 4.29216 1.53688 5.554 2.63375 6.29596C1.96312 6.2764 1.33188 6.08902 0.77875 5.7805V5.83034C0.77875 7.8373 2.19125 9.51051 4.06625 9.89158C3.72438 9.98307 3.35938 10.0361 2.98875 10.0361C2.72563 10.0361 2.47438 10.0102 2.22375 9.96351C2.75125 11.6064 4.26125 12.8027 6.06125 12.8386C4.66187 13.9459 2.88813 14.6058 0.976875 14.6058C0.65125 14.6058 0.32625 14.5901 0 14.5503C1.82313 15.7238 3.975 16.4103 6.29938 16.4103C13.8413 16.4103 17.9625 10.1042 17.9625 4.64295C17.9625 4.46881 17.9625 4.29153 17.9494 4.11361C18.7538 3.53505 19.45 2.80192 20 1.971L19.9619 1.95522Z"
              fill="#C4CDE4"
            />
          </svg>
        </a>

        {/* <svg
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5821 2.2492C19.3521 1.36387 18.6744 0.666603 17.8139 0.429977C16.2542 0 10 0 10 0C10 0 3.74586 0 2.18613 0.429977C1.32565 0.666641 0.647936 1.36387 0.417919 2.2492C0 3.85392 0 7.20202 0 7.20202C0 7.20202 0 10.5501 0.417919 12.1548C0.647936 13.0402 1.32565 13.7084 2.18613 13.945C3.74586 14.375 10 14.375 10 14.375C10 14.375 16.2541 14.375 17.8139 13.945C18.6744 13.7084 19.3521 13.0402 19.5821 12.1548C20 10.5501 20 7.20202 20 7.20202C20 7.20202 20 3.85392 19.5821 2.2492V2.2492ZM7.95453 10.2419V4.1622L13.1818 7.2021L7.95453 10.2419Z"
            fill="#C4CDE4"
          />
        </svg> */}
      </MobileUl>
    </Footer>
  );
};

export default NewFooter;

const Footer = styled.div`
  @media (max-width: 786px) {
    width: 100%;
    height: 123px;
    background: #082338;

    span {
      display: flex;
      position: relative;
      justify-content: center;
      top: 5rem;
      margin: auto;
      text-align: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #a5a5a5;
      vertical-align: middle;
      overflow: hidden;
    }
  }

  height: 80px;
  width: 100%;
  background: #082338;
  border-radius: 0px;

  @media (min-width: 778px) {
    span {
      left: 80px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #a5a5a5;
      vertical-align: middle;
      position: relative;
      top: 30px;
      left: 10.5rem;
      /* right: 11rem; */
    }
  }
`;
const UL = styled.div`
  @media (max-width: 786px) {
    display: none;
  }
  display: flex;
  position: relative;
  justify-content: flex-end; /* Add this line to align the ul to the right */
  margin-right: 150px; /* Add some right margin to space the ul from the right side */
  a {
    margin-left: 16px; /* Add some left margin to space the li elements */
    font-family: "Rubik";
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-align: right;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 9px;
    color: #a5a5a5;
  }
`;
const MobileUl = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  svg {
    position: relative;
    top: 14px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
