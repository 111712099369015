import Layout from '../components1/03_compounds/Layout';
import Verification from '../components1/02_molecules/Content_Verfication';
import { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';

const EmailLogin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Register for CareerBeam Festival | Ascend Beyond</title>
        <meta
          name="description"
          content="Deep dive into future ready career options at CareerBeam Festival. Register now for exclusive seminars, workshops and panels; network with industry leaders & unlock your potential. Don't miss out!"
        />
        <link rel="canonical" href="https://careerbeam.in/event-registration" />
        <meta name="robots" content="all" />
      </Helmet>

      {/* <MetaTags>
        <meta
          name="title"
          content="Register for CareerBeam Festival | Ascend Beyond"
        />
        <meta
          name="description"
          content="Deep dive into future ready career options at CareerBeam Festival. Register now for exclusive seminars, workshops and panels; network with industry leaders & unlock your potential. Don't miss out!"
        />
      </MetaTags> */}
      <Verification />
    </Layout>
  );
};

export default EmailLogin;
