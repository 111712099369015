import React from "react";
import styled from "styled-components";

const KeyHigh = () => {
  return (
    <KeyHighComponent>
      <h1>Event Attractions</h1>
      <div class="grid-container">
        <div class="grid-item">Music Jam Sessions</div>
        <div class="grid-item">Flash Mobs</div>
        <div class="grid-item">Silent Movie Screening</div>
        <div class="grid-item">Flea Market</div>
        <div class="grid-item">Bartending Workshop</div>
        <div class="grid-item">B-Boying Showcase</div>
        <div class="grid-item">VR Gaming Zone</div>
        <div class="grid-item">Beat the Gaming Master</div>
      </div>
    </KeyHighComponent>
  );
};

export default KeyHigh;

const KeyHighComponent = styled.div`
  @media (max-width: 768px) {
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      /* identical to box height */
      position: relative;
      top: 40px;
      display: flex;
      justify-content: center;
      color: #062229;
    }

    /* display: none; */
    height: 689px;
    background: #a8daff;
    .grid-container {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    .grid-item {
      position: relative;
      display: flex;
      justify-content: center;
      margin: auto;
      /* top: 10rem; */
      top: 80px;
      align-items: center;
      width: 311px;
      height: 52px;
      border-radius: 8px;
      background-color: white;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      color: #062229;
    }

    .grid-item::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 100%;
      background: #1b75ba;
      border-radius: 8px 0px 0px 8px;
    }
  }

  @media (min-width: 768px) {
    height: 520px;
    background: #a8daff;
    h1 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 36px;
      /* identical to box height, or 100% */
      position: relative;
      top: 88px;
      text-align: center;
      color: #062229;
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 32px;
      width: 80%;
      margin: auto;
    }

    .grid-item {
      position: relative;
      display: flex;
      justify-content: center;
      margin: auto;
      top: 10rem;
      align-items: center;
      width: 268px;
      height: 60px;
      border-radius: 8px;
      background-color: white;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      color: #062229;
    }

    .grid-item::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 100%;
      background: #1b75ba;
      border-radius: 8px 0px 0px 8px;
    }
  }
`;

const HighMobile = styled.div`
  @media (min-width: 786px) {
    display: none;
  }
  height: 513px;
  background: #a8daff;
  h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    text-align: center;
    position: relative;
    top: 48px;
    color: #062229;
  }

  .high-tab {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    top: 8rem;
  }

  .num-div {
    background: #338ccc;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 69px;
    height: 62px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #ffffff;
  }
  .city-div {
    background: #ffffff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 202px;
    height: 48px;
  }
`;
